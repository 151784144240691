import React from "react"
import { createPortal } from "react-dom"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import Text from "@engaging-tech/components/dist/lib/Text/Text"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const DeleteModal = ({
  data,
  toggle,
  deleteError,
  deleteSuccess,
  resetDeleteSuccess,
  isDeleting,
  resetDeleteError,
  removeEntry,
  from,
  entryIdSearch,
  nameSearchTerm,
  reviewedSearch,
  invalidSearch,
  emailSearch,
  scoreSearch,
  onLoad
}) => {
  return createPortal(
    <Modal bg="light.0" p={3}>
      <Flex flexDirection="column" width={1 / 1}>
        <Text textAlign="center" mb={4} fontWeight={700}>
          Are You Sure You Want To Remove This Entry?
        </Text>
        {isDeleting ? (
          <Spinner
            py={3}
            color="primary.0"
            justifyContent="center"
            width={1 / 1}
          />
        ) : (
          <>
            {deleteError && (
              <Flex flexDirection="column" alignItems="center">
                <Text textAlign="center">
                  There was a problem removing this entry, please inform the
                  Engaging Tech team.
                </Text>
                <Button
                  mt={3}
                  bg="secondary.2"
                  onClick={() => {
                    toggle()
                    resetDeleteError()
                  }}
                >
                  Close
                </Button>
              </Flex>
            )}
            {!deleteError && !deleteSuccess && (
              <>
                <Text mb={1}>Entry ID: {data.id}</Text>
                <Text mb={1}>Company Name: {data.companyName}</Text>
                <Text mb={1}>
                  Industry:{" "}
                  {data.industry ? (
                    data.industry.name
                  ) : (
                    <Text color="#ff0000">none selected</Text>
                  )}
                </Text>
                <Text mb={1}>
                  Country:{" "}
                  {data.country ? (
                    data.country.name
                  ) : (
                    <Text color="#ff0000">none selected</Text>
                  )}
                </Text>

                <Flex flexDirection="row" width={1 / 1} justifyContent="center">
                  <Button mr={3} bg="secondary.2" onClick={toggle}>
                    No
                  </Button>
                  <Button
                    bg="secondary.2"
                    onClick={() => {
                      removeEntry(data.id)
                    }}
                  >
                    Yes
                  </Button>
                </Flex>
              </>
            )}
            {deleteSuccess && (
              <Flex flexDirection="column" alignItems="center">
                <Text>
                  This entry has been set as removed! It will not appear when
                  you search for it tomorrow on the ranking tables.
                </Text>
                <Button
                  mt={3}
                  bg="secondary.2"
                  onClick={() => {
                    toggle()
                    resetDeleteSuccess()
                    onLoad({
                      from: from,
                      id: entryIdSearch,
                      companyName: nameSearchTerm,
                      reviewed: reviewedSearch,
                      invalid: invalidSearch,
                      hasEmails: emailSearch,
                      score: scoreSearch
                    })
                  }}
                >
                  Close
                </Button>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default DeleteModal
