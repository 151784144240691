import { createSelector } from "reselect"
import differenceInSeconds from "date-fns/differenceInSeconds"
import { determineDescription, servicesToExclude } from "../lib/statusLibrary"

const statusState = state => state && state?.status

export const getStatusReport = createSelector(
  statusState,
  state => state?.statusReport
)

export const getIsLoading = createSelector(
  statusState,
  state => state?.isLoading
)
export const getHasError = createSelector(statusState, state => state?.hasError)

export const getEWStatusReport = createSelector(
  statusState,
  state => state?.ewStatusReport
)
export const getEBStatusReport = createSelector(
  statusState,
  state => state?.ebStatusReport
)

const determineTimeDiff = date =>
  differenceInSeconds(new Date(), new Date(date))

const prepareStatus = (statusses, type) =>
  Object.entries(statusses).map(status => {
    return {
      service: status[0],
      type: type,
      description: determineDescription[status[0]],
      timestamp: determineTimeDiff(status[1].timestamp)
    }
  })

const excludeServices = statusses =>
  statusses.filter(function (item) {
    for (var key in servicesToExclude) {
      if (item[key] === undefined || item[key] !== servicesToExclude[key])
        return true
    }
    return false
  })

export const getEWRestStatus = createSelector(
  getEWStatusReport,
  report => report?.rest && excludeServices(prepareStatus(report.rest, "Rest"))
)
export const getEWGraphQLStatus = createSelector(
  getEWStatusReport,
  report =>
    report?.graphql && excludeServices(prepareStatus(report.graphql, "GraphQL"))
)
export const getEBRestStatus = createSelector(
  getEBStatusReport,
  report => report?.rest && excludeServices(prepareStatus(report.rest, "Rest"))
)
export const getEBGraphQLStatus = createSelector(
  getEBStatusReport,
  report =>
    report?.graphql && excludeServices(prepareStatus(report.graphql, "GraphQL"))
)
