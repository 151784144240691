import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import createSagaMiddleware from "redux-saga"
import { spawn } from "redux-saga/effects"
import { authReducer } from "./features/auth"
import { CMSReducer, CMSSaga } from "./features/cms"
import {
  UMCurrentUserReducer,
  UMCurrentUserSaga,
  UMOrganisationSearchReducer,
  UMOrganisationSearchSaga
} from "./features/ebUserManagement"
import { MiscReducer, MiscSaga } from "./features/misc"
import {
  OrgManagementReducer,
  OrgManagementSaga
} from "./features/organisationManagement"
import {
  FetchSurveysForOrgReducer,
  FetchSurveysForOrgSaga
} from "./features/portable/FetchSurveysForOrg"
import {
  OrgSearchInputReducer,
  OrgSearchInputSaga
} from "./features/portable/SearchOrgInput"
import { rankingAuditReducer, rankingAuditSaga } from "./features/rankingAudit"
import { reportsReducer, reportsSaga } from "./features/reports"
import { statusReducer, statusSaga } from "./features/status"
import { dataexportReducer, dataexportSaga } from "./features/surveyDataExport"

const sagaMiddleware = createSagaMiddleware()

const withDevTools =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const appReducer = combineReducers({
  misc: MiscReducer,
  reports: reportsReducer,
  status: statusReducer,
  auth: authReducer,
  rankingAudit: rankingAuditReducer,
  UMCurrentUser: UMCurrentUserReducer,
  UMOrganisationSearch: UMOrganisationSearchReducer,
  orgSearchInput: OrgSearchInputReducer,
  fetchSurveys: FetchSurveysForOrgReducer,
  dataExport: dataexportReducer,
  cms: CMSReducer,
  orgManagement: OrgManagementReducer
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const store =
  //   typeof window !== "undefined"
  //     ? createStore(rootReducer, withDevTools(applyMiddleware(sagaMiddleware))) :
  createStore(rootReducer, withDevTools(applyMiddleware(sagaMiddleware)))

function* rootSaga() {
  yield spawn(reportsSaga)
  yield spawn(statusSaga)
  yield spawn(rankingAuditSaga)
  yield spawn(UMCurrentUserSaga)
  yield spawn(UMOrganisationSearchSaga)
  yield spawn(MiscSaga)
  yield spawn(dataexportSaga)
  yield spawn(OrgSearchInputSaga)
  yield spawn(FetchSurveysForOrgSaga)
  yield spawn(CMSSaga)
  yield spawn(OrgManagementSaga)
}

sagaMiddleware.run(rootSaga)

export default store