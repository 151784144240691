import { createSelector } from "reselect"
import { decode } from "@engaging-tech/jwt"

const authState = state => state && state?.auth

export const getUser = createSelector(authState, state => state?.user)

export const getAuthState = createSelector(authState, state => state?.authState)

export const getGroups = createSelector(getUser, user => {
  if (!user) return false
  const { keyPrefix, storage } = user

  const lastAuthUser = storage[`${keyPrefix}.LastAuthUser`]

  const accessToken = storage[`${keyPrefix}.${lastAuthUser}.accessToken`]
  const token = decode(accessToken)
  const groups = token["cognito:groups"]
  if (!groups) return []

  return groups
})
