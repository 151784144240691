import { connect } from "react-redux"
import ViewProducts from "../../../components/management_actions/premium_products/ViewProducts"
import {
  deleteActiveProductRequest,
  postActiveProductRequest,
  setAction,
  setEditData,
  setNewProduct,
  setSelectedProduct,
  updateActiveProductRequest
} from "../../../store/orgManagement.actions"
import {
  selectActiveProducts,
  getAvailableProducts
} from "../../../store/orgManagement.selectors"

const mapState = state => ({
  activeProducts: selectActiveProducts(state).list,
  availableProducts: getAvailableProducts(state).list,
  selectedProduct: selectActiveProducts(state).selectedProduct,
  action: selectActiveProducts(state).action,
  editData: selectActiveProducts(state).editData,
  newProduct: selectActiveProducts(state).newProduct,
  isLoading: selectActiveProducts(state).isLoading,
  error: selectActiveProducts(state).error
})

const mapDispatch = dispatch => ({
  deleteActiveProduct: productId =>
    dispatch(deleteActiveProductRequest(productId)),
  postActiveProduct: newProduct =>
    dispatch(postActiveProductRequest(newProduct)),
  setSelectedProduct: selectedProduct =>
    dispatch(setSelectedProduct(selectedProduct)),
  setAction: action => dispatch(setAction(action)),
  setEditData: editData => dispatch(setEditData(editData)),
  setNewProduct: newProduct => dispatch(setNewProduct(newProduct)),
  updateActiveProduct: () => dispatch(updateActiveProductRequest())
})

const ViewProductsContainer = connect(mapState, mapDispatch)(ViewProducts)

export default ViewProductsContainer
