import ApolloClient, { gql } from "apollo-boost"
import { CognitoUserPool } from "amazon-cognito-identity-js"
import { decode } from "@engaging-tech/jwt"
import awsmobile from "../aws-exports"

const userPool = {
  UserPoolId: awsmobile.aws_user_pools_id,
  ClientId: awsmobile.aws_user_pools_web_client_id
}

/**
 * Singleton class for handling graphQL queries using Apollo. A JWT will be
 * appended to the requests sent if a user is logged in.
 */
class Apollo {
  /**
   * Initialises the Apollo Client. There is logic present within the constructor
   * to ensure that we only ever have *one* instance of the class running.
   */
  constructor() {
    /* eslint-disable-next-line no-extra-boolean-cast */
    if (!!Apollo.instance) {
      if (!Apollo.instance.UserPool)
        Apollo.instance.UserPool = () => new CognitoUserPool(userPool)
      return Apollo.instance
    }

    this.UserPool = () => new CognitoUserPool(userPool)

    return this
  }

  getCurrentUser = () => this.UserPool().getCurrentUser()

  getSession = async () => {
    const user = this.getCurrentUser()
    return new Promise((resolve, reject) =>
      user
        ? user.getSession((err, session) =>
            err ? reject(err) : resolve(session)
          )
        : resolve()
    )
  }

  refreshSession = async refreshToken => {
    const user = this.getCurrentUser()

    return new Promise((resolve, reject) =>
      user.refreshSession(refreshToken, (err, session) =>
        err ? reject(err) : resolve(session.getIdToken().getJwtToken())
      )
    )
  }

  tryRefreshToken = async () => {
    const session = await this.getSession()
    if (!session) return
    const token = decode(session.getIdToken().getJwtToken())
    const expireDate = token && new Date(token.exp * 1000)
    const isTokenValid = expireDate > new Date()
    if (isTokenValid) {
      const refreshToken = session.getRefreshToken()
      await this.refreshSession(refreshToken)
    }
  }

  /**
   * Creates a pre-configured instance of the ApolloClient with a user's JWT
   * and URI for the request.
   *
   */
  createClientWithToken = async uri => {
    try {
      await this.tryRefreshToken()
    } catch (err) {
      console.log(err)
      const user = this.getCurrentUser()
      if (user) user.signOut()
    }
    const session = await this.getSession()
    const tokenString = session && session.getIdToken().getJwtToken()

    return new ApolloClient({
      uri,
      request: operation =>
        tokenString
          ? operation.setContext({
              headers: { Authorization: `JWT ${tokenString}` }
            })
          : null
    })
  }

  /**
   * Execute a graphQL query. This is mostly a wrapper for ApolloClient's query,
   * with support for a URI and automatic JWT attachment.
   * @param {string} uri The endpoint to make the query against.
   * @param {object} queryParams Other query parameters. These are passed into the
   * ApolloClient's query function, so anything used there *should* be valid here.
   */
  query = async ({ uri, ...queryParams } = {}) => {
    const client = await this.createClientWithToken(uri)
    return client.query(queryParams)
  }

  /**
   * Execute a graphQL mutation. This is mostly a wrapper for ApolloClient's mutate,
   * with support for a URI and automatic JWT attachment
   * @param {string} uri The endpoint to make the query against.
   * @param {object} queryParams Other query parameters. These are passed into the
   * ApolloClient's query function, so anything used there *should* be valid here.
   */
  mutate = async ({ uri, ...mutationParams } = {}) => {
    const client = await this.createClientWithToken(uri)
    return client.mutate(mutationParams)
  }
}

const apollo = new Apollo()

export { gql, apollo }

export default Apollo