import { createSelector } from "reselect"

const rankingAudit = state => state.rankingAudit

export const getEntries = createSelector(rankingAudit, audit => audit?.entries)

export const getIsLoading = createSelector(
  rankingAudit,
  audit => audit?.isLoading
)

export const getIsLoadingMore = createSelector(
  rankingAudit,
  audit => audit?.isLoadingMore
)

export const getHasError = createSelector(
  rankingAudit,
  audit => audit?.hasError
)

export const getUpdateSuccess = createSelector(
  rankingAudit,
  audit => audit?.updateSuccess
)

export const getMergeRequest = createSelector(
  rankingAudit,
  audit => audit?.mergeRequest
)

export const getNewCompanyName = createSelector(
  rankingAudit,
  audit => audit?.newCompanyName
)

export const getIsSubmitting = createSelector(
  rankingAudit,
  audit => audit?.isSubmitting
)

export const getNoChange = createSelector(
  rankingAudit,
  audit => audit?.noChange
)

export const getIsMerging = createSelector(
  rankingAudit,
  audit => audit?.isMerging
)

export const getIsDeleting = createSelector(
  rankingAudit,
  audit => audit?.isDeleting
)

export const getDeleteError = createSelector(
  rankingAudit,
  audit => audit?.deleteError
)

export const getDeleteSuccess = createSelector(
  rankingAudit,
  audit => audit?.deleteSuccess
)
