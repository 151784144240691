import { connect } from "react-redux"
import UserDetailsComponent from "../components/UserDetails"
import { loadUserByEmail } from "../store/currentUser/currentUser.actions"
import {
  getCurrentUser,
  getIsLoading
} from "../store/currentUser/currentUser.selectors"

const mapState = state => ({
  currentUser: getCurrentUser(state),
  isLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  handleEmailSearch: ({ email }) => dispatch(loadUserByEmail(email))
})

const UserDetails = connect(mapState, mapDispatch)(UserDetailsComponent)

export default UserDetails
