import React, { useState } from "react"
import TabBar from "./TabBar"
import UserDetails from "../containers/UserDetails"
import UsersByOrganisation from "../containers/UsersByOrganisation"

const UserManagement = () => {
  const [companySearchTab, setCompanySearchTab] = useState(true)

  const routes = [
    {
      path: "byCompany",
      description: "By Company",
      onClick: () => setCompanySearchTab(true)
    },
    {
      path: "user",
      description: "Find User",
      onClick: () => setCompanySearchTab(false)
    }
  ]

  const handleSetToUserView = () => setCompanySearchTab(false)

  return (
    <>
      <TabBar
        routes={routes}
        selectedTab={companySearchTab ? routes[0].path : routes[1].path}
        onClick={() => {
          return companySearchTab ? routes[0].onClick : routes[1].onClick
        }}
      />
      {companySearchTab ? (
        <UsersByOrganisation handleSetToUserView={handleSetToUserView} />
      ) : (
        <UserDetails />
      )}
    </>
  )
}

export default UserManagement
