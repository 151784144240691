import { connect } from "react-redux"
import { rankingAuditActions, rankingAuditSelectors } from ".."
import DeleteModalComponent from "../components/DeleteModal"

const mapState = state => ({
  isDeleting: rankingAuditSelectors.getIsDeleting(state),
  deleteError: rankingAuditSelectors.getDeleteError(state),
  deleteSuccess: rankingAuditSelectors.getDeleteSuccess(state)
})

const mapDispatch = dispatch => ({
  removeEntry: id => dispatch(rankingAuditActions.removeEntry(id)),
  resetDeleteSuccess: () => dispatch(rankingAuditActions.resetUpdateSuccess()),
  resetDeleteError: () => dispatch(rankingAuditActions.resetHasError())
})

const DeleteModal = connect(mapState, mapDispatch)(DeleteModalComponent)

export default DeleteModal
