import { connect } from "react-redux"
import UsersByOrganisationComponent from "../components/UsersByOrganisation"
import {
  getIsLoadingUsers,
  getUsers,
  getCurrentOrg
} from "../store/organisationSearch/organisationSearch.selectors"
import { loadUserByUserId } from "../store/currentUser/currentUser.actions"
import { loadUsersByOrgId } from "../store/organisationSearch/organisationSearch.actions"
import { getOrgs } from "../../portable/SearchOrgInput/store/searchorg.selectors"

const mapState = state => ({
  users: getUsers(state),
  isLoading: getIsLoadingUsers(state),
  companyName: getCurrentOrg(state)?.name,
  orgs: getOrgs(state)
})

const mapDispatch = dispatch => ({
  loadUser: userId => dispatch(loadUserByUserId(userId)),
  onSearchOrgsSubmit: org => dispatch(loadUsersByOrgId({ org, skip: 0 }))
})

const UsersByOrganisation = connect(
  mapState,
  mapDispatch
)(UsersByOrganisationComponent)

export default UsersByOrganisation
