import { Button, Flex, ResponsiveSearch } from "@engaging-tech/components"
import { useFormik } from "formik"
import React from "react"

const SearchByOrgInput = ({
  onSubmit,
  orgs,
  isLoading = false,
  onChangeCallback,
  buttonText,
  width = 2 / 3,
  mt = 4,
  noSearchBtn = false,
  currentOrg
}) => {
  const { handleSubmit, setFieldValue } = useFormik({
    initialValues: { id: "", name: "" },
    onSubmit
  })

  if (currentOrg) return null

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Flex width={width} mt={mt} justifyContent="space-between">
        <Flex width={1 / 1}>
          <ResponsiveSearch
            id="id"
            width={1 / 1}
            type="text"
            label="Organisation Name"
            selectCallback={val => {
              setFieldValue("id", val.id)
              setFieldValue("name", val.name)
              onChangeCallback(val.name)
            }}
            options={orgs || []}
            onChangeCallback={onChangeCallback}
            isLoading={isLoading}
            dropdownWidth={["auto", 1]}
          />
        </Flex>
        {!noSearchBtn && (
          <Button height="54px" bg="secondary.1" type="submit" ml={2}>
            {buttonText || "Search"}
          </Button>
        )}
      </Flex>
    </form>
  )
}

export default SearchByOrgInput
