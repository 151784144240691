import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../lib/Apollo"
import { LOAD, UPDATE, REMOVE, MERGE } from "./rankingAudit.queries"
import { COMPANIES_TO_LOAD } from "../lib/TableData"

const GRAPHQL_API_URL = () => getConfig().api
const apollo = new Apollo()

/**
 * Fetches all content of a specific post type from the backend.
 * @param {string} postType The type of posted content to load.
 */
export const loadEntries = ({
  from = 0,
  id = null,
  companyName = null,
  reviewed = null,
  invalid = null,
  hasEmails = null,
  score = null
}) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: {
      from,
      limit: COMPANIES_TO_LOAD,
      searchParams: { id, companyName, reviewed, invalid, hasEmails, score }
    }
  })

export const updateSingleEntry = ({
  id,
  companyName,
  industryId,
  countryId,
  reviewed,
  invalid,
  emails
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE,
    variables: {
      id,
      companyName,
      industryId,
      countryId,
      reviewed,
      invalid: invalid || false,
      emails
    }
  })

export const removeSingleEntry = id =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: REMOVE,
    variables: { id }
  })

export const mergeEntries = ({ id, companyName }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: MERGE,
    variables: { id, companyName }
  })
