/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../lib/Apollo"
import { GENERATE_EXPORT } from "./dataexport.queries"

const GRAPHQL_API_URL = () => getConfig().api

const apollo = new Apollo()

export const generateExport = ({ surveyId, options }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GENERATE_EXPORT,
    variables: { surveyId, options }
  })
