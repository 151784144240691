import React from "react"
import OrgManagementView from "../features/organisationManagement/views/OrgManagement"
import BaseLayout from "../layouts/BaseLayout"

const OrgManagement = () => (
  <BaseLayout>
    <OrgManagementView />
  </BaseLayout>
)

export default OrgManagement
