// Eng / Styled Components
import { Button, Flex, Spinner, Table, Text } from "@engaging-tech/components"
// React
import React from "react"
// Components
import OrganisationPageCtr from "../containers/OrganisationPageCtr"
// Table Data
import { Headings, TableConfig } from "../lib/TableData"

const None = () => (
  <Text color="#ff0000" fontSize={3}>
    none
  </Text>
)

// Button to enter organisation index page.
const ViewBtn = ({ org, setCurrentOrg }) => {
  return (
    <Flex justifyContent="center" width={1 / 1}>
      <Button bg="tertiary.0" width="150px" onClick={() => setCurrentOrg(org)}>
        Open
      </Button>
    </Flex>
  )
}

const prepareData = (orgs = [], setCurrentOrg) => {
  if (!orgs) return []
  return orgs.map(org => ({
    id: org.id,
    company: org.name,
    manage: org.id ? (
      <ViewBtn setCurrentOrg={setCurrentOrg} org={org} />
    ) : (
      <None />
    )
  }))
}

const OrganisationsTable = ({ isLoading, orgs, setCurrentOrg, currentOrg }) => {
  if (isLoading) return <Spinner py={3} color="primary.0" width={1 / 1} />

  return (
    /* If currentOrg is empty object then the table of organisations is shown.
      otherwise the Organisation Index page is shown with selected org details. */
    <>
      {currentOrg ? (
        <OrganisationPageCtr />
      ) : (
        <Table
          headings={Headings}
          config={TableConfig}
          rows={prepareData(orgs, setCurrentOrg)}
        />
      )}
    </>
  )
}

export default OrganisationsTable
