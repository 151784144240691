export const types = {
  LOAD_USERS_BY_ORG_ID:
    "[EB Org User Management] Load Users By Organisation Id",
  LOAD_USERS_BY_ORG_NAME:
    "[EB Org User Management] Load Users By Organisation Name",
  LOAD_USERS_BY_ORG_SUCCESS:
    "[EB Org User Management] Load Users By Organisation Success",
  LOAD_USERS_BY_ORG_FAILURE:
    "[EB Org User Management] Load Users By Organisation Failure",
  CLEAR_ERROR: "[EB Org User Management] Clear Error",
  CREATE_USER: "[EB Org User Management] Create User",
  CREATE_USER_SUCCESS: "[EB Org User Management] Create User Success",
  CREATE_USER_FAIL: "[EB Org User Management] Create User Failure",
  LOAD_MORE_USERS: "[EB Org User Management] Load More Users",
  LOAD_MORE_USERS_SUCCESS: "[EB Org User Management] Load More Users Success",
  LOAD_MORE_USERS_FAIL: "[EB Org User Management] Load More Users Fail"
}

export const loadMoreOrgUsers = skip => ({
  type: types.LOAD_MORE_USERS,
  payload: skip
})

export const loadMoreOrgUsersSuccess = users => ({
  type: types.LOAD_MORE_USERS_SUCCESS,
  payload: users
})

export const loadMoreOrgUsersFailure = err => ({
  type: types.LOAD_MORE_USERS_FAIL,
  payload: err
})

export const createUser = ({
  firstName,
  lastName,
  password,
  email,
  organisation
}) => ({
  type: types.CREATE_USER,
  payload: { firstName, lastName, password, email, organisation }
})

export const createUserSuccess = user => ({
  type: types.CREATE_USER_SUCCESS,
  payload: user
})

export const createUserFail = err => ({
  type: types.CREATE_USER_FAIL,
  payload: err
})

export const clearError = () => ({
  type: types.CLEAR_ERROR
})

export const loadUsersByOrgId = ({ org, skip = 0 }) => ({
  type: types.LOAD_USERS_BY_ORG_ID,
  payload: { org, skip }
})

export const loadUsersByOrgName = ({ name, skip = 0 }) => ({
  type: types.LOAD_USERS_BY_ORG_NAME,
  payload: { name, skip }
})

export const loadUsersByOrgSuccess = users => ({
  type: types.LOAD_USERS_BY_ORG_SUCCESS,
  payload: users
})

export const loadUsersByOrgFailure = err => ({
  type: types.LOAD_USERS_BY_ORG_FAILURE,
  payload: err
})
