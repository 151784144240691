import React from "react"
import GenerateView from "../features/surveyDataExport/views/Generate"
import BaseLayout from "../layouts/BaseLayout"

const DataExport = () => (
  <BaseLayout>
    <GenerateView />
  </BaseLayout>
)

export default DataExport
