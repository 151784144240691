/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../lib/Apollo"

import { GET_ALL } from "./reports.queries"

const GRAPHQL_API_URL = () => getConfig().api

const apollo = new Apollo()

export const getAll = ({ from, to }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_ALL,
    variables: { from, to }
  })
