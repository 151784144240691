import React from "react"

import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Radio from "../../../components/Radio"

const GetIsWorkFromHome = ({ isWorkFromHome, handleClick }) => {
  return (
    <Box mb={[3, 3, 0]}>
      <Text>Standard or Work From Home?</Text>
      <Radio
        label={() => "Standard"}
        checked={!isWorkFromHome}
        onClick={() => handleClick(false)}
      />
      <Radio
        label={() => "Work From Home"}
        checked={isWorkFromHome}
        onClick={() => handleClick(true)}
      />
    </Box>
  )
}

export default GetIsWorkFromHome
