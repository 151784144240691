// Redux
import { connect } from "react-redux"
// Store
import {
  getCurrentOrg,
  getOpenSlider
} from "../../../store/orgManagement.selectors"
import {
  setCloseSlider,
  updateCreditsRequest
} from "../../../store/orgManagement.actions"
import { getUser } from "../../../../auth/store/auth.selectors"
// Components
import CreditsEditor from "../../../components/management_actions/manage_credits/CreditsEditor"

const mapState = state => ({
  openSlider: getOpenSlider(state),
  currentOrg: getCurrentOrg(state),
  user: getUser(state)
})

const mapDispatch = dispatch => ({
  setCloseSlider: () => dispatch(setCloseSlider()),
  onSubmit: payload => dispatch(updateCreditsRequest(payload))
})

const CreditsEditorCtr = connect(mapState, mapDispatch)(CreditsEditor)

export default CreditsEditorCtr
