import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../lib/Apollo"
import {
  LOAD_USER,
  LOAD_USERS_BY_ORG,
  UPDATE_USER,
  DELETE_USER,
  CREATE_USER
} from "./ebUserManagement.queries"

const GRAPHQL_API_URL = () => getConfig().api

const apollo = new Apollo()

export const loadUser = ({ email, userId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_USER,
    variables: { email, userId }
  })

export const USER_LOAD_LIMIT = 10

export const loadUsersByOrg = ({ org, skip }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_USERS_BY_ORG,
    variables: { orgId: org.id, limit: USER_LOAD_LIMIT, skip }
  })

export const updateUser = variables =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_USER,
    variables
  })

export const createUser = ({
  firstName,
  lastName,
  email,
  password,
  organisation
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_USER,
    variables: {
      params: {
        firstName,
        lastName,
        email,
        password,
        organisationId: organisation.id
      }
    }
  })

export const deleteUser = userId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: DELETE_USER,
    variables: {
      userId
    }
  })
