/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../../lib/Apollo"
import { FETCH_SURVEYS } from "./fetchsurveys.queries"

const GRAPHQL_API_URL = () => getConfig().api

const apollo = new Apollo()

export const fetchSurveysByOrganisationId = ({ organisationId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: FETCH_SURVEYS,
    variables: { organisationId }
  })
