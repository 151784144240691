// Eng / Styled Components
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import styled from "styled-components"
// React
import React, { useEffect } from "react"

const SliderBox = styled(Box)`
  position: absolute;
  bottom: 0px;
  height: 155px;
  width: 100%;
  background-color: white;
  z-index: 10;
  transform: translateX(500px);
  overflow: hidden;
  transition: 0.2s;
`

const EditorSlider = ({ children, openSlider }) => {
  // SLIDER STYLING
  useEffect(() => {
    const slider = document.querySelector(".editor-slider")

    if (openSlider.add || openSlider.edit) {
      slider.style.transform = "translateY(0px)"
    } else if (openSlider.log) {
      slider.style.height = "275px"
      slider.style.transform = "translateY(0px)"
    } else {
      slider.style.transform = "translateY(500px)"
      slider.style.height = "155px"
    }
  }, [openSlider])

  return <SliderBox className="editor-slider">{children}</SliderBox>
}

export default EditorSlider
