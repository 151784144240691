/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import { Button, Flex, Select, Spinner, Text } from "@engaging-tech/components"
import React from "react"
import DatePicker from "react-multi-date-picker"
import styled from "styled-components"
import GetAvailableProducts from "../../../loaders/getAvailableProducts"
import { Input, Label } from "./TextData"

const Option = styled.option``

const Buttons = ({
  setAction,
  setSelectedProduct,
  confirmAction,
  disableButton
}) => (
  <Flex justifyContent="center" width={1 / 1} mb={2} mt={4}>
    <Button
      bg="#ff9900"
      width="120px"
      mr={2}
      onClick={() => {
        setAction("")
        setSelectedProduct({})
      }}
    >
      Back
    </Button>
    <Button
      bg="red"
      width="120px"
      onClick={confirmAction}
      disabled={disableButton}
    >
      Confirm
    </Button>
  </Flex>
)

const AddProduct = ({
  availableProducts,
  setSelectedProduct,
  setAction,
  confirmAction,
  newProduct,
  setNewProduct,
  action,
  isLoading,
  error
}) => {
  const disableButton =
    (action === "add" && !newProduct.invoiceId) ||
    (action === "add" && !newProduct.productId)

  return (
    <>
      <GetAvailableProducts />
      {isLoading ? (
        <>
          <Text my={2} textAlign="center">
            Fetching Data
          </Text>
          <Spinner color="primary.0" mb={3} />
        </>
      ) : error ? (
        <Text textAlign="center" my={2}>
          Something went wrong
        </Text>
      ) : (
        <Flex width={1 / 1} flexDirection="column">
          <Text fontSize={5} color="primary.0" textAlign="center" mb={2}>
            Add a new product to the account
          </Text>
          <Select
            onChange={e =>
              setNewProduct({ ...newProduct, productId: e.target.value })
            }
          >
            <Option disabled selected>
              Select a product
            </Option>
            {availableProducts?.map(({ id, name }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
          {!newProduct.productId && (
            <Text color="red" fontSize={2}>
              Required
            </Text>
          )}
          <Text mt={2}>Expires at</Text>
          <DatePicker
            render={(value, openCalendar) => {
              return (
                <Button
                  onClick={openCalendar}
                  width="100px"
                  variant="outline.primary.0"
                >
                  {value}
                </Button>
              )
            }}
            minDate={new Date()}
            format="DD/MM/YYYY"
            value={new Date(newProduct.expiresAt)}
            onChange={value =>
              setNewProduct({
                ...newProduct,
                expiresAt: new Date(value.toDate()).toISOString()
              })
            }
          />
          <Label htmlFor="invoiceId">Invoice id</Label>
          <Input
            type="text"
            id="invoiceId"
            onChange={e =>
              setNewProduct({ ...newProduct, invoiceId: e.target.value })
            }
          />
          {!newProduct.invoiceId && (
            <Text color="red" fontSize={2}>
              Required
            </Text>
          )}
          <Buttons
            setSelectedProduct={setSelectedProduct}
            confirmAction={confirmAction}
            setAction={setAction}
            disableButton={disableButton}
          />
        </Flex>
      )}
    </>
  )
}

export default AddProduct
