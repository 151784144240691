import { Box, Button, Spinner, Text, TextArea } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"

const Banner = ({ banner, isLoading, isSaving, onSave, onLoad }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    if (!hasLoaded) onLoad()
    setHasLoaded(true)
  }, [hasLoaded, onLoad])

  if (!hasLoaded) return <Spinner width={150} color="primary.0" />

  const handleSubmit = (e, type) => {
    e.preventDefault()
    switch (type) {
      case "WORKL_BANNER":
        return onSave({
          type: "WORKL_BANNER",
          content: e.target.workl.value
        })
      case "WFB_BANNER":
        return onSave({
          type: "WFB_BANNER",
          content: e.target.wfb.value
        })
      default:
        return null
    }
  }

  return (
    <Box>
      <form onSubmit={e => handleSubmit(e, "WORKL_BANNER")}>
        <Text width={1 / 1} fontWeight={600} mb={3} fontSize={5}>
          WorkL Site Banner
        </Text>
        <Box minHeight="175px">
          {isLoading || isSaving === "WORKL_BANNER" ? (
            <Spinner size={150} color="primary.0" />
          ) : (
            <TextArea
              helperText="You can enter both plaintext and HTML (<script> tags will be removed from the banner as they are not permitted)"
              height="150px"
              id="workl"
              name="workl"
              width={1 / 1}
              defaultValue={banner?.WORKL_BANNER || ""}
            />
          )}
        </Box>
        <Button
          disabled={!!isSaving}
          variant="primary.0"
          width="150px"
          ml="auto"
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </form>{" "}
      <form onSubmit={e => handleSubmit(e, "WFB_BANNER")}>
        <Text width={1 / 1} fontWeight={600} mb={3} fontSize={5}>
          WorkL For Business Site Banner
        </Text>
        <Box minHeight="175px">
          {isLoading || isSaving === "WFB_BANNER" ? (
            <Spinner size={150} color="primary.0" />
          ) : (
            <TextArea
              helperText="You can enter both plaintext and HTML (<script> tags will be removed from the banner as they are not permitted)"
              height="150px"
              id="wfb"
              name="wfb"
              width={1 / 1}
              defaultValue={banner?.WFB_BANNER || ""}
            />
          )}
        </Box>
        <Button
          disabled={!!isSaving}
          variant="primary.0"
          width="150px"
          ml="auto"
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </form>
    </Box>
  )
}

export default Banner
