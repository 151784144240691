import { takeLatest, put, call } from "redux-saga/effects"

import { statusService } from ".."

import * as Actions from "./status.actions"

// const MOCK_RES = {
//   time: new Date(),
//   rest: {
//     "user-manager": {
//       target: "http://ew_user-manager_prod:7002/",
//       routes: [
//         {
//           path: "/signup",
//           methods: ["POST"],
//         },
//         {
//           path: "/signin",
//           methods: ["POST"],
//         },
//         {
//           path: "/confirm-email/:confirmToken",
//           methods: ["POST"],
//         },
//         {
//           path: "/forgot-password",
//           methods: ["POST"],
//         },
//         {
//           path: "/reset-password",
//           methods: ["POST"],
//         },
//         {
//           path: "/email-availability/:email",
//           methods: ["GET"],
//         },
//       ],
//       timestamp: "2020-10-09T09:31:44.547Z",
//     },
//     payment: {
//       target: "http://ew_payment_prod:7002/",
//       routes: [
//         {
//           path: "/process",
//           methods: ["POST"],
//         },
//       ],
//       timestamp: "2020-10-09T09:31:48.158Z",
//     },
//     misc: {
//       target: "http://ew_misc_prod:7002/",
//       routes: [
//         {
//           path: "/podcasts",
//           methods: ["GET"],
//         },
//         {
//           path: "/industries",
//           methods: ["GET"],
//         },
//         {
//           path: "/regions/:countryId",
//           methods: ["GET"],
//         },
//       ],
//       timestamp: "2020-10-09T09:31:53.263Z",
//     },
//     hub: {
//       target: "http://ew_hub_prod:7002/",
//       routes: [
//         {
//           path: "/posts/:postId",
//           methods: ["GET"],
//         },
//         {
//           path: "/posts",
//           methods: ["GET"],
//         },
//         {
//           path: "/posts/:postId/likes",
//           methods: ["GET"],
//         },
//       ],
//       timestamp: "2020-10-09T09:31:56.766Z",
//     },
//     "file-manager": {
//       target: "http://ew_file-manager_prod:7002/",
//       routes: [],
//       timestamp: "2020-10-09T09:31:59.058Z",
//     },
//     jobs: {
//       target: "http://ew_jobs_prod:7002/",
//       routes: [
//         {
//           path: "/board/vacancies",
//           methods: ["GET"],
//         },
//         {
//           path: "/board/vacancies/detailed/:jobId",
//           methods: ["GET"],
//         },
//       ],
//       timestamp: "2020-10-09T09:31:38.141Z",
//     },
//   },
//   graphql: {
//     "misc-graphql": {
//       url: "http://ew_misc-graphql_prod:7002/graphql",
//       timestamp: new Date(),
//     },
//     materials: {
//       url: "http://ew_materials_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:42.526Z",
//     },
//     surveys: {
//       url: "http://ew_surveys_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:48.789Z",
//     },
//     "hub-graphql": {
//       url: "http://ew_hub-graphql_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:47.942Z",
//     },
//     "survey-processes": {
//       url: "http://ew_survey-processes_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:49.755Z",
//     },
//     rankings: {
//       url: "http://ew_rankings_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:54.064Z",
//     },
//     "business-graphql": {
//       url: "http://ew_business-graphql_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:54.398Z",
//     },
//     "social-library": {
//       url: "http://ew_social-library_prod:7002/graphql",
//       timestamp: "2020-10-09T09:32:01.209Z",
//     },
//     "gardners-payment": {
//       url: "http://ew_gardners-payment_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:37.373Z",
//     },
//     profile: {
//       url: "http://ew_profile_prod:7002/graphql",
//       timestamp: "2020-10-09T09:31:38.368Z",
//     },
//   },
// };

export function* loadStatusSaga() {
  try {
    const ewRes = yield call(statusService.loadEWStatus)
    const ebRes = yield call(statusService.loadEBStatus)

    if (ewRes?.data && ebRes?.data)
      yield put(
        Actions.loadStatusSuccess({
          ewStatus: ewRes.data,
          ebStatus: ebRes.data
        })
      )
    else yield put(Actions.loadStatusFail())
  } catch (err) {
    console.log(err)
    yield put(Actions.loadStatusFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_STATUS, loadStatusSaga)
}
