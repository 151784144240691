export const types = {
  UPDATE_USER: "[Auth] Update User",
  UPDATE_AUTH_STATE: "[Auth] Update Auth State"
}

export const updateUser = payload => ({
  type: types.UPDATE_USER,
  payload
})

export const updateAuthState = payload => ({
  type: types.UPDATE_AUTH_STATE,
  payload
})
