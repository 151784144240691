import { takeLatest, put, call, select } from "redux-saga/effects"

import {
  loadUser,
  updateUser,
  deleteUser
} from "../../services/ebUserManagement.service"

import * as Actions from "./currentUser.actions"
import * as Selectors from "./currentUser.selectors"

const getGraphQLErrCode = err => {
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

export function* loadUserSaga(action) {
  try {
    const res = yield call(loadUser, action.payload)
    yield put(Actions.loadUserSuccess(res?.data?.getUser))
  } catch (error) {
    yield put(Actions.loadUserFail(getGraphQLErrCode(error)))
  }
}

const getUpdateVariables = ({ currentUser, updatedUser }) => {
  const params = {}
  if (updatedUser.password) {
    params.password = updatedUser.password
  }
  for (const field of ["firstName", "lastName", "email"]) {
    if (currentUser[field] !== updatedUser[field]) {
      params[field] = updatedUser[field]
    }
  }
  if (currentUser.organisation.id !== updatedUser.organisation.id) {
    params.organisationId = updatedUser.organisation.id
  }
  if (!Object.keys(params).length) return null
  return { userId: updatedUser.userId, params }
}

export function* updateUserSaga(action) {
  try {
    const currentUser = yield select(Selectors.getCurrentUser)
    const variables = getUpdateVariables({
      currentUser,
      updatedUser: action.payload
    })
    if (variables) {
      const res = yield call(
        updateUser,
        getUpdateVariables({ currentUser, updatedUser: action.payload })
      )
      if (res?.data?.updateUser) {
        yield put(Actions.updateUserSuccess(res.data.updateUser))
      }
    }
  } catch (error) {
    yield put(Actions.updateUserFail(getGraphQLErrCode(error)))
  }
}

export function* deleteUserSaga(action) {
  try {
    const res = yield call(deleteUser, action.payload)
    if (res?.data?.deleteUser) {
      yield put(Actions.deleteUserSuccess())
    }
  } catch (error) {
    yield put(Actions.deleteUserFail(getGraphQLErrCode(error)))
  }
}

export function* saga() {
  yield takeLatest([Actions.types.LOAD_USER], loadUserSaga)
  yield takeLatest([Actions.types.UPDATE_USER], updateUserSaga)
  yield takeLatest([Actions.types.DELETE_USER], deleteUserSaga)
}
