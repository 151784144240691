import { connect } from "react-redux"
import Component from "../../components/OptionsSelect/OrganisationNameFilter"
import { setField } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  companyNameRequired: getField(state, "companyNameRequired")
})

const mapDispatch = dispatch => ({
  handleClick: val => dispatch(setField("companyNameRequired", val))
})

export default connect(mapState, mapDispatch)(Component)
