// Redux
import { connect } from "react-redux"
// Store
import { getComponent } from "../../store/orgManagement.selectors"
import { setComponent, setCloseSlider } from "../../store/orgManagement.actions"
// Components
import OrganisationModal from "../../components/reusables/OrganisationModal"

const mapState = state => ({
  component: getComponent(state)
})

const mapDispatch = dispatch => ({
  setComponent: component => dispatch(setComponent(component)),
  setCloseSlider: () => dispatch(setCloseSlider())
})

const OrganisationModalCtr = connect(mapState, mapDispatch)(OrganisationModal)

export default OrganisationModalCtr
