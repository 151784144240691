import React, { useState } from "react"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import TextField from "@engaging-tech/components/dist/lib/TextField/TextField"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import { useFormik } from "formik"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import { format } from "date-fns"
import EditUserForm from "../containers/EditUserForm"
import RemoveUserForm from "../containers/RemoveUserForm"
import ErrorSnackbar from "../containers/ErrorSnackbar"

const SearchByEmail = ({ onSubmit }) => {
  const { values, handleChange, handleSubmit, handleReset } = useFormik({
    initialValues: { email: "" },
    onSubmit
  })
  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleReset}
      style={{ width: "100%" }}
    >
      <Flex width={3 / 4} mt={4}>
        <TextField
          width={1 / 1}
          label="User Email"
          name="email"
          value={values.email}
          onChange={handleChange}
        />
        <Button height="54px" bg="secondary.0" type="submit" ml={2}>
          Search
        </Button>
      </Flex>
    </form>
  )
}

const DisplayRow = ({ name, value }) => (
  <Flex width={1} py={2}>
    <Text>
      {name}: {value}
    </Text>
  </Flex>
)

const ServicesMap = {
  jobMatcher: "Job Matcher",
  jobBoard: "Job Board",
  surveyLicence: "Survey Licence",
  events: "Events Subscription",
  leads: "Leads Subscription",
  ebn: "EBN Membership"
}
const DisplayOrg = ({ name, id, confirmed, services }) => (
  <Box width={1} bg="primary.4" p={2} mb={3}>
    <Text>Current Organisation</Text>
    <Box width={1} pl={5}>
      <DisplayRow name="Organisation Id" value={id} />
      <DisplayRow name="Name" value={name} />
      <DisplayRow name="Confirmed" value={confirmed?.toString()} />
      <Text width={1} display="block" textAlign="center">
        Services:
      </Text>
      {services?.map(({ name, active, expireDate }) => (
        <Flex flexBasis={0}>
          <Text fontSize={2}>{ServicesMap[name] || name}</Text>
          <Text fontSize={2}>{active ? "Active" : "Inactive"}</Text>
          <Text fontSize={1}>
            Expires: {format(new Date(expireDate), "dd/MM/yyyy p")}
          </Text>
        </Flex>
      ))}
    </Box>
  </Box>
)

const DisplayUser = ({
  firstName,
  lastName,
  userId,
  email,
  enabled,
  createdAt,
  updatedAt,
  status,
  organisation,
  attributes,
  handleDisplayEditForm,
  handleDisplayRemoveForm
}) => {
  return (
    <Box py={3} bg="primary.4">
      <Flex width={1} justifyContent="flex-end" py={3}>
        <Button
          mr={3}
          elevation={0}
          variant="secondary.0"
          width={100}
          onClick={handleDisplayEditForm}
        >
          Edit
        </Button>
        <Button
          mr={3}
          elevation={0}
          variant="error.0"
          width={100}
          onClick={handleDisplayRemoveForm}
        >
          Remove
        </Button>
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <Box width={1} px={2}>
          <DisplayRow name="User ID" value={userId} />
          <DisplayRow name="First Name" value={firstName} />
          <DisplayRow name="Last Name" value={lastName} />
          <DisplayRow name="Email" value={email} />
          <DisplayRow name="Enabled" value={enabled.toString()} />
          <DisplayRow name="Status" value={status} />
          <DisplayRow
            name="Created At"
            value={format(new Date(createdAt), "dd/MM/yyyy p")}
          />
          <DisplayRow
            name="Last Updated"
            value={format(new Date(updatedAt), "dd/MM/yyyy p")}
          />
        </Box>
        <Box width={1} px={2}>
          <DisplayOrg {...organisation} />
          <Text>Other Attributes:</Text>
          {attributes.map(({ name, value }) => {
            if (name === "identities") return null
            return <DisplayRow name={name} value={value} />
          })}
        </Box>
      </Flex>
    </Box>
  )
}

const UserDetails = ({ handleEmailSearch, currentUser, isLoading }) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showRemoveForm, setShowRemoveForm] = useState(false)
  return (
    <Box>
      <SearchByEmail onSubmit={handleEmailSearch} />
      {isLoading && <Spinner color="primary.0" />}
      {currentUser && !isLoading && (
        <DisplayUser
          {...currentUser}
          handleDisplayEditForm={() => setShowEditForm(true)}
          handleDisplayRemoveForm={() => setShowRemoveForm(true)}
        />
      )}
      {showEditForm && (
        <EditUserForm
          currentUser={currentUser}
          toggle={() => setShowEditForm(false)}
        />
      )}
      {showRemoveForm && (
        <RemoveUserForm
          userId={currentUser?.userId}
          toggle={() => setShowRemoveForm(false)}
        />
      )}
      <ErrorSnackbar type="user" />
    </Box>
  )
}

export default UserDetails
