import React, { useState } from "react"
import { TableConfig, Headings } from "../lib/TableData"
import EditModal from "../containers/EditModal"
import DeleteModal from "../containers/DeleteModal"
import ListEmailsModal from "../components/ListEmailsModal"
import Filters from "./Filters"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Icon from "@engaging-tech/components/dist/lib/Icon/Icon"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import ToggleLogic from "@engaging-tech/components/dist/lib/ToggleLogic/ToggleLogic"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Table from "@engaging-tech/components/dist/lib/Table/Table"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import styled from "styled-components"

const HoverText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const prepareData = (
  entries,
  onLoad,
  from,
  entryIdSearch,
  nameSearchTerm,
  reviewedSearch,
  invalidSearch,
  emailSearch,
  scoreSearch,
  listOpen,
  setListOpen
) =>
  entries.map(el => {
    return {
      id: el.id,
      reviewed: (
        <Flex justifyContent="center">
          {el.reviewed ? <Icon name="check" /> : <Icon name="close" />}
        </Flex>
      ),
      country: el.country ? (
        el.country.name
      ) : (
        <Text color="#ff0000" fontSize={3}>
          none selected
        </Text>
      ),
      companyName: el.companyName,
      emails:
        el.emails && el.emails.length > 0 ? (
          <>
            <HoverText fontSize={3} onClick={() => setListOpen(true)}>
              List Given
            </HoverText>
            {listOpen && (
              <ListEmailsModal data={el.emails} setListOpen={setListOpen} />
            )}
          </>
        ) : (
          <Text color="#ff0000" fontSize={3}>
            none given
          </Text>
        ),
      score: el.score ? el.score : <Text fontSize={3}>no score yet</Text>,
      industry: el.industry ? (
        el.industry.name
      ) : (
        <Text color="#ff0000" fontSize={3}>
          none selected
        </Text>
      ),
      editBtn: (
        <Flex width={1 / 1} justifyContent="center">
          <ToggleLogic>
            {({ on, toggle }) => (
              <>
                <Button mr={3} bg="secondary.2" onClick={toggle}>
                  Edit
                </Button>
                {on && (
                  <EditModal
                    data={el}
                    toggle={toggle}
                    onLoad={onLoad}
                    from={from}
                    entryIdSearch={entryIdSearch}
                    nameSearchTerm={nameSearchTerm}
                    reviewedSearch={reviewedSearch}
                    invalidSearch={invalidSearch}
                    emailSearch={emailSearch}
                    scoreSearch={scoreSearch}
                  />
                )}
              </>
            )}
          </ToggleLogic>
        </Flex>
      ),
      deleteBtn: (
        <Flex width={1 / 1} justifyContent="center">
          {el.invalid ? (
            <Text color="#ff0000" fontSize={3}>
              Removed
            </Text>
          ) : (
            <ToggleLogic>
              {({ on, toggle }) => (
                <>
                  <Button mr={3} bg="tertiary.0" onClick={toggle}>
                    Remove
                  </Button>
                  {on && (
                    <DeleteModal
                      data={el}
                      toggle={toggle}
                      onLoad={onLoad}
                      from={from}
                      entryIdSearch={entryIdSearch}
                      nameSearchTerm={nameSearchTerm}
                      reviewedSearch={reviewedSearch}
                      invalidSearch={invalidSearch}
                      emailSearch={emailSearch}
                      scoreSearch={scoreSearch}
                    />
                  )}
                </>
              )}
            </ToggleLogic>
          )}
        </Flex>
      )
    }
  })

const RankingAudit = ({
  entries,
  isLoadingMore,
  onLoad,
  onLoadMore,
  isLoading
}) => {
  const [from, setFrom] = useState(0)
  const [entryChipShowing, setEntryChipShowing] = useState(false)
  const [nameChipShowing, setNameChipShowing] = useState(false)
  const [nameSearchTerm, setNameSearchTerm] = useState(null)
  const [entryIdSearch, setEntryIdSearch] = useState(null)
  const [invalidSearch, setInvalidSearch] = useState(null)
  const [reviewedSearch, setReviewedSearch] = useState(null)
  const [emailSearch, setEmailSearch] = useState(null)
  const [scoreSearch, setScoreSearch] = useState(null)
  const [listOpen, setListOpen] = useState(false)
  return (
    <Flex flexDirection="column" alignItems="center">
      <Text width={1 / 1} fontWeight={600} mb={3} fontSize={5}>
        Search Criteria:
      </Text>
      <Filters
        entryChipShowing={entryChipShowing}
        nameChipShowing={nameChipShowing}
        entryIdSearch={entryIdSearch}
        nameSearchTerm={nameSearchTerm}
        setEntryChipShowing={setEntryChipShowing}
        setNameChipShowing={setNameChipShowing}
        setNameSearchTerm={setNameSearchTerm}
        setEntryIdSearch={setEntryIdSearch}
        invalidSearch={invalidSearch}
        setInvalidSearch={setInvalidSearch}
        reviewedSearch={reviewedSearch}
        setReviewedSearch={setReviewedSearch}
        emailSearch={emailSearch}
        setEmailSearch={setEmailSearch}
        scoreSearch={scoreSearch}
        setScoreSearch={setScoreSearch}
        onLoad={onLoad}
        from={from}
      />
      <Text width={1 / 1} color="#ff0000" textAlign="center" mt={4}>
        Please note, any changes made to entries will be visible on the rankings
        tables the next day.
      </Text>
      {!isLoading ? (
        <Table
          width={1 / 1}
          headings={Headings}
          rows={prepareData(
            entries,
            onLoad,
            from,
            entryIdSearch,
            nameSearchTerm,
            reviewedSearch,
            invalidSearch,
            emailSearch,
            scoreSearch,
            listOpen,
            setListOpen
          )}
          config={TableConfig}
          mt={5}
          mb={2}
        />
      ) : (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      )}

      {isLoadingMore ? (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      ) : (
        <Button
          onClick={() => {
            setFrom(from => from + 50)
            onLoadMore({
              from: from + 50,
              id: entryIdSearch,
              companyName: nameSearchTerm,
              reviewed: reviewedSearch,
              invalid: invalidSearch,
              hasEmails: emailSearch,
              score: scoreSearch
            })
          }}
          bg="secondary.1"
          mt={2}
          mb={5}
          width="200px"
        >
          Load More
        </Button>
      )}
    </Flex>
  )
}

export default RankingAudit
