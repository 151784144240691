import { connect } from "react-redux"
import { getOrgs } from "../../portable/SearchOrgInput/store/searchorg.selectors"
import EditUserFormComponent from "../components/EditUserForm"
import { updateUser } from "../store/currentUser/currentUser.actions"

const mapState = state => ({
  orgs: getOrgs(state)
})

const mapDispatch = (dispatch, { toggle }) => ({
  onSubmit: params => {
    dispatch(updateUser(params))
    toggle()
  }
})

const EditUserForm = connect(mapState, mapDispatch)(EditUserFormComponent)

export default EditUserForm
