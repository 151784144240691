import { types } from "./orgManagement.actions"

export const initialState = {
  isLoading: false,
  errorCode: null,
  currentOrg: null,
  openSlider: { add: false, edit: false, log: false },
  component: null,
  logs: [],
  activeProducts: {
    isLoading: false,
    list: [],
    errorCode: null,
    selectedProduct: {},
    action: "",
    editData: {},
    newProduct: {
      productId: "",
      expiresAt: new Date(),
      invoiceId: ""
    }
  },
  availableProducts: {
    isLoading: false,
    list: [],
    error: null
  }
}

export const ErrorTypes = {
  Generic: "GENERIC",
  ERR020: "InvalidEmail",
  ERR021: "InvalidUserId",
  ERR022: "InvalidPassword",
  ERR023: "InvalidSAMLProvider",
  ERR024: "EmailAlreadyExists"
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_NEW_PRODUCT: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          newProduct: payload
        }
      }
    }

    case types.SET_EDIT_DATA: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          editData: payload
        }
      }
    }

    case types.SET_ACTION: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          action: payload
        }
      }
    }

    case types.SET_SELECTED_PRODUCT: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          selectedProduct: payload
        }
      }
    }

    case types.GET_AVAILABLE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        availableProducts: {
          ...state.availableProducts,
          isLoading: false,
          list: payload
        }
      }
    }

    case types.GET_AVAILABLE_PRODUCTS_FAIL: {
      return {
        ...state,
        availableProducts: {
          ...state.availableProducts,
          isLoading: false,
          error: payload
        }
      }
    }

    case types.GET_AVAILABLE_PRODUCTS_REQUEST: {
      return {
        ...state,
        availableProducts: {
          ...state.availableProducts,
          isLoading: true
        }
      }
    }

    case types.UPDATE_ACTIVE_PRODUCT_FAIL:
    case types.DELETE_ACTIVE_PRODUCT_FAIL:
    case types.POST_ACTIVE_PRODUCT_FAIL:
    case types.GET_ACTIVE_PRODUCTS_FAIL: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          error: payload,
          isLoading: false
        }
      }
    }

    case types.UPDATE_ACTIVE_PRODUCT_SUCCESS:
    case types.DELETE_ACTIVE_PRODUCT_SUCCESS:
    case types.POST_ACTIVE_PRODUCT_SUCCESS:
    case types.GET_ACTIVE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          list: payload,
          isLoading: false,
          action: "",
          selectedProduct: {},
          editData: {},
          newProduct: {
            productId: "",
            expiresAt: new Date(),
            invoiceId: ""
          }
        }
      }
    }

    case types.UPDATE_ACTIVE_PRODUCT_REQUEST:
    case types.DELETE_ACTIVE_PRODUCT_REQUEST:
    case types.POST_ACTIVE_PRODUCT_REQUEST:
    case types.GET_ACTIVE_PRODUCTS_REQUEST: {
      return {
        ...state,
        activeProducts: {
          ...state.activeProducts,
          isLoading: true
        }
      }
    }
    case types.SET_CURRENT_ORG:
      return {
        ...state,
        currentOrg: payload
      }
    case types.UPDATE_CREDITS_REQUEST:
    case types.GET_LOGS_BY_ORGANISATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorCode: null
      }
    case types.UPDATE_CREDITS_FAIL:
    case types.GET_LOGS_BY_ORGANISATION_FAIL:
      return {
        ...state,
        isLoading: false,
        errorCode: ErrorTypes[payload] || ErrorTypes.Generic
      }
    case types.GET_LOGS_BY_ORGANISATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorCode: null,
        logs: payload
      }
    case types.UPDATE_CREDITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorCode: null,
        currentOrg: {
          ...state.currentOrg,
          credits: payload.credits
        }
      }
    case types.SET_OPEN_SLIDER:
      return {
        ...state,
        openSlider: {
          ...state.openSlider,
          ...payload
        }
      }
    case types.SET_CLOSE_SLIDER:
      return {
        ...state,
        openSlider: {
          add: false,
          edit: false,
          log: false
        }
      }
    case types.SET_COMPONENT:
      return {
        ...state,
        component: payload
      }
    default:
      return state
  }
}
