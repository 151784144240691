// Redux
import { connect } from "react-redux"
// Store
import { getComponent, getCurrentOrg } from "../store/orgManagement.selectors"
import { setComponent, setCurrentOrg } from "../store/orgManagement.actions"
// Components
import OrganisationPage from "../components/OrganisationPage"

const mapState = state => ({
  currentOrg: getCurrentOrg(state),
  component: getComponent(state)
})

const mapDispatch = dispatch => ({
  setCurrentOrg: org => dispatch(setCurrentOrg(org)),
  setComponent: component => dispatch(setComponent(component))
})

const OrganisationPageCtr = connect(mapState, mapDispatch)(OrganisationPage)

export default OrganisationPageCtr
