export const FEATURE_GROUPS = {
  EBUserManagement: "EBUserManagement",
  Reports: "Reports",
  Status: "Status",
  DataExport: "DataExport",
  CompanyAudit: "CompanyAudit",
  Admin: "Admin",
  CMS: "CMS",
  OrgManagement: "OrgManagement"
}

export const FEATURE_TO_ROUTE_PATH_MAP = {
  [FEATURE_GROUPS.EBUserManagement]: "/user-management",
  [FEATURE_GROUPS.Reports]: "/reports",
  [FEATURE_GROUPS.Status]: "/status",
  [FEATURE_GROUPS.DataExport]: "/survey-data-export",
  [FEATURE_GROUPS.CompanyAudit]: "/ranking-audit",
  [FEATURE_GROUPS.CMS]: "/cms",
  [FEATURE_GROUPS.OrgManagement]: "/organisation-management"
}

export const getShouldRedirectToHome = (groups, featureGroup) => {
  const found = groups.find(
    g => g === FEATURE_GROUPS.Admin || g === featureGroup
  )

  return !found
}
