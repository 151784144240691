import { connect } from "react-redux"
import StatusTableComponent from "../components/StatusTable"
import { statusSelectors } from ".."

const mapState = state => ({
  restStatus: statusSelectors.getEWRestStatus(state),
  graphQLStatus: statusSelectors.getEWGraphQLStatus(state),
  isLoading: statusSelectors.getIsLoading(state)
})

const EWStatusTable = connect(mapState)(StatusTableComponent)

export default EWStatusTable
