import { Box } from "@engaging-tech/components"
import { Redirect } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"
import { authSelectors } from "../../auth"
import Banner from "../containers/Banner"

const CMS = () => {
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.CMS),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />
  return (
    <Box>
      <Banner />
    </Box>
  )
}

export default CMS
