import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Icon from "@engaging-tech/components/dist/lib/Icon/Icon"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import Table from "@engaging-tech/components/dist/lib/Table/Table"
import format from "date-fns/format"
import React, { useEffect } from "react"
import {
  determineNumber,
  Headings,
  lastMonthText,
  queryTypes,
  TableConfig
} from "../lib/reportsLibrary"
import DownloadEmails from "./DownloadEmails"
import OrgEmails from "./OrgEmails"

const prepareUsersData = users =>
  users?.map(user => ({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    createdAt: format(new Date(user.createdAt), "dd/MM/yyyy p"),
    confirmed: user.confirmed && "Y",
    allowMarketing: user.allowMarketing && "Y",
    location: user.location
  }))

const prepareOrgsData = orgs =>
  orgs?.map(org => ({
    name: org.name,
    email: org.email,
    websiteURL: org.websiteURL,
    createdAt: format(new Date(org.createdAt), "dd/MM/yyyy p"),
    confirmed: org.confirmed && "Y",
    allowMarketing: org.allowMarketing && "Y"
  }))

const determineDescription = {
  lifetime: "The total number, up until you refresh the browser",
  lastMonth: `For the whole month of ${lastMonthText}`,
  lastWeek: "Live refresh: The period between the time now and 7 days ago",
  last24: "Live refresh: The period between the time now and 24 hours ago",
  bespoke: "For your selected bespoke dates"
}

const rowData = (selected, queryStats) => [
  {
    Name: "WorkL Sign-ups",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.IndividualUsers?.count),
    Download: (
      <DownloadEmails
        usersData={prepareUsersData(queryStats?.IndividualUsers?.users)}
        type="WorkL Sign-ups"
        selected={selected}
      />
    )
  },
  {
    Name: "WorkL Premium Sign Ups",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.IndividualUsersPremiumStartedAt?.count),
    Download: (
      <DownloadEmails
        usersData={prepareUsersData(
          queryStats?.IndividualUsersPremiumStartedAt?.users
        )}
        type="Premium Sign Ups"
        selected={selected}
      />
    )
  },
  {
    Name: "Total WorkL Individual Surveys taken",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.IndividualSurvey?.completed)
  },
  {
    Name: "WorkL Individual Work From Home Surveys taken",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.IndividualWorkFromHomeSurvey?.completed)
  },
  {
    Name: "WorkL Individual Workplace Happiness Surveys taken",
    Description: determineDescription[selected],
    Number: determineNumber(
      queryStats?.IndividualWorkplaceHappinessSurvey?.completed
    )
  },
  {
    Name: "WorkL Individual Workplace Wellbeing Surveys taken",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.WellbeingSurvey?.completed)
  }
]

const rowDataBusiness = (selected, queryStats) => [
  {
    Name: "WorkL Business Sign-ups",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.OrganisationsUsers?.count),
    Download: (
      <OrgEmails
        usersData={prepareOrgsData(
          queryStats?.OrganisationsUsers?.organisations
        )}
        type="EB Sign-ups"
        selected={selected}
      />
    )
  },
  {
    Name: "WorkL Business Organisation Surveys taken",
    Description: determineDescription[selected],
    Number: determineNumber(queryStats?.OrganisationEmployeeSurvey?.completed)
  },
  {
    Name: "Jobs Board Subscriptions",
    Description: determineDescription[selected],
    Number: determineNumber(
      queryStats?.OrganisationWithJobBoardSubscription?.count
    )
  },
  {
    Name: "Leads Subscriptions",
    Description: determineDescription[selected],
    Number: determineNumber(
      queryStats?.OrganisationWithLeadsSubscription?.count
    )
  },
  {
    Name: "Events Subscriptions",
    Description: determineDescription[selected],
    Number: determineNumber(
      queryStats?.OrganisationWithEventsSubscription?.count
    )
  }
]

const RefreshButton = ({ loadQuery, selected }) => (
  <Flex width={1 / 1} justifyContent="center" mb={4}>
    <Button onClick={() => loadQuery(queryTypes[selected])} variant="primary.0">
      <Icon name="refresh" color="#fff" mx={5} />
    </Button>
  </Flex>
)

const ReportTable = ({ selected, queryStats, loadQuery }) => {
  useEffect(() => {
    if (!queryStats && selected !== "bespoke") loadQuery(queryTypes[selected])
  }, [queryStats, loadQuery, selected])

  if (queryStats?.isLoading)
    return (
      <Spinner color="primary.0" width={50} height={50} alignItems="center" />
    )
  return (
    <Flex mb={4} flexDirection="column" width={1 / 1}>
      {selected !== "bespoke" && (
        <RefreshButton loadQuery={loadQuery} selected={selected} />
      )}
      <div>
        <h3> WorkL Data</h3>
      </div>
      <Table
        headings={Headings}
        rows={rowData(selected, queryStats)}
        config={TableConfig}
      />
      <div>
        <h3> WorkL For Business Data</h3>
      </div>
      <Table
        headings={Headings}
        rows={rowDataBusiness(selected, queryStats)}
        config={TableConfig}
      />
    </Flex>
  )
}

export default ReportTable
