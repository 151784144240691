import React, { useState, useEffect } from "react"

import { createPortal } from "react-dom"
import { Formik } from "formik"
import styled from "styled-components"
import * as Yup from "yup"
import { updateSuccessFlag } from "../store/rankingAudit.actions"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import TextField from "@engaging-tech/components/dist/lib/TextField/TextField"
import Select from "@engaging-tech/components/dist/lib/Select/Select"
import Checkbox from "@engaging-tech/components/dist/lib/Checkbox/Checkbox"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ListEmailsModal = ({ data, setListOpen }) => {
  return createPortal(
    <Modal bg="light.0" p={3}>
      <Flex width={1 / 1} flexDirection="column" alignItems="center">
        {data?.map(el => (
          <Text mb={2} textAlign="center">
            {el}
          </Text>
        ))}
        <Button
          width="160px"
          bg="secondary.0"
          onClick={() => setListOpen(false)}
        >
          Close
        </Button>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default ListEmailsModal
