import { connect } from "react-redux"
import { Snackbar } from "@engaging-tech/components"
import * as CurrentUserSelectors from "../store/currentUser/currentUser.selectors"
import * as CurrentUserActions from "../store/currentUser/currentUser.actions"
import * as OrgSearchSelectors from "../store/organisationSearch/organisationSearch.selectors"
import * as OrgSearchActions from "../store/organisationSearch/organisationSearch.actions"

const ErrorTypes = {
  InvalidEmail: "No user with this email exists. Please try another email.",
  InvalidUserId: "This user does not exist",
  InvalidPassword:
    "This password does not fit the constraints. Please try a different password.",
  InvalidSAMLProvider:
    "The SAML provider name does not exist. Please use an existing provider name.",
  EmailAlreadyExists:
    "The email you provided is already in use. Please try a different email address.",
  InvalidOrg: "Please select an existing organisation.",
  GENERIC: "Something went wrong. Please try again later."
}

const mapState = (state, { type }) => {
  const errorCode =
    type === "user"
      ? CurrentUserSelectors.getErrorCode(state)
      : OrgSearchSelectors.getErrorCode(state)
  return {
    isVisible: !!errorCode,
    content: ErrorTypes[errorCode],
    label: "X"
  }
}

const mapDispatch = (dispatch, { type }) => ({
  action: () => {
    if (type === "user") {
      dispatch(CurrentUserActions.clearError())
    } else {
      dispatch(OrgSearchActions.clearError())
    }
  }
})

const EditUserForm = connect(mapState, mapDispatch)(Snackbar)

export default EditUserForm
