import React from "react"
import ReportsView from "../features/reports/views/Reports"
import BaseLayout from "../layouts/BaseLayout"

const Reports = () => (
  <BaseLayout>
    <ReportsView />
  </BaseLayout>
)

export default Reports
