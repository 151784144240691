import { takeLatest, put, call, select } from "redux-saga/effects"
import { getOrgs } from "../../../portable/SearchOrgInput/store/searchorg.selectors"

import {
  loadUsersByOrg,
  createUser
} from "../../services/ebUserManagement.service"

import * as Actions from "./organisationSearch.actions"
import * as Selectors from "./organisationSearch.selectors"

const getGraphQLErrCode = err => {
  console.log({ err })
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

export function* loadUsersByOrgSaga(action) {
  try {
    const res = yield call(loadUsersByOrg, action.payload)
    yield put(Actions.loadUsersByOrgSuccess(res?.data?.getUsersByOrganisation))
  } catch (error) {
    yield put(Actions.loadUsersByOrgFailure(getGraphQLErrCode(error)))
  }
}

export function* createUserSaga(action) {
  try {
    const res = yield call(createUser, action.payload)
    if (res?.data?.createUser) {
      yield put(Actions.createUserSuccess(res.data.createUser))
    }
  } catch (error) {
    yield put(Actions.createUserFail(getGraphQLErrCode(error)))
  }
}

export function* loadUsersByOrgNameSaga(action) {
  const orgs = yield select(getOrgs)
  const org = orgs.find(o => o.name === action.payload.name)
  if (org) {
    yield put(Actions.loadUsersByOrgId({ orgId: org.id, ...action.payload }))
  } else {
    yield put(Actions.loadUsersByOrgFailure("ERR025"))
  }
}

export function* loadMoreUsersSaga(action) {
  try {
    const currentOrg = yield select(Selectors.getCurrentOrg)
    const res = yield call(loadUsersByOrg, {
      skip: action.payload,
      orgId: currentOrg.id
    })
    if (res?.data?.getUsersByOrganisation) {
      yield put(
        Actions.loadMoreOrgUsersSuccess(res.data.getUsersByOrganisation)
      )
    }
  } catch (error) {
    yield put(Actions.loadMoreOrgUsersFailure(getGraphQLErrCode(error)))
  }
}

export function* saga() {
  yield takeLatest([Actions.types.LOAD_USERS_BY_ORG_ID], loadUsersByOrgSaga)
  yield takeLatest(
    [Actions.types.LOAD_USERS_BY_ORG_NAME],
    loadUsersByOrgNameSaga
  )
  yield takeLatest([Actions.types.CREATE_USER], createUserSaga)
  yield takeLatest([Actions.types.LOAD_MORE_USERS], loadMoreUsersSaga)
}
