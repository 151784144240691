import React from "react"
import CMSView from "../features/cms/views/CMS"
import BaseLayout from "../layouts/BaseLayout"

const CMS = () => (
  <BaseLayout>
    <CMSView />
  </BaseLayout>
)

export default CMS
