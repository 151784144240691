import React, { useState } from "react"
import styled from "styled-components"

import { hideVisually } from "polished"
import PropTypes from "prop-types"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  ${hideVisually()}

  /* Highlight the checkbox when tabbing to it on keyboard */
  &:focus ~ div > div:first-child {
    background-color: ${({ theme }) => theme.colors.dark[5]};
  }
`

const Wrapper = styled.label`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
`

const LabelWrapper = styled.div`
  user-select: none;
`

const CustomRadio = styled(Flex)`
  width: 20px;
  height: 20px;
  transition: 0.18s;
  flex-shrink: 0;

  &:hover {
    background-color: ${({ theme, isSelected }) =>
      isSelected ? "none" : theme.colors.dark[5]};
  }
`

const Radio = ({
  id,
  disabled,
  label: Label,
  colour,
  onClick,
  checked,
  ...props
}) => {
  const [isSelected, setIsSelected] = useState(checked)

  const handleClick = e => {
    setIsSelected(e.target.value)
    onClick(e)
  }

  return (
    <Wrapper disabled={disabled}>
      <HiddenRadio
        id={id}
        defaultChecked={checked}
        disabled={disabled}
        onClick={e => handleClick(e)}
        {...props}
      />
      <Flex alignItems="center" flexDirection="row">
        <CustomRadio
          isSelected={isSelected}
          mr={3}
          border="2px solid"
          borderColor={colour}
          borderRadius={99}
          justifyContent="center"
          alignItems="center"
        >
          {checked && (
            <Flex
              width="10px"
              height="10px"
              bg={checked ? colour : "transparent"}
              borderRadius="50%"
            />
          )}
        </CustomRadio>
        {Label && (
          <LabelWrapper>
            <Label />
          </LabelWrapper>
        )}
      </Flex>
    </Wrapper>
  )
}

Radio.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  colour: PropTypes.string,
  checked: PropTypes.bool
}

Radio.defaultProps = {
  label: () => null,
  disabled: false,
  checked: false,
  colour: "primary.0"
}

export default Radio
