import { Button, Flex } from "@engaging-tech/components"
import React from "react"

const ControlButtons = ({
  setSelectedProduct,
  product,
  setAction,
  setEditData
}) => (
  <Flex flexDirection="column" width="100px">
    <Button
      mb={2}
      bg="#ff9900"
      width="100px"
      onClick={() => {
        setAction("update")
        setEditData(product)
        setSelectedProduct(product)
      }}
    >
      Edit
    </Button>
    <Button
      bg="primary.1"
      width="100px"
      onClick={() => {
        setAction("delete")
        setSelectedProduct(product)
      }}
    >
      Delete
    </Button>
  </Flex>
)

export default ControlButtons
