import { takeLatest, put, call } from "redux-saga/effects"

import {
  loadEntries,
  updateSingleEntry,
  removeSingleEntry,
  mergeEntries
} from "../services/rankingAudit.service"

import * as Actions from "./rankingAudit.actions"

const determineType = string => {
  if (string === "null") return null
  if (string === "false") return false
  if (string === "true") return true
  if (string === "All") return null
  return string
}

const prepareData = data => {
  return {
    ...data,
    reviewed: data.reviewed ? determineType(data.reviewed) : null,
    invalid: data.invalid ? determineType(data.invalid) : null,
    hasEmails: data.hasEmails ? determineType(data.hasEmails) : null,
    score: data.score ? determineType(data.score) : null
  }
}

export function* loadSaga(action) {
  const submissionParams = prepareData(action.payload)

  try {
    const res = yield call(loadEntries, submissionParams)
    yield put(Actions.loadSuccess(res.data.getSurveyCompanies))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* updateEntrySaga(action) {
  const prepareEmails = payload => {
    return {
      id: payload.id,
      companyName: payload.companyName,
      industryId: payload.industryId,
      countryId: payload.countryId,
      reviewed: payload.reviewed,
      invalid: payload.invalid,
      emails: payload.emails.split(",").map(el => el.trim())
    }
  }

  try {
    yield call(updateSingleEntry, prepareEmails(action.payload))
    yield put(Actions.updateEntrySuccess())
  } catch (errors) {
    if (errors.graphQLErrors[0].extensions.code === "ERR012") {
      yield put(Actions.setHalfSuccess(action.payload.companyName))
    } else {
      yield put(Actions.updateEntryFail())
    }
  }
}

export function* removeEntrySaga(action) {
  try {
    yield call(removeSingleEntry, action.payload)
    yield put(Actions.removeEntrySuccess())
  } catch (err) {
    yield put(Actions.removeEntryFail())
  }
}

export function* loadMoreSaga(action) {
  const submissionParams = prepareData(action.payload)
  try {
    const res = yield call(loadEntries, submissionParams)
    yield put(Actions.loadMoreSuccess(res.data.getSurveyCompanies))
  } catch {
    yield put(Actions.loadMoreFail())
  }
}

export function* mergeEntriesSaga(action) {
  try {
    yield call(mergeEntries, action.payload)
    yield put(Actions.updateSuccessFlag())
  } catch (err) {
    yield put(Actions.loadFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.LOAD_MORE, loadMoreSaga)
  yield takeLatest(Actions.types.UPDATE_ENTRY, updateEntrySaga)
  yield takeLatest(Actions.types.REMOVE_ENTRY, removeEntrySaga)
  yield takeLatest(Actions.types.MERGE_ENTRIES, mergeEntriesSaga)
}
