// React
import React from "react"
// Eng / Styled Components
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import { Flex } from "@engaging-tech/components"
import TextField from "@engaging-tech/components/dist/lib/TextField/TextField"
import styled from "styled-components"
// Forms
import * as Yup from "yup"
import { useFormik } from "formik"

const validationSchema = Yup.object().shape({
  newValue: Yup.number().required("Required")
})

const FormWrapper = styled.form`
  width: 50%;
`

const CreditsEditor = ({
  openSlider,
  currentOrg,
  onSubmit,
  setCloseSlider,
  user
}) => {
  const { credits, id } = currentOrg

  const editorText = openSlider.add ? "Add" : "Edit"

  const initialValues = {
    id,
    newValue: openSlider.add ? 0 : credits,
    creditActions: openSlider,
    oldValue: credits,
    userId: user.attributes.email
  }

  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    validateField
  } = useFormik({
    initialValues,
    validateOnChange: true,
    onSubmit,
    validationSchema
  })

  const handleFieldChange = field => e => {
    handleChange(e)
    validateField(field)
  }

  return (
    <Flex width={1 / 1} flexDirection="column" alignItems="center">
      <FormWrapper onSubmit={handleSubmit}>
        <Flex width={1 / 1} flexDirection="column" alignItems="center">
          <TextField
            name="newValue"
            type="number"
            width={1 / 1}
            error={errors.newValue}
            value={values.newValue}
            onChange={handleFieldChange("newValue")}
            onBlur={handleBlur}
            id="newValue"
            label={`${editorText} credits`}
          />
          <Button type="submit" bg="#ff9900" width="150px">
            {`OK, ${editorText}`}
          </Button>
        </Flex>
      </FormWrapper>
      <Button
        bg="triary.0"
        width="150px"
        my={2}
        onClick={() => setCloseSlider()}
      >
        Back
      </Button>
    </Flex>
  )
}

export default CreditsEditor
