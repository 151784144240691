import { useEffect } from "react"
import { connect } from "react-redux"
import { loadStatus } from "../store/status.actions"

const StatusLoaderComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
    const interval = setInterval(() => onLoad(), 60000)
    return () => clearInterval(interval)
  }, [onLoad])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(loadStatus())
})

const StatusLoader = connect(null, mapDispatch)(StatusLoaderComponent)

export default StatusLoader
