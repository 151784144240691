import { connect } from "react-redux"
import {
  getIsLoadingOrgs,
  getOrgs
} from "../../portable/SearchOrgInput/store/searchorg.selectors"
import OrganisationsTableComponent from "../components/OrganisationsTable"
import { getCurrentOrg } from "../store/orgManagement.selectors"
import { setCurrentOrg } from "../store/orgManagement.actions"

const mapState = state => ({
  orgs: getOrgs(state),
  isLoading: getIsLoadingOrgs(state),
  currentOrg: getCurrentOrg(state)
})

const mapDispatch = dispatch => ({
  setCurrentOrg: org => dispatch(setCurrentOrg(org))
})

const OrganisationTable = connect(
  mapState,
  mapDispatch
)(OrganisationsTableComponent)

export default OrganisationTable
