import React from "react"

import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Card from "@engaging-tech/components/dist/lib/Card/Card"
import OrganisationNameFilter from "../../containers/OptionsSelect/OrganisationNameFilter"
import AdditionalColumns from "../../containers/OptionsSelect/AdditionalColumns"
import DateRange from "../../containers/OptionsSelect/DateRange"

const OptionsSelect = () => {
  return (
    <Card width={1} border="1px solid black" p={3} alignItems="center" mt={3}>
      <Text width="auto" fontSize={5}>
        Options
      </Text>
      <Flex width={1} flexDirection={["column", "column", "row"]}>
        <OrganisationNameFilter mr={[0, 0, 3]} mb={[3, 3, 0]} />
        <AdditionalColumns ml={[0, 0, 3]} />
        <DateRange />
      </Flex>
    </Card>
  )
}

export default OptionsSelect
