import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../lib/Apollo"
import {
  UPDATE_CREDITS,
  GET_LOGS_BY_ORGANISATION,
  GET_AVAILABLE_PRODUCTS,
  DELETE_ACTIVE_PRODUCT,
  UPDATE_ACTIVE_PRODUCT,
  POST_ACTIVE_PRODUCT,
  GET_ACTIVE_PRODUCTS
} from "./orgManagement.queries"

const GRAPHQL_API_URL = () => getConfig().api

const apollo = new Apollo()

export const updateCredits = ({
  id,
  newValue,
  oldValue,
  creditActions,
  userId
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_CREDITS,
    variables: { id, newValue, oldValue, creditActions, userId }
  })

export const getLogsByOrganisation = ({ organisationId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_LOGS_BY_ORGANISATION,
    variables: { organisationId }
  })

export const getActiveProducts = ({ organisationId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_ACTIVE_PRODUCTS,
    variables: { organisationId }
  })

export const getAvailableProducts = ({ organisationId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_AVAILABLE_PRODUCTS,
    variables: { organisationId }
  })

export const deleteActiveProduct = ({ id, organisationId }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: DELETE_ACTIVE_PRODUCT,
    variables: { id, organisationId }
  })

export const updateActiveProduct = ({
  id,
  organisationId,
  expiresAt,
  invoiceId
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_ACTIVE_PRODUCT,
    variables: { id, organisationId, expiresAt, invoiceId }
  })

export const postActiveProduct = ({
  productId,
  organisationId,
  expiresAt,
  invoiceId
}) => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: POST_ACTIVE_PRODUCT,
    variables: { productId, organisationId, expiresAt, invoiceId }
  })
}
