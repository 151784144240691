import { connect } from "react-redux"
import BannerComponent from "../components/Banner"
import { load, saveBanner } from "../store/cms.actions"
import {
  getBanner,
  getHasError,
  getIsLoading,
  getIsSaving
} from "../store/cms.selectors"

const mapState = state => ({
  isLoading: getIsLoading(state),
  isSaving: getIsSaving(state),
  hasError: getHasError(state),
  banner: getBanner(state)
})

const mapDispatch = dispatch => ({
  onSave: payload => dispatch(saveBanner(payload)),
  onLoad: () => {
    dispatch(load("WORKL_BANNER"))
    dispatch(load("WFB_BANNER"))
  }
})

const Banner = connect(mapState, mapDispatch)(BannerComponent)

export default Banner
