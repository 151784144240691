import { connect } from "react-redux"
import { reportsSelectors } from ".."
import BespokeComponent from "../components/Bespoke.js"

const mapState = state => ({
  queryStats: reportsSelectors.getBespokeStats(state)
})

const Bespoke = connect(mapState)(BespokeComponent)

export default Bespoke
