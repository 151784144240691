export const types = {
  LOAD_USER: "[EB User Management] Load User",
  LOAD_USER_SUCCESS: "[EB User Management] Load User Success",
  LOAD_USER_FAIL: "[EB User Management] Load User Failure",
  UPDATE_USER: "[EB User Management] Update User",
  UPDATE_USER_SUCCESS: "[EB User Management] Update User Success",
  UPDATE_USER_FAIL: "[EB User Management] Update User Failure",
  CLEAR_ERROR: "[EB User Management] Clear Error",
  DELETE_USER: "[EB User Management] Delete User",
  DELETE_USER_SUCCESS: "[EB User Management] Delete User Success",
  DELETE_USER_FAIL: "[EB User Management] Delete User Failure"
}

export const deleteUser = userId => ({
  type: types.DELETE_USER,
  payload: userId
})
export const deleteUserSuccess = userId => ({
  type: types.DELETE_USER_SUCCESS
})
export const deleteUserFail = err => ({
  type: types.DELETE_USER_FAIL,
  payload: err
})

export const clearError = () => ({
  type: types.CLEAR_ERROR
})

export const updateUser = ({
  userId,
  firstName,
  lastName,
  email,
  password,
  organisation
}) => ({
  type: types.UPDATE_USER,
  payload: { userId, firstName, lastName, email, password, organisation }
})

export const updateUserSuccess = user => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: user
})

export const updateUserFail = err => ({
  type: types.UPDATE_USER_FAIL,
  payload: err
})

export const loadUserByEmail = email => ({
  type: types.LOAD_USER,
  payload: { email }
})

export const loadUserByUserId = userId => ({
  type: types.LOAD_USER,
  payload: { userId }
})

export const loadUserSuccess = user => ({
  type: types.LOAD_USER_SUCCESS,
  payload: user
})

export const loadUserFail = err => ({
  type: types.LOAD_USER_FAIL,
  payload: err
})
