import React from "react"
import AllRoutes from "@engaging-tech/routing/dist/components/AllRoutes"
import routes from "./routes"
import AuthGuard from "./features/auth/containers/AuthGuard"

const App = () => {
  return (
    <React.StrictMode>
      <AuthGuard>
        <AllRoutes routes={routes} />
      </AuthGuard>
    </React.StrictMode>
  )
}

export default App
