export const types = {
  FETCH_SURVEYS: "[Fetch Surveys For Org] Fetch Surveys",
  FETCH_SURVEYS_SUCCESS: "[Fetch Surveys For Org] Fetch Surveys Success",
  FETCH_SURVEYS_FAILURE: "[Fetch Surveys For Org] Fetch Surveys Failure"
}

export const fetchSurveys = ({ organisationId }) => ({
  type: types.FETCH_SURVEYS,
  payload: { organisationId }
})

export const fetchSurvesSuccess = surveys => ({
  type: types.FETCH_SURVEYS_SUCCESS,
  payload: surveys
})

export const fetchSurveysFail = err => ({
  type: types.FETCH_SURVEYS_FAILURE,
  payload: err
})
