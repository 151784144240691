import { connect } from "react-redux"
import Component from "../../components/SurveySelect/GetIsWorkFromHome"
import { setField } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  isWorkFromHome: getField(state, "isWorkFromHome")
})

const mapDispatch = dispatch => ({
  handleClick: val => {
    dispatch(setField("isWorkFromHome", val))
  }
})

export default connect(mapState, mapDispatch)(Component)
