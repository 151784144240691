import { connect } from "react-redux"
import RemoveUserFormComponent from "../components/RemoveUserForm"
import { deleteUser } from "../store/currentUser/currentUser.actions"

const mapDispatch = (dispatch, { toggle }) => ({
  handleRemoveUser: userId => {
    dispatch(deleteUser(userId))
    toggle()
  }
})

const RemoveUserForm = connect(null, mapDispatch)(RemoveUserFormComponent)

export default RemoveUserForm
