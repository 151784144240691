import React from "react"
import styled, { css } from "styled-components"
import { Link } from "@engaging-tech/routing"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"

const Container = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`
const MenuGroup = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const disabledStyle = css`
  color: ${({ theme }) => theme.colors.dark[3]};
  opacity: 0.54;
  cursor: not-allowed;
`

const TabLink = styled(Link)`
  display: block;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary[0] : theme.colors.dark[2]};

  border-bottom: ${({ theme, selected }) =>
    selected
      ? `2px solid ${theme.colors.primary[0]}`
      : `0px solid ${theme.colors.dark[0]}`};
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;

  ${({ disabled }) => (disabled ? disabledStyle : "")}
`

const TabWrapper = styled("div")`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary[0] : theme.colors.dark[1]};
  padding: 5px;
  font-size: ${({ theme, selected }) => (selected ? "20px" : "16px")};
  font-weight: ${({ theme, selected }) => (selected ? "800" : "400")};
  margin: 2px;
  ${({ disabled }) => (disabled ? disabledStyle : "")}
  border-bottom: ${({ theme, selected }) =>
    selected
      ? `2px solid ${theme.colors.primary[0]}`
      : `0px solid ${theme.colors.dark[0]}`};
`

const Tabs = ({ routes, selectedTab }) => (
  <MenuGroup pl={[0, 0, 2]} pt={[0, 0, 2]}>
    {routes &&
      routes.map(route => (
        <TabWrapper selected={selectedTab === route.path}>
          <TabLink
            onClick={route.onClick}
            key={route.path}
            disabled={!!route.isDisabled}
            selected={selectedTab === route.path}
          >
            {route.description}
          </TabLink>
        </TabWrapper>
      ))}
  </MenuGroup>
)

const TabBar = ({ routes, selectedTab }) => (
  <Container>
    <Tabs selectedTab={selectedTab} routes={routes} />
  </Container>
)

export default TabBar
