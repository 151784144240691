import { createSelector } from "reselect"

const reportsState = state => state && state?.reports

export const getIsLoading = createSelector(
  reportsState,
  state => state?.isLoading
)

export const getSelectedQueryType = createSelector(
  reportsState,
  state => state?.selectedQuery
)

export const getLifetimeStats = createSelector(
  reportsState,
  state => state?.lifetime
)
export const getLastMonthStats = createSelector(
  reportsState,
  state => state?.lastMonth
)
export const getLastWeekStats = createSelector(
  reportsState,
  state => state?.lastWeek
)
export const getLast24Stats = createSelector(
  reportsState,
  state => state?.last24
)
export const getBespokeStats = createSelector(
  reportsState,
  state => state?.bespoke
)
