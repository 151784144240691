import { connect } from "react-redux"
import { authActions, authSelectors } from ".."

import AuthGuardComponent from "../components/AuthGuard.js"

const mapState = state => ({
  user: authSelectors.getUser(state),
  authState: authSelectors.getAuthState(state)
})

const mapDispatch = dispatch => ({
  updateUser: user => dispatch(authActions.updateUser(user)),
  updateAuthState: authState => dispatch(authActions.updateAuthState(authState))
})

const AuthGuard = connect(mapState, mapDispatch)(AuthGuardComponent)

export default AuthGuard
