import { connect } from "react-redux"
import Component from "../../components/SurveySelect/SurveyTypeRadios"
import { setField } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  surveyType: getField(state, "surveyType")
})

const mapDispatch = dispatch => ({
  handleClick: type => dispatch(setField("surveyType", type))
})

export default connect(mapState, mapDispatch)(Component)
