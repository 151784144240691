import { connect } from "react-redux"
import GenerateFormComponent from "../components/GenerateForm"
import { getField } from "../store/dataexport.selectors"

const mapState = state => ({
  isGenerating: getField(state, "isGenerating"),
  exportUrl: getField(state, "exportUrl")
})

export default connect(mapState)(GenerateFormComponent)
