import React from "react"
import { createPortal } from "react-dom"
import { useFormik } from "formik"
import styled from "styled-components"
import * as Yup from "yup"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import DatePicker from "@engaging-tech/components/dist/lib/DatePicker/DatePicker"
import Button from "@engaging-tech/components/dist/lib/Button/Button"

const FormWrapper = styled.form`
  margin-top: ${({ theme }) => theme.space[3]}px;
  width: 100%;
  height: 100%;
`

const initialValues = {
  startDate: "",
  endDate: ""
}

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const BespokeModal = ({ toggle, onSubmit }) => {
  const validationSchema = Yup.object().shape({
    endDate: Yup.date().required("This field must be a date"),
    startDate: Yup.date()
      .required("This field must be a date")
      .when(
        "endDate",
        (endDate, schema) =>
          endDate && schema.max(endDate, "Start Date must be before End Date")
      )
  })

  const Formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  })
  const {
    values,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    isValid
  } = Formik

  const handleFormSubmission = e => {
    handleSubmit(e)
    toggle()
  }

  return createPortal(
    <Modal
      bg="light.0"
      onToggle={toggle}
      flexDirection="column"
      alignItems="center"
      p={4}
      height={500}
      maxWidth="650px"
    >
      <FormWrapper onSubmit={handleFormSubmission}>
        <Flex
          flexDirection="column"
          px={2}
          height="100%"
          justifyContent="space-between"
        >
          <Flex flexDirection="column">
            <Text
              width="auto"
              fontSize={5}
              fontWeight={600}
              color="primary.0"
              mb={4}
            >
              Choose your dates:
            </Text>
            <Text mb={5} fontSize={3}>
              The results given will be taken from the start of your inputted
              start date until the end of your inputted end date.
            </Text>
            <Flex
              flexDirection="row"
              justifyContent="space-evenly"
              width={1 / 1}
            >
              <DatePicker
                width={1 / 1}
                onClick={() => setFieldTouched("startDate", true, true)}
                name="startDate"
                id="startDate"
                type="date"
                format="dd/MM/yyyy"
                value={values.startDate}
                handleChange={val => setFieldValue("startDate", val)}
                helperText="Start Date"
                error={touched.startDate && errors.startDate}
              />
              <DatePicker
                width={1 / 1}
                onClick={() => setFieldTouched("endDate", true, true)}
                name="endDate"
                id="endDate"
                type="date"
                format="dd/MM/yyyy"
                value={values.endDate}
                handleChange={val => setFieldValue("endDate", val)}
                helperText="End Date"
                error={touched.endDate && errors.endDate}
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection="row-reverse"
            justifyContent="space-between"
            width={1 / 1}
          >
            <Button
              variant="primary.0"
              type="submit"
              disabled={!isValid || !values.startDate || !values.endDate}
            >
              Submit
            </Button>
            <Button variant="light.0" onClick={toggle} elevation={0}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </FormWrapper>
    </Modal>,
    appRoot()
  )
}

export default BespokeModal
