import React from "react"

import { TableConfig, Headings } from "../lib/statusLibrary"
import Icon from "@engaging-tech/components/dist/lib/Icon/Icon"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Table from "@engaging-tech/components/dist/lib/Table/Table"

const determineStatus = seconds => {
  if (seconds < 120)
    return {
      icon: <Icon name="check-circle" color="secondary.0" />,
      text: "service working fine"
    }
  if (seconds >= 120 && seconds < 540)
    return {
      icon: <Icon name="error_outline" color="error.2" />,
      text: "service slightly slower than expected"
    }
  if (seconds >= 540)
    return {
      icon: <Icon name="error" color="error.0" />,
      text: "service taking longer than expected"
    }
  return {
    icon: (
      <Spinner color="primary.0" width={25} height={25} alignItems="center" />
    ),
    text: "retrieving data"
  }
}

const StatusTable = ({ title, restStatus, graphQLStatus, isLoading }) => {
  const rowDataSorted = graphQLStatus
    ?.concat(restStatus)
    .sort((a, b) => a.service.localeCompare(b.service))

  const rowDataMapped = rowDataSorted?.map(row => {
    return {
      ...row,
      status: (
        <Flex width={1 / 1} justifyContent="center">
          {determineStatus(row?.timestamp).icon}
        </Flex>
      ),
      text: determineStatus(row?.timestamp).text
    }
  })

  return (
    <>
      <Text fontSize={5} color="primary.0" fontWeight={600}>
        {title}
      </Text>
      {isLoading ? (
        <Spinner color="primary.0" width={50} height={50} alignItems="center" />
      ) : (
        <Table
          headings={Headings}
          rows={rowDataMapped}
          config={TableConfig}
          my={4}
        />
      )}
    </>
  )
}

export default StatusTable
