const TableConfig = {
  stylesPerColumn: [
    {
      fontSize: 14,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    },
    {
      fontSize: 14,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 14,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 14,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 14,
      fontWeight: 500,
      color: "dark.1"
    },
    { justifyContent: "center", alignItems: "center" },
    { justifyContent: "center" },
    { justifyContent: "center" }
  ],
  columnWidths: [
    11 / 100,
    11 / 100,
    11 / 100,
    11 / 100,
    11 / 100,
    11 / 100,
    11 / 100,
    11 / 100,
    11 / 100
  ],
  main: {
    borderRadius: "10px"
  },
  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: "dark.3",
    py: 2
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 2,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const Headings = [
  { id: "id", title: "Entry ID" },
  { id: "companyName", title: "Company Name" },
  { id: "industry", title: "Industry" },
  { id: "country", title: "Country" },
  { id: "emails", title: "Emails" },
  { id: "score", title: "Score" },
  { id: "reviewed", title: "Reviewed" },
  { id: "editBtn", title: "Edit" },
  { id: "deleteBtn", title: "Remove" }
]

const COMPANIES_TO_LOAD = 10

export { TableConfig, Headings, COMPANIES_TO_LOAD }
