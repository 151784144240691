import React, { useEffect, useState } from "react"
import useRouter from "@engaging-tech/routing/dist/hooks/useRouter"
import useRoutes from "@engaging-tech/routing/dist/hooks/useRoutes"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import { Authenticator } from "@aws-amplify/ui-react"
import styled, { css } from "styled-components"
import Link from "@engaging-tech/routing/dist/components/Link"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import { useDispatch, useSelector } from "react-redux"
import { authSelectors } from "../../auth"
import {
  FEATURE_GROUPS,
  FEATURE_TO_ROUTE_PATH_MAP
} from "../../../lib/Features"
import { updateUser } from "../../auth/store/auth.actions"

const ActiveNavItemText = css`
  color: ${({ theme }) => theme.colors.primary[0]};
`

const InactiveNavItemText = css`
  color: ${({ theme }) => theme.colors.primary[1]};
`

const NavItemText = styled(Text)`
  ${({ isActive }) => (isActive ? ActiveNavItemText : InactiveNavItemText)};
`

const NavItem = ({ route }) => {
  const router = useRouter()
  const [highlightText, setHighlightText] = useState(false)

  const handleMouseHover = isMouseEnter => {
    if (isMouseEnter) {
      setHighlightText(true)
    } else {
      setHighlightText(router.isMatch(route.path))
    }
  }

  useEffect(() => {
    setHighlightText(router.isMatch(route.path))
  }, [route, router])

  return (
    <Flex
      position="relative"
      as="li"
      onMouseEnter={() => handleMouseHover(true)}
      onMouseLeave={() => handleMouseHover(false)}
      justifyContent="center"
      mr={[3, 5]}
    >
      <Link to={route.path}>
        <Flex flexDirection="column" alignItems="center">
          <NavItemText
            fontSize={18}
            fontFamily="Avenir Next"
            my={[2, 4]}
            fontWeight={700}
            isActive={highlightText}
          >
            {route.description}
          </NavItemText>
        </Flex>
      </Link>
    </Flex>
  )
}

const Nav = () => {
  const dispatch = useDispatch()
  const routes = useRoutes()

  const groups = useSelector(authSelectors.getGroups)

  let navRoutes = []
  if (groups.includes(FEATURE_GROUPS.Admin)) {
    navRoutes = routes
  } else {
    navRoutes = groups.map(groupName => {
      const path = FEATURE_TO_ROUTE_PATH_MAP[groupName]
      return routes.find(route => route.path === path)
    })
  }

  return (
    <Box as="nav" position="relative" elevation={4}>
      <Flex
        width={1 / 1}
        as="ul"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mt={0}
        ml={[3, 6]}
        pr={[0, 7]}
      >
        <Flex
          width="auto"
          maxWidth={830}
          as="ul"
          justifyContent="space-between"
          mt={0}
        >
          {navRoutes.map(route => (
            <NavItem route={route} key={route.description} />
          ))}
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          width="auto"
        >
          <Authenticator hideSignUp>
            {({ signOut, user }) => (
              <Button
                variant="secondary.0"
                onClick={() => {
                  signOut()
                  dispatch(updateUser(null))
                }}
                disabled={!user}
                minWidth={1 / 2}
              >
                Sign Out
              </Button>
            )}
          </Authenticator>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Nav
