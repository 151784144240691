// Eng / Styled Components
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import { Button } from "@engaging-tech/components"
import styled from "styled-components"
// React
import React from "react"
import { createPortal } from "react-dom"

const StyledModal = styled(Modal)`
  overflow: hidden;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const OrganisationModal = ({ setComponent, component, setCloseSlider }) => {
  const handleClose = () => {
    setCloseSlider()
    setComponent(null)
  }

  return createPortal(
    <StyledModal
      flexDirection="column"
      bg="light.0"
      onToggle={() => handleClose()}
      p={3}
      alignItems="center"
    >
      {component}
      <Button
        variant="outline.primary.0"
        onClick={() => handleClose()}
        width="120px"
        bg="white"
      >
        Close
      </Button>
    </StyledModal>,
    appRoot()
  )
}

export default OrganisationModal
