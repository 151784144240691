// Redux
import { connect } from "react-redux"
// Store
import {
  getCurrentOrg,
  getIsLoading,
  getOpenSlider
} from "../../../store/orgManagement.selectors"
import { setOpenSlider } from "../../../store/orgManagement.actions"
// Components
import CreditsPanel from "../../../components/management_actions/manage_credits/CreditsPanel"

const mapState = state => ({
  openSlider: getOpenSlider(state),
  currentOrg: getCurrentOrg(state),
  isLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  setOpenSlider: sliderActions => dispatch(setOpenSlider(sliderActions))
})

const CreditsPanelCtr = connect(mapState, mapDispatch)(CreditsPanel)

export default CreditsPanelCtr
