import { types } from "./fetchsurveys.actions"

export const initialState = {
  isLoading: false,
  surveys: null,
  error: null
}

export const ErrorTypes = {
  Generic: "GENERIC",
  ERR020: "InvalidEmail",
  ERR021: "InvalidUserId",
  ERR022: "InvalidPassword",
  ERR023: "InvalidSAMLProvider",
  ERR024: "EmailAlreadyExists",
  ERR025: "InvalidOrg"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SURVEYS:
      return {
        ...state,
        surveys: null,
        isLoading: true,
        error: null
      }

    case types.FETCH_SURVEYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        surveys: action.payload || []
      }

    case types.FETCH_SURVEYS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: ErrorTypes[action.payload] || ErrorTypes.Generic
      }

    default:
      return state
  }
}
