import React from "react"
import StatusView from "../features/status/views/Status"
import BaseLayout from "../layouts/BaseLayout"

const Status = () => (
  <BaseLayout>
    <StatusView />
  </BaseLayout>
)

export default Status
