import { types } from "./rankingAudit.actions"

export const initialState = {
  isLoading: false,
  updateSuccess: false,
  entries: [],
  hasError: false,
  isLoadingMore: false,
  mergeRequest: false,
  newCompanyName: "",
  isSubmitting: false,
  noChange: false,
  isMerging: false,
  isDeleting: false,
  deleteError: false,
  deleteSuccess: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        isLoading: true
      }
    case types.REMOVE_ENTRY:
      return {
        ...state,
        isDeleting: true
      }
    case types.UPDATE_ENTRY:
      return {
        ...state,
        isSubmitting: true
      }
    case types.LOAD_MORE:
      return { ...state, isLoadingMore: true }
    case types.LOAD_MORE_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        entries: action.payload
      }
    case types.REMOVE_ENTRY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        deleteError: false
      }
    case types.UPDATE_ENTRY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        updateSuccess: true,
        hasError: false
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        entries: action.payload,
        isLoading: false
      }
    case types.REMOVE_ENTRY_FAIL:
      return {
        ...state,
        isDeleting: false,
        deleteError: true
      }
    case types.UPDATE_ENTRY_FAIL:
    case types.LOAD_FAIL:
      return {
        ...state,
        isSubmitting: false,
        hasError: true
      }
    case types.LOAD_MORE_FAIL:
      return { ...state, isLoadingMore: false }
    case types.RESET_DELETE_SUCCESS:
    case types.RESET_UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccess: false,
        deleteSuccess: false
      }
    case types.RESET_DELETE_ERROR:
    case types.RESET_HAS_ERROR:
      return {
        ...state,
        hasError: false,
        deleteError: false
      }
    case types.SET_MERGE_REQUEST:
      return {
        ...state,
        mergeRequest: false
      }
    case types.SET_HALF_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        hasError: false,
        mergeRequest: true,
        newCompanyName: action.payload
      }
    case types.UPDATE_SUCCESS_FLAG:
      return {
        ...state,
        updateSuccess: true,
        isMerging: false
      }
    case types.UPDATE_NO_CHANGE:
      return {
        ...state,
        noChange: action.payload
      }
    case types.MERGE_ENTRIES:
      return {
        ...state,
        isMerging: true
      }

    default:
      return state
  }
}
