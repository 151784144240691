import { types } from "./auth.actions"

export const initialState = {
  user: null,
  authState: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_USER:
      return {
        ...state,
        user: action.payload
      }
    case types.UPDATE_AUTH_STATE:
      return {
        ...state,
        authState: action.payload
      }

    default:
      return state
  }
}
