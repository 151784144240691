import React from "react"

import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Chip from "@engaging-tech/components/dist/lib/Chip/Chip"
import TextField from "@engaging-tech/components/dist/lib/TextField/TextField"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Select from "@engaging-tech/components/dist/lib/Select/Select"

const Filters = ({
  entryChipShowing,
  setEntryChipShowing,
  nameChipShowing,
  setNameChipShowing,
  nameSearchTerm,
  setNameSearchTerm,
  entryIdSearch,
  setEntryIdSearch,
  invalidSearch,
  setInvalidSearch,
  reviewedSearch,
  setReviewedSearch,
  emailSearch,
  setEmailSearch,
  scoreSearch,
  setScoreSearch,
  onLoad,
  from
}) => {
  return (
    <>
      <Flex flexDirection={["column", "row"]}>
        {nameChipShowing ? (
          <Flex my={3} width={1 / 1}>
            <Text width="230px">Company Name:</Text>
            <Chip
              mt={-1}
              trailingIcon="cancel"
              onClick={() => {
                setNameChipShowing(false)
                setNameSearchTerm(null)
                onLoad({
                  from: from,
                  id: entryIdSearch,
                  companyName: null,
                  reviewed: reviewedSearch,
                  invalid: invalidSearch,
                  hasEmails: emailSearch,
                  score: scoreSearch
                })
              }}
            >
              Current Name Search: "{nameSearchTerm}"
            </Chip>
          </Flex>
        ) : (
          <>
            <Flex width={1 / 1}>
              <Text mt={3} width="230px">
                Company Name:
              </Text>
              <TextField
                width={1 / 1}
                label="Company Name Search"
                value={nameSearchTerm}
                onChange={e => setNameSearchTerm(e.target.value)}
              />
              <Button
                height="54px"
                bg="secondary.1"
                ml={2}
                onClick={() => {
                  onLoad({
                    from: 0,
                    id: entryIdSearch,
                    companyName: nameSearchTerm,
                    reviewed: reviewedSearch,
                    invalid: invalidSearch,
                    hasEmails: emailSearch,
                    score: scoreSearch
                  })
                  setNameChipShowing(true)
                }}
              >
                Search
              </Button>
            </Flex>
          </>
        )}
        {entryChipShowing ? (
          <Flex ml={[0, 2]} my={3} width={1 / 1}>
            <Text width="230px">Entry ID:</Text>
            <Chip
              mt={-1}
              trailingIcon="cancel"
              onClick={() => {
                setEntryChipShowing(false)
                setEntryIdSearch(null)
                onLoad({
                  from: from,
                  id: null,
                  companyName: nameSearchTerm,
                  reviewed: reviewedSearch,
                  invalid: invalidSearch,
                  hasEmails: emailSearch,
                  score: scoreSearch
                })
              }}
            >
              Current ID Search: "{entryIdSearch}"
            </Chip>
          </Flex>
        ) : (
          <Flex ml={[0, 2]} width={1 / 1}>
            <Text mt={3} width="230px">
              Entry ID:
            </Text>
            <TextField
              width={1 / 1}
              label="Entry ID Search"
              value={entryIdSearch}
              onChange={e => setEntryIdSearch(e.target.value)}
            />
            <Button
              height="54px"
              bg="secondary.1"
              ml={2}
              onClick={() => {
                onLoad({
                  from: 0,
                  id: entryIdSearch,
                  companyName: nameSearchTerm,
                  reviewed: reviewedSearch,
                  invalid: invalidSearch,
                  hasEmails: emailSearch,
                  score: scoreSearch
                })
                setEntryChipShowing(true)
              }}
            >
              Search
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex flexDirection={["column", "row"]} mb={3}>
        <Flex alignItems="center">
          <Text width="180px">Reviewed:</Text>
          <Select
            name="reviewed"
            type="text"
            width={1 / 1}
            onChange={e => {
              setReviewedSearch(e.target.value)
              onLoad({
                from: e.target.value === null ? from : 0,
                id: entryIdSearch,
                companyName: nameSearchTerm,
                reviewed: e.target.value,
                invalid: invalidSearch,
                hasEmails: emailSearch,
                score: scoreSearch
              })
            }}
            id="reviewed"
            label="Reviewed"
          >
            <option disabled value={null}>
              Choose option
            </option>
            <option value={null}>All</option>
            <option value={true}>Reviewed</option>
            <option value={false}>Not-Reviewed</option>
          </Select>
        </Flex>
        <Flex ml={[0, 2]} mt={[2, 0]} alignItems="center">
          <Text width="180px">Deleted:</Text>
          <Select
            name="deleted"
            type="text"
            width={1 / 1}
            onChange={e => {
              setInvalidSearch(e.target.value)
              onLoad({
                from: e.target.value === null ? from : 0,
                id: entryIdSearch,
                companyName: nameSearchTerm,
                reviewed: reviewedSearch,
                invalid: e.target.value,
                hasEmails: emailSearch,
                score: scoreSearch
              })
            }}
            id="deleted"
            label="Deleted"
          >
            <option disabled value={null}>
              Choose option
            </option>
            <option value={null}>All</option>
            <option value={false}>Not-deleted</option>
            <option value={true}>Deleted</option>
          </Select>
        </Flex>
      </Flex>
      <Flex flexDirection={["column", "row"]} mb={3}>
        <Flex alignItems="center">
          <Text width="180px">Emails:</Text>
          <Select
            name="hasEmails"
            type="text"
            width={1 / 1}
            onChange={e => {
              setEmailSearch(e.target.value)
              onLoad({
                from: e.target.value === null ? from : 0,
                id: entryIdSearch,
                companyName: nameSearchTerm,
                reviewed: reviewedSearch,
                invalid: invalidSearch,
                hasEmails: e.target.value,
                score: scoreSearch
              })
            }}
            id="hasEmails"
            label="emails"
          >
            <option disabled value={null}>
              Choose option
            </option>
            <option value={null}>All</option>
            <option value={true}>Has emails</option>
            <option value={false}>Does not have emails</option>
          </Select>
        </Flex>
        <Flex ml={[0, 2]} mt={[2, 0]} alignItems="center">
          <Text width="180px">Score:</Text>
          <Select
            name="score"
            type="text"
            width={1 / 1}
            onChange={e => {
              setScoreSearch(e.target.value)
              onLoad({
                from: e.target.value === null ? from : 0,
                id: entryIdSearch,
                companyName: nameSearchTerm,
                reviewed: reviewedSearch,
                invalid: invalidSearch,
                hasEmails: emailSearch,
                score: e.target.value
              })
            }}
            id="score"
            label="score"
          >
            <option disabled value={null}>
              Choose option
            </option>
            <option value={null}>All</option>
            <option value="above">Score 0.60 or above</option>
            <option value="under">Score under 0.60</option>
          </Select>
        </Flex>
      </Flex>
    </>
  )
}

export default Filters
