import React from "react"
import RankingAuditView from "../features/rankingAudit/views/RankingAudit"
import BaseLayout from "../layouts/BaseLayout"

const RankingAudit = () => (
  <BaseLayout>
    <RankingAuditView />
  </BaseLayout>
)

export default RankingAudit
