import { types } from "./organisationSearch.actions"
import { types as CUTypes } from "../currentUser/currentUser.actions"
import { USER_LOAD_LIMIT } from "../../services/ebUserManagement.service"

export const initialState = {
  isLoadingUsers: false,
  isLoadingMoreUsers: false,
  isMoreUsersToLoad: false,
  currentOrg: null,
  users: null,
  errorCode: null
}

export const ErrorTypes = {
  Generic: "GENERIC",
  ERR020: "InvalidEmail",
  ERR021: "InvalidUserId",
  ERR022: "InvalidPassword",
  ERR023: "InvalidSAMLProvider",
  ERR024: "EmailAlreadyExists",
  ERR025: "InvalidOrg"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USERS_BY_ORG_ID:
    case types.LOAD_USERS_BY_ORG_NAME:
      return {
        ...state,
        isLoadingUsers: true,
        users: null,
        errorCode: null,
        currentOrg: action.payload.org
      }

    case types.LOAD_MORE_USERS:
      return { ...state, isLoadingMoreUsers: true, errorCode: null }

    case types.LOAD_MORE_USERS_SUCCESS:
      return {
        ...state,
        isLoadingMoreUsers: false,
        users: Array.isArray(state.users)
          ? [...state.users, ...action.payload]
          : action.payload,
        isMoreUsersToLoad: action.payload.length === USER_LOAD_LIMIT
      }

    case types.LOAD_MORE_USERS_FAIL:
      return {
        ...state,
        isLoadingMoreUsers: false,
        errorCode: ErrorTypes[action.payload] || ErrorTypes.Generic
      }

    case types.CREATE_USER:
      return {
        ...state,
        isLoadingUsers: true,
        errorCode: null
      }

    case CUTypes.DELETE_USER:
      return {
        ...state,
        users: Array.isArray(state.users)
          ? state.users.filter(u => u.userId !== action.payload)
          : null
      }

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: Array.isArray(state.users)
          ? [...state.users, action.payload]
          : null
      }

    case types.CREATE_USER_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errorCode: ErrorTypes[action.payload] || ErrorTypes.Generic
      }

    case types.LOAD_USERS_BY_ORG_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: action.payload,
        isMoreUsersToLoad: action.payload.length === USER_LOAD_LIMIT
      }

    case types.LOAD_USERS_BY_ORG_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
        users: null,
        errorCode: ErrorTypes[action.payload] || ErrorTypes.Generic
      }

    case types.CLEAR_ERROR:
      return {
        ...state,
        errorCode: null
      }

    default:
      return state
  }
}
