export const types = {
  LOAD: "[CMS] Load CMS",
  LOAD_SUCCESS: "[CMS] Load Success",
  LOAD_FAIL: "[CMS] Load Fail",
  SAVE_BANNER: "[CMS] Save Banner",
  SAVE_BANNER_SUCCESS: "[CMS] Save Banner Success",
  SAVE_BANNER_FAIL: "[CMS] Save Banner Fail"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const saveBanner = payload => ({
  type: types.SAVE_BANNER,
  payload
})

export const saveBannerSuccess = payload => ({
  type: types.SAVE_BANNER_SUCCESS,
  payload
})

export const saveBannerFail = () => ({
  type: types.SAVE_BANNER_FAIL
})
