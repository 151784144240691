/* eslint-disable react/jsx-curly-newline */
import { Button, Flex, Text } from "@engaging-tech/components"
import React from "react"
import DatePicker from "react-multi-date-picker"
import styled from "styled-components"

export const Input = styled.input``
export const Label = styled.label`
  font-size: 14px;
`

const EditExpiration = ({ editData, setEditData }) => (
  <Flex alignItems="center">
    <Text fontSize="14px" mr={2}>
      Expires at:
    </Text>
    <DatePicker
      render={(value, openCalendar) => {
        return (
          <Button
            onClick={openCalendar}
            width="100px"
            variant="outline.primary.0"
          >
            {value}
          </Button>
        )
      }}
      minDate={new Date()}
      format="DD/MM/YYYY"
      value={new Date(editData.expiresAt)}
      onChange={value =>
        setEditData({ ...editData, expiresAt: value.toDate() })
      }
    />
  </Flex>
)

const EditInvoiceId = ({ editData, setEditData }) => (
  <>
    <Label htmlFor="invoice-id">Invoice Id</Label>
    <Input
      id="invoice-id"
      type="text"
      value={editData.invoiceId}
      onChange={e => setEditData({ ...editData, invoiceId: e.target.value })}
    />
    {!editData.invoiceId && (
      <Text color="red" fontSize={2}>
        Required
      </Text>
    )}
  </>
)

const TextData = ({ product, action, editData, setEditData }) => {
  const { name, invoiceId, transactionId, expiresAt } = product

  return (
    <Flex flexDirection="column" width="fit-content">
      <Text fontSize={5} color="primary.0" mb={1}>
        {name}
      </Text>

      {action === "update" ? (
        <EditExpiration editData={editData} setEditData={setEditData} />
      ) : (
        <Text>ExpiresAt: {new Date(expiresAt).toLocaleDateString()}</Text>
      )}

      {action === "update" && invoiceId ? (
        <EditInvoiceId editData={editData} setEditData={setEditData} />
      ) : (
        <Text color="primary.1">
          {invoiceId
            ? `Invoice No: ${invoiceId}`
            : `Transaction ID: ${transactionId}`}
        </Text>
      )}
    </Flex>
  )
}

export default TextData
