import { connect } from "react-redux"
import Component from "../../components/SurveySelect/ChooseOrg"
import { setField, resetOrg } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  organisationId: getField(state, "organisationId"),
  organisationName: getField(state, "organisationName")
})

const mapDispatch = dispatch => ({
  handleSubmit: ({ id, name }) => {
    dispatch(setField("organisationId", id))
    dispatch(setField("organisationName", name))
  },
  resetOrg: () => dispatch(resetOrg())
})

export default connect(mapState, mapDispatch)(Component)
