import React, { useCallback, useState } from "react"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import Select from "@engaging-tech/components/dist/lib/Select/Select"
import { format } from "date-fns"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import FetchSurveysForOrg from "../../../portable/FetchSurveysForOrg"
import { SurveyTypes } from "../../lib"

const formatDate = dateString => format(new Date(dateString), "dd MMM yyyy")

const Ternary = ({
  surveyType,
  organisationId,
  setSurvey,
  surveyId,
  surveyName,
  clearSurvey
}) => {
  const [surveyIdValue, setSurveyIdValue] = useState()

  const handleUseSurvey = useCallback(
    surveys => {
      if (!surveyIdValue) {
        return setSurvey({
          surveyId: surveys[0].id,
          name: `${surveys[0].name}: ${formatDate(
            surveys[0].startDate
          )} - ${formatDate(surveys[0].endDate)}`
        })
      }
      const survey = surveys.find(s => s.id === surveyIdValue)
      return setSurvey({
        surveyId: survey.id,
        name: `${survey.name}: ${formatDate(survey.startDate)} - ${formatDate(
          survey.endDate
        )}`
      })
    },
    [setSurvey, surveyIdValue]
  )

  if (surveyType !== SurveyTypes.organisation || !organisationId) return null
  return (
    <Box>
      <Text>Choose Survey</Text>
      <Box>
        <FetchSurveysForOrg organisationId={organisationId}>
          {({ surveys, isLoading }) => {
            if (isLoading) return <Spinner color="primary.0" />
            if (!surveys?.length)
              return (
                <Text fontSize={3}>
                  This organisation has no surveys. Please try a different
                  Organisation.
                </Text>
              )
            if (surveyId)
              return (
                <>
                  <Text fontSize={3}>Using: {surveyName}</Text>
                  <Flex justifyContent="center" mt={3}>
                    <Button
                      variant="primary.0"
                      onClick={() => clearSurvey(surveys)}
                    >
                      Change
                    </Button>
                  </Flex>
                </>
              )
            return (
              <>
                <Select
                  value={surveyIdValue}
                  onChange={e => setSurveyIdValue(e.target.value)}
                >
                  {surveys.map(survey => (
                    <option value={survey.id}>
                      {survey.name}: {formatDate(survey.startDate)} -{" "}
                      {formatDate(survey.endDate)}
                    </option>
                  ))}
                </Select>
                <Flex justifyContent="center" mt={3}>
                  <Button
                    variant="primary.0"
                    onClick={() => handleUseSurvey(surveys)}
                  >
                    Use This Survey
                  </Button>
                </Flex>
              </>
            )
          }}
        </FetchSurveysForOrg>
      </Box>
    </Box>
  )
}

export default Ternary
