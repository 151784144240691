import { connect } from "react-redux"
import { reportsActions, reportsSelectors } from "../../reports"
import ReportsNavComponent from "../components/ReportsNav"

const mapState = state => ({
  selected: reportsSelectors.getSelectedQueryType(state)
})

const mapDispatch = dispatch => ({
  onSelect: queryType => dispatch(reportsActions.setQuery(queryType)),
  loadQuery: queryType => dispatch(reportsActions.loadQuery(queryType))
})

const ReportsNav = connect(mapState, mapDispatch)(ReportsNavComponent)

export default ReportsNav
