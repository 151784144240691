export const types = {
  SET_FIELD: "[Data Export] Set Field",
  RESET_ORG: "[Data Export] Reset Org",
  SET_COLUMN_VALUE: "[Data Export] Set Column Value",
  GENERATE_EXPORT: "[Data Export] Generate Export",
  RETRY_GENERATE_EXPORT: "[Data Export] Retry Generate Export",
  GENERATE_EXPORT_SUCCESS: "[Data Export] Generate Export Success",
  GENERATE_EXPORT_FAIL: "[Data Export] Generate Export Fail",
  CHANGE_DATES: "[Data Export] Change Dates",
  SET_ERROR: "[Data Export] Set Error"
}

export const setError = error => ({
  type: types.SET_ERROR,
  payload: error
})

export const setField = (fieldName, value) => ({
  type: types.SET_FIELD,
  payload: { fieldName, value }
})

export const resetOrg = () => ({
  type: types.RESET_ORG
})

export const setAdditionalColumnValue = (name, value) => ({
  type: types.SET_COLUMN_VALUE,
  payload: { name, value }
})

export const generateExport = () => ({
  type: types.GENERATE_EXPORT
})

export const retryGenerateExport = count => ({
  type: types.RETRY_GENERATE_EXPORT,
  payload: count
})

export const generateExportSuccess = url => ({
  type: types.GENERATE_EXPORT_SUCCESS,
  payload: url
})

export const generateExportFail = err => ({
  type: types.GENERATE_EXPORT_FAIL,
  payload: err
})

export const changeDates = (dateName, date, isError) => ({
  type: types.CHANGE_DATES,
  payload: { dateName, date, isError }
})
