import React from "react"
import Card from "@engaging-tech/components/dist/lib/Card/Card"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"

import Nav from "../features/navigation/components/Nav"

const BaseLayout = ({ bg = "transparent", children, ...props }) => (
  <>
    <Nav />
    <Flex mx={[4, 4, 0]} flexDirection="column">
      <Flex justifyContent="center" bg={bg} {...props} width={1 / 1} pt={4}>
        <Card as="main" maxWidth={1024} elevation={0}>
          {children}
        </Card>
      </Flex>
    </Flex>
  </>
)

export default BaseLayout
