import { connect } from "react-redux"
import AddProduct from "../../../components/management_actions/premium_products/AddProduct"

import { getAvailableProducts } from "../../../store/orgManagement.selectors"

const mapState = state => ({
  isLoading: getAvailableProducts(state).isLoading,
  error: getAvailableProducts(state).error
})

const AddProductContainer = connect(mapState, null)(AddProduct)

export default AddProductContainer
