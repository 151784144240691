/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const GENERATE_EXPORT = gql`
  query ($surveyId: String!, $options: ExportOptions!) {
    getDataExport(surveyId: $surveyId, options: $options) {
      status
      url
      createdAt
    }
  }
`
