import { types } from "./searchorg.actions"

export const initialState = {
  isLoadingOrgs: false,
  orgs: null,
  orgSearchErrorCode: null
}

export const ErrorTypes = {
  Generic: "GENERIC",
  ERR020: "InvalidEmail",
  ERR021: "InvalidUserId",
  ERR022: "InvalidPassword",
  ERR023: "InvalidSAMLProvider",
  ERR024: "EmailAlreadyExists",
  ERR025: "InvalidOrg"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_ORGS_BY_NAME:
      return {
        ...state,
        orgs: null,
        isLoadingOrgs: true,
        orgSearchErrorCode: null
      }

    case types.SEARCH_ORGS_BY_NAME_SUCCESS:
      return {
        ...state,
        isLoadingOrgs: false,
        orgs: action.payload || []
      }

    case types.SEARCH_ORGS_BY_NAME_FAIL:
      return {
        ...state,
        isLoadingOrgs: false,
        orgSearchErrorCode: ErrorTypes[action.payload] || ErrorTypes.Generic
      }

    default:
      return state
  }
}
