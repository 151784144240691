import { gql } from "../../../lib/Apollo"

export const UPDATE_CREDITS = gql`
  mutation (
    $newValue: Int!
    $id: ID!
    $oldValue: Int!
    $creditActions: CreditActions!
    $userId: String!
  ) {
    updateCredits(
      newValue: $newValue
      id: $id
      oldValue: $oldValue
      creditActions: $creditActions
      userId: $userId
    ) {
      id
      credits
    }
  }
`
export const GET_LOGS_BY_ORGANISATION = gql`
  query ($organisationId: ID!) {
    getLogsByOrganisation(organisationId: $organisationId) {
      newValue
      oldValue
      createdAt
      userId
    }
  }
`

export const GET_ACTIVE_PRODUCTS = gql`
  query ($organisationId: ID!) {
    getActiveProducts(organisationId: $organisationId) {
      id
      expiresAt
      name
      invoiceId
      transactionId
    }
  }
`

export const GET_AVAILABLE_PRODUCTS = gql`
  query ($organisationId: ID!) {
    getAvailableProducts(organisationId: $organisationId) {
      id
      name
    }
  }
`

export const POST_ACTIVE_PRODUCT = gql`
  mutation (
    $invoiceId: String!
    $expiresAt: Date!
    $organisationId: ID!
    $productId: ID!
  ) {
    postActiveProduct(
      invoiceId: $invoiceId
      expiresAt: $expiresAt
      organisationId: $organisationId
      productId: $productId
    ) {
      id
      expiresAt
      name
      invoiceId
      transactionId
    }
  }
`
export const UPDATE_ACTIVE_PRODUCT = gql`
  mutation (
    $id: ID!
    $invoiceId: String
    $expiresAt: Date
    $organisationId: ID!
  ) {
    updateActiveProduct(
      id: $id
      invoiceId: $invoiceId
      expiresAt: $expiresAt
      organisationId: $organisationId
    ) {
      id
      expiresAt
      name
      invoiceId
      transactionId
    }
  }
`

export const DELETE_ACTIVE_PRODUCT = gql`
  mutation ($id: ID!, $organisationId: ID!) {
    deleteActiveProduct(id: $id, organisationId: $organisationId) {
      id
      expiresAt
      name
      invoiceId
      transactionId
    }
  }
`
