export const types = {
  LOAD_STATUS: "[Status] Load Status",
  LOAD_STATUS_SUCCESS: "[Status] Load Status Success",
  LOAD_STATUS_FAIL: "[Status] Load Status Fail"
}

export const loadStatus = id => ({
  type: types.LOAD_STATUS,
  payload: id
})

export const loadStatusSuccess = payload => {
  return {
    type: types.LOAD_STATUS_SUCCESS,
    payload
  }
}

export const loadStatusFail = () => ({
  type: types.LOAD_STATUS_FAIL
})
