/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { connect } from "react-redux"
import { getAvailableProductsRequest } from "../store/orgManagement.actions"

const GetAvailableProductsComponent = ({ loadAvailableProducts }) => {
  useEffect(() => {
    loadAvailableProducts()
  }, [])

  return null
}

const mapDispatch = dispatch => ({
  loadAvailableProducts: () => dispatch(getAvailableProductsRequest())
})

const GetAvailableProducts = connect(
  null,
  mapDispatch
)(GetAvailableProductsComponent)
export default GetAvailableProducts
