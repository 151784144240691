import CMS from "./CMS"
import DataExport from "./DataExport"
import Home from "./Home"
import OrgManagement from "./OrgManagement"
import RankingAudit from "./RankingAudit"
import Reports from "./Reports"
import Status from "./Status"
import UserManagement from "./UserManagement"

export default {
  Reports,
  Status,
  RankingAudit,
  DataExport,
  UserManagement,
  Home,
  CMS,
  OrgManagement
}
