import { connect } from "react-redux"
import { rankingAuditActions, rankingAuditSelectors } from ".."
import { MiscSelectors } from "../../misc"
import EditModalComponent from "../components/EditModal"

const mapState = state => ({
  isSubmitting: rankingAuditSelectors.getIsSubmitting(state),
  hasError: rankingAuditSelectors.getHasError(state),
  updateSuccess: rankingAuditSelectors.getUpdateSuccess(state),
  mergeRequest: rankingAuditSelectors.getMergeRequest(state),
  newCompanyName: rankingAuditSelectors.getNewCompanyName(state),
  noChange: rankingAuditSelectors.getNoChange(state),
  isMerging: rankingAuditSelectors.getIsMerging(state),
  industries: MiscSelectors.getAllIndustries(state),
  countries: MiscSelectors.getAllCountries(state)
})

const mapDispatch = dispatch => ({
  onSubmit: data => dispatch(rankingAuditActions.updateEntry(data)),
  resetUpdateSuccess: () => dispatch(rankingAuditActions.resetUpdateSuccess()),
  resetHasError: () => dispatch(rankingAuditActions.resetHasError()),
  updateNoChange: bool => dispatch(rankingAuditActions.updateNoChange(bool)),
  setMergeRequest: () => dispatch(rankingAuditActions.setMergeRequest()),
  callToMerge: data => dispatch(rankingAuditActions.mergeEntries(data))
})

const EditModal = connect(mapState, mapDispatch)(EditModalComponent)

export default EditModal
