import Apollo from "../../../lib/Apollo"
import { getConfig } from "@engaging-tech/ssr-config"
import { LOAD, SAVE } from "./cms.queries"

const GRAPHQL_API_URL = () => getConfig().api
const apollo = new Apollo()

export const loadCMS = payload =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD,
    variables: { type: payload }
  })

export const saveCMS = payload =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: SAVE,
    variables: payload
  })
