import { gql } from "@engaging-tech/graphql"

export const LOAD = gql`
  query ($type: CMSType!) {
    fetchCMS(type: $type) {
      content
      type
    }
  }
`

export const SAVE = gql`
  mutation ($type: CMSType!, $content: String!) {
    createCMS(type: $type, content: $content) {
      type
      content
    }
  }
`
