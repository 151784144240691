/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import Apollo from "../../../../lib/Apollo"
import { SEARCH_ORGS_BY_NAME } from "./searchorg.queries"

const GRAPHQL_API_URL = () => getConfig().api

const apollo = new Apollo()

export const searchOrgsByName = ({ name }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: SEARCH_ORGS_BY_NAME,
    variables: { name }
  })
