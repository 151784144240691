import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { loadCMS, saveCMS } from "../services/cms.service"
import * as Actions from "./cms.actions"

export function* loadSaga(action) {
  try {
    const res = yield call(loadCMS, action.payload)
    yield put(Actions.loadSuccess(res.data.fetchCMS))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* saveBannerSaga(action) {
  try {
    const res = yield call(saveCMS, action.payload)
    yield put(Actions.saveBannerSuccess(res.data.createCMS))
  } catch {
    yield put(Actions.saveBannerFail())
  }
}

export function* saga() {
  yield takeEvery(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.SAVE_BANNER, saveBannerSaga)
}
