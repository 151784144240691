const TableConfig = {
  stylesPerColumn: [
    {
      fontSize: 16,
      fontWeight: 600,
      color: "primary.0"
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    }
  ],
  columnWidths: [1 / 2, 1 / 2, 1, 1],
  main: {
    borderRadius: "10px"
  },
  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: "dark.3",
    py: 2
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 2,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const Headings = [
  { id: "oldValue", title: "Previous" },
  { id: "newValue", title: "New" },
  { id: "userId", title: "User ID" },
  { id: "createdAt", title: "Date" }
]

export { TableConfig, Headings }
