import { Button, Flex } from "@engaging-tech/components"
import React from "react"

const ConfirmButtons = ({
  setSelectedProduct,
  clickAction,
  setAction,
  disableButton
}) => (
  <Flex flexDirection="column" width="120px" justifyContent="center">
    <Button
      mb={2}
      bg="#ff9900"
      width="120px"
      onClick={() => {
        setAction("")
        setSelectedProduct({})
      }}
    >
      Back
    </Button>
    <Button
      bg="red"
      width="120px"
      onClick={clickAction}
      disabled={disableButton}
    >
      Confirm
    </Button>
  </Flex>
)

export default ConfirmButtons
