import { call, put, takeLatest, delay } from "redux-saga/effects"
import { searchOrgsByName } from "../services/searchorg.service"
import * as Actions from "./searchorg.actions"

const getGraphQLErrCode = err => {
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

export function* searchOrgsByNameSaga(action) {
  try {
    yield delay(1000);
    const res = yield call(searchOrgsByName, action.payload)
    yield put(Actions.searchOrgsByNameSuccess(res?.data?.searchOrgsByName))
  } catch (error) {
    yield put(Actions.searchOrgsByNameFail(getGraphQLErrCode(error)))
  }
}

export function* saga() {
  yield takeLatest([Actions.types.SEARCH_ORGS_BY_NAME], searchOrgsByNameSaga)
}
