import { connect } from "react-redux"
import Component from "../components/FetchSurveysForOrg"
import { fetchSurveys } from "../store/fetchsurveys.actions"
import { getIsLoading, getSurveys } from "../store/fetchsurveys.selectors"

const mapState = state => ({
  isLoading: getIsLoading(state),
  surveys: getSurveys(state) || []
})

const mapDispatch = dispatch => ({
  fetchSurveys: organisationId => dispatch(fetchSurveys({ organisationId }))
})

const FetchSurveysForOrg = connect(mapState, mapDispatch)(Component)

export default FetchSurveysForOrg
