import { types } from "./cms.actions"

export const intialState = {
  isLoading: false,
  hasError: false,
  isSaving: false,
  banner: {
    WORKL_BANNER: "",
    WFB_BANNER: ""
  }
}

export const reducer = (state = intialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return { ...state, isLoading: true }

    case types.SAVE_BANNER:
      return {
        ...state,
        isSaving: action.payload.type
      }

    case types.LOAD_SUCCESS:
    case types.SAVE_BANNER_SUCCESS:
      return {
        ...state,
        banner: {
          ...state.banner,
          [action.payload.type]: action.payload.content
        },
        hasError: false,
        isLoading: false,
        isSaving: false
      }

    case types.LOAD_FAIL:
    case types.SAVE_BANNER_FAIL:
      return { ...state, isLoading: false, isSaving: false, hasError: true }

    default:
      return state
  }
}
