import { createSelector } from "reselect"

const getMiscState = state => state.misc

const splitFromUk = countries => {
  const uk = countries.find(
    country => country.id === "5abb593b5779598af1079569"
  )
  const rest = countries.filter(country => country.id !== uk.id)
  return [uk, rest]
}

const sortAlphabetically = countries =>
  countries.sort((a, b) => a.name.localeCompare(b.name))

const orderCountries = countries => {
  if (countries.length === 0) return countries

  const [uk, rest] = splitFromUk(countries)
  const sortedRest = sortAlphabetically(rest)
  return [uk].concat(sortedRest)
}

export const getAllCountries = createSelector(getMiscState, misc =>
  orderCountries(misc.allCountries)
)

const pipe = (state, prop) => prop

export const getHasError = createSelector(
  [getMiscState, pipe],
  (misc, type) => misc[`hasError${type}`]
)

export const getHasLoaded = createSelector(
  [getMiscState, pipe],
  (misc, type) => misc[`hasLoaded${type}`]
)

export const getIsLoading = createSelector(
  [getMiscState, pipe],
  (misc, type) => misc[`loading${type}`]
)

export const getSurveyJobRoles = createSelector(getMiscState, misc =>
  misc.surveyJobRoles.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
)

export const getAllJobRoles = createSelector(
  getMiscState,
  misc => misc.allJobRoles
)

export const getAllIndustries = createSelector(getMiscState, misc =>
  misc.allIndustries.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
)

export const getAllHobbies = createSelector(
  getMiscState,
  misc => misc.allHobbies
)

export const getAllLanguages = createSelector(
  getMiscState,
  misc => misc.allLanguages
)

export const getAllSkills = createSelector(getMiscState, misc => misc.allSkills)

export const getAllRegions = createSelector(
  getMiscState,
  misc => misc.allRegions
)
