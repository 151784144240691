import { connect } from "react-redux"
import Component from "../../components/OptionsSelect/AdditionalColumns"
import { setAdditionalColumnValue } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  additionalColumns: getField(state, "additionalColumns")
})

const mapDispatch = dispatch => ({
  setColumnValue: (name, val) => dispatch(setAdditionalColumnValue(name, val))
})

export default connect(mapState, mapDispatch)(Component)
