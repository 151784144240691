import React from "react"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import SearchByOrgInput from "../../../portable/SearchOrgInput"

const ChooseOrg = ({
  organisationId,
  handleSubmit,
  organisationName,
  resetOrg
}) => {
  return (
    <Box mb={[3, 3, 0]}>
      <Text>Choose Organisation</Text>
      {!organisationId && (
        <SearchByOrgInput onSubmit={handleSubmit} buttonText="Select" />
      )}
      {organisationId && (
        <Box width={1}>
          <Text fontWeight="800" fontSize={2} mb={2}>
            Organisation: {organisationName}
          </Text>
          <Flex width={1} justifyContent="center" mt={3}>
            <Button onClick={resetOrg} variant="primary.0" elevation={0}>
              Change
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default ChooseOrg
