import { types } from "./currentUser.actions"

export const initialState = {
  isLoading: false,
  user: null,
  errorCode: null
}

export const ErrorTypes = {
  Generic: "GENERIC",
  ERR020: "InvalidEmail",
  ERR021: "InvalidUserId",
  ERR022: "InvalidPassword",
  ERR023: "InvalidSAMLProvider",
  ERR024: "EmailAlreadyExists"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER:
      return {
        ...state,
        isLoading: true,
        user: null,
        errorCode: null
      }

    case types.DELETE_USER:
    case types.UPDATE_USER:
      return { ...state, isLoading: true, errorCode: null }

    case types.LOAD_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      }

    case types.DELETE_USER_SUCCESS:
      return { ...state, isLoading: false, user: null }

    case types.DELETE_USER_FAIL:
    case types.LOAD_USER_FAIL:
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        errorCode: ErrorTypes[action.payload] || ErrorTypes.Generic
      }

    case types.CLEAR_ERROR:
      return {
        ...state,
        errorCode: null
      }

    default:
      return state
  }
}
