import { connect } from "react-redux"
import AddUserModalComponent from "../components/AddUserForm"
import { getCurrentOrg } from "../store/organisationSearch/organisationSearch.selectors"
import { createUser } from "../store/organisationSearch/organisationSearch.actions"
import { getOrgs } from "../../portable/SearchOrgInput/store/searchorg.selectors"

const mapState = state => ({
  orgs: getOrgs(state),
  currentOrg: getCurrentOrg(state)
})

const mapDispatch = (dispatch, { toggle }) => ({
  onSubmit: params => {
    dispatch(createUser(params))
    toggle()
  }
})

const AddUserModal = connect(mapState, mapDispatch)(AddUserModalComponent)

export default AddUserModal
