/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { connect } from "react-redux"
import { getActiveProductsRequest } from "../store/orgManagement.actions"

const GetActiveProductsComponent = ({ loadActiveProducts }) => {
  useEffect(() => {
    loadActiveProducts()
  }, [])

  return null
}

const mapDispatch = dispatch => ({
  loadActiveProducts: () => dispatch(getActiveProductsRequest())
})

const GetActiveProducts = connect(null, mapDispatch)(GetActiveProductsComponent)
export default GetActiveProducts
