import React from "react"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Button from "@engaging-tech/components/dist/lib/Button/Button"

import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"

const LoadMoreUsers = ({
  loadMoreOrgUsers,
  users,
  isMoreToLoad,
  isLoading
}) => {
  if (!users?.length) return null
  if (!isMoreToLoad) return null
  return (
    <Flex width={1} justifyContent="center">
      {isLoading ? (
        <Spinner color="primary.0" />
      ) : (
        <Button
          variant="primary.0"
          onClick={() => loadMoreOrgUsers(users.length)}
        >
          Load More
        </Button>
      )}
    </Flex>
  )
}

export default LoadMoreUsers
