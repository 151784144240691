import React from "react"
import { Spinner, Flex } from "@engaging-tech/components"
import {
  format,
  subHours,
  subMonths,
  startOfMonth,
  endOfMonth,
  getMonth,
  subDays
} from "date-fns"

const start = "2000-01-01"
const now = new Date()
const oneDayAgo = subHours(now, 24)

const oneMonthAgo = subMonths(now, 1)
const startOfLastMonth = startOfMonth(oneMonthAgo)
const endOfLastMonth = endOfMonth(oneMonthAgo)
const lastMonthText = format(oneMonthAgo, "MMMM yyyy")
const oneWeekAgo = subDays(now, 7)

const determineNumber = number => {
  if (number === 0) return 0
  return (
    number || (
      <Flex justifyContent="center">
        <Spinner color="primary.0" width={25} height={25} alignItems="center" />
      </Flex>
    )
  )
}

const TableConfig = {
  stylesPerColumn: [
    {
      fontSize: 16,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    },
    {
      color: "dark.2"
    },
    {
      justifyContent: "center"
    }
  ],
  columnWidths: [30 / 100, 15 / 100, 35 / 100, 20 / 100],
  main: {
    borderRadius: "10px"
  },
  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: "dark.3",
    py: 2
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 2,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const Headings = [
  {
    id: "Name",
    title: "Type of Query"
  },
  {
    id: "Number",
    title: "Number"
  },
  {
    id: "Description",
    title: "Description"
  },
  {
    id: "Download",
    title: "Copy to Clipboard"
  }
]

const queryTypes = {
  last24: { from: oneDayAgo, to: now, type: "last24", text: "Last 24 hours" },
  lastWeek: {
    from: oneWeekAgo,
    to: now,
    type: "lastWeek",
    text: "Last 7 days"
  },
  lastMonth: {
    from: startOfLastMonth,
    to: endOfLastMonth,
    type: "lastMonth",
    text: lastMonthText
  },
  lifetime: { from: start, to: now, type: "lifetime", text: "Lifetime stats" },
  bespoke: { from: "", to: "", type: "bespoke", text: "Bespoke dates" }
}

const oldIndividualSurveysArchived = 5392
const oldOrganisationSurveysArchived = 2918

export {
  start,
  now,
  oneDayAgo,
  oneWeekAgo,
  startOfLastMonth,
  endOfLastMonth,
  lastMonthText,
  getMonth,
  determineNumber,
  TableConfig,
  Headings,
  queryTypes,
  oldIndividualSurveysArchived,
  oldOrganisationSurveysArchived
}
