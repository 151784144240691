// Redux
import { connect } from "react-redux"
// Store
import {
  getCurrentOrg,
  getIsLoading,
  getLogs
} from "../../../store/orgManagement.selectors"
import {
  getLogsByOrganisationRequest,
  setCloseSlider
} from "../../../store/orgManagement.actions"
// Components
import CreditsLog from "../../../components/management_actions/manage_credits/CreditsLog"

const mapState = state => ({
  isLoading: getIsLoading(state),
  logs: getLogs(state),
  currentOrg: getCurrentOrg(state)
})

const mapDispatch = dispatch => ({
  setCloseSlider: () => dispatch(setCloseSlider()),
  getLogsByOrganisation: params =>
    dispatch(getLogsByOrganisationRequest(params))
})

const CreditsLogCtr = connect(mapState, mapDispatch)(CreditsLog)

export default CreditsLogCtr
