import { createSelector } from "reselect"

const getCMSState = state => state?.cms

export const getIsLoading = createSelector(
  getCMSState,
  state => state?.isLoading
)

export const getIsSaving = createSelector(getCMSState, state => state?.isSaving)

export const getHasError = createSelector(getCMSState, state => state?.hasError)

export const getBanner = createSelector(getCMSState, state => state?.banner)

export const getWorkLBanner = createSelector(getBanner, banner => banner?.workl)

export const getWFBBanner = createSelector(getBanner, banner => banner?.wfb)
