import React, { useCallback } from "react"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import ToggleLogic from "@engaging-tech/components/dist/lib/ToggleLogic/ToggleLogic"
import Table from "@engaging-tech/components/dist/lib/Table/Table"
import { TableConfig, Headings } from "../lib/TableData"
import AddUserForm from "../containers/AddUserForm"
import SearchByOrgInput from "../../portable/SearchOrgInput"
import ErrorSnackbar from "../containers/ErrorSnackbar"
import LoadMoreUsersButton from "../containers/LoadMoreUsersButton"

const None = () => (
  <Text color="#ff0000" fontSize={3}>
    none
  </Text>
)

const prepareData = ({ users, companyName, onViewClick }) => {
  if (!users) return []
  return users.map(user => {
    return {
      id: user.userId,
      firstName: user.firstName || <None />,
      lastName: user.lastName || <None />,
      company: companyName || <None />,
      viewBtn: (
        <Button
          bg="tertiary.0"
          onClick={() => onViewClick(user.userId)}
          width={9 / 10}
        >
          View Details
        </Button>
      )
    }
  })
}

const getCurrentOrg = (orgs, { orgId, name }) => {
  if (orgId) return orgs.find(org => org.id === orgId)
  return orgs?.find(org => org.name === name)
}

const UsersByCompany = ({
  isLoading,
  users,
  companyName,
  handleSetToUserView,
  loadUser,
  onSearchOrgsSubmit,
  orgs = []
}) => {
  const handleSearchOrgsSubmit = useCallback(
    ({ id }) => {
      const org = getCurrentOrg(orgs, { orgId: id })
      onSearchOrgsSubmit(org)
    },
    [onSearchOrgsSubmit, orgs]
  )

  return (
    <>
      <SearchByOrgInput onSubmit={handleSearchOrgsSubmit} />
      <ToggleLogic>
        {({ on, toggle }) => (
          <Flex justifyContent="flex-end">
            <Button variant="secondary.0" onClick={toggle}>
              Create User
            </Button>
            {on && <AddUserForm toggle={toggle} />}
          </Flex>
        )}
      </ToggleLogic>
      {!isLoading ? (
        <>
          <Table
            width={1 / 1}
            headings={Headings}
            rows={prepareData({
              users,
              companyName,
              onViewClick: userId => {
                loadUser(userId)
                handleSetToUserView()
              }
            })}
            config={TableConfig}
            mt={5}
            mb={2}
          />
          <LoadMoreUsersButton />
        </>
      ) : (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      )}
      <ErrorSnackbar />
    </>
  )
}

export default UsersByCompany
