import { Box } from "@engaging-tech/components"
import { Redirect } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"
import { authSelectors } from "../../auth"
import SearchByOrgInput from "../../portable/SearchOrgInput/containers/SearchByOrgInput"
import OrganisationTable from "../containers/OrganisationTable"

const OrgManagement = () => {
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.EBUserManagement),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />
  return (
    <Box>
      <SearchByOrgInput noSearchBtn />
      <OrganisationTable />
    </Box>
  )
}

export default OrgManagement
