export const types = {
  LOAD: "[Ranking Audit] Load Audit Entries",
  LOAD_MORE: "[Ranking Audit] Load More Audit Entries",
  LOAD_MORE_SUCCESS: "[Ranking Audit] Load More Entries Success",
  LOAD_SUCCESS: "[Ranking Audit] Load Entries Success",
  LOAD_FAIL: "[Ranking Audit] Load Entries Fail",
  LOAD_MORE_FAIL: "[Ranking Audit] Load More Entries Fail",
  UPDATE_ENTRY: "[Ranking Audit] Update Entry",
  UPDATE_ENTRY_SUCCESS: "[Ranking Audit] Update Entry Success",
  UPDATE_ENTRY_FAIL: "[Ranking Audit] Update Entry Fail",
  RESET_UPDATE_SUCCESS: "[Ranking Audit] Reset Update Entry Success Flag",
  REMOVE_ENTRY: "[Ranking Audit] Remove Entry",
  REMOVE_ENTRY_SUCCESS: "[Ranking Audit] Remove Entry Success",
  REMOVE_ENTRY_FAIL: "[Ranking Audit] Remove Entry Fail",
  RESET_HAS_ERROR: "[Ranking Audit] Reset Has Error Flag",
  UPDATE_REVIEWED_ONLY: "[Ranking Audit] Update Reviewed Flag Only",
  UPDATE_REVIEWED_ONLY_SUCCESS:
    "[Ranking Audit] Update Reviewed Flag Only Success",
  UPDATE_REVIEWED_ONLY_FAIL: "[Ranking Audit] Update Reviewed Flag Only Fail",
  SET_MERGE_REQUEST: "[Ranking Audit] Set Merge Request",
  SET_HALF_SUCCESS: "[Ranking Audit] Set Half Success Update Pre-Merge",
  UPDATE_SUCCESS_FLAG: "[Ranking Audit] Update Success Flag To True",
  UPDATE_NO_CHANGE: "[Ranking Audit] Updated No Change Flag",
  MERGE_ENTRIES: "[Ranking Audit] Merge Entries",
  RESET_DELETE_ERROR: "[Ranking Audit] Reset Delete Error Flag",
  RESET_DELETE_SUCCESS: "[Ranking Audit] Reset Delete Success Flag"
}

export const load = (
  from,
  id,
  companyName,
  reviewed,
  invalid,
  hasEmails,
  score
) => ({
  type: types.LOAD,
  payload: { from, id, companyName, reviewed, invalid, hasEmails, score }
})

export const loadMore = (
  from,
  id,
  companyName,
  reviewed,
  invalid,
  hasEmails,
  score
) => ({
  type: types.LOAD_MORE,
  payload: { from, id, companyName, reviewed, invalid, hasEmails, score }
})

export const loadMoreSuccess = entries => ({
  type: types.LOAD_MORE_SUCCESS,
  payload: entries
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const loadMoreFail = () => ({
  type: types.LOAD_MORE_FAIL
})

export const updateEntry = payload => ({
  type: types.UPDATE_ENTRY,
  payload
})

export const updateEntrySuccess = () => ({
  type: types.UPDATE_ENTRY_SUCCESS
})

export const updateEntryFail = () => ({
  type: types.UPDATE_ENTRY_FAIL
})

export const resetUpdateSuccess = () => ({
  type: types.RESET_UPDATE_SUCCESS
})

export const resetHasError = () => ({
  type: types.RESET_HAS_ERROR
})

export const removeEntry = payload => ({
  type: types.REMOVE_ENTRY,
  payload
})

export const removeEntrySuccess = () => ({
  type: types.REMOVE_ENTRY_SUCCESS
})

export const removeEntryFail = () => ({
  type: types.REMOVE_ENTRY_FAIL
})

export const setMergeRequest = () => ({
  type: types.SET_MERGE_REQUEST
})

export const setHalfSuccess = payload => ({
  type: types.SET_HALF_SUCCESS,
  payload
})

export const updateSuccessFlag = () => ({
  type: types.UPDATE_SUCCESS_FLAG
})

export const updateNoChange = payload => ({
  type: types.UPDATE_NO_CHANGE,
  payload
})

export const mergeEntries = payload => ({
  type: types.MERGE_ENTRIES,
  payload
})

export const resetDeleteSuccess = () => ({
  type: types.RESET_DELETE_SUCCESS
})

export const resetDeleteError = () => ({
  type: types.RESET_DELETE_ERROR
})
