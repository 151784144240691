import { connect } from "react-redux"
import Component from "../../components/SurveySelect/Ternary"
import { setField } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  surveyType: getField(state, "surveyType"),
  organisationId: getField(state, "organisationId"),
  surveyId: getField(state, "surveyId"),
  surveyName: getField(state, "surveyName")
})

const mapDispatch = dispatch => ({
  setSurvey: ({ name, surveyId }) => {
    dispatch(setField("surveyId", surveyId))
    dispatch(setField("surveyName", name))
  },
  clearSurvey: () => {
    dispatch(setField("surveyId", null))
    dispatch(setField("surveyName", null))
  }
})

export default connect(mapState, mapDispatch)(Component)
