export const types = {
  SEARCH_ORGS_BY_NAME: "[Search Orgs Input] Search Organisations By Name",
  SEARCH_ORGS_BY_NAME_SUCCESS:
    "[Search Orgs Input] Search Organisations By Name Success",
  SEARCH_ORGS_BY_NAME_FAIL:
    "[Search Orgs Input] Search Organisations By Name Fail"
}

export const searchOrgsByName = ({ name }) => ({
  type: types.SEARCH_ORGS_BY_NAME,
  payload: { name }
})

export const searchOrgsByNameSuccess = organisations => ({
  type: types.SEARCH_ORGS_BY_NAME_SUCCESS,
  payload: organisations
})

export const searchOrgsByNameFail = err => ({
  type: types.SEARCH_ORGS_BY_NAME_FAIL,
  payload: err
})
