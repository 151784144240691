import React from "react"
import { useSelector } from "react-redux"
import { authSelectors } from "../features/auth"
import CMSView from "../features/cms/views/CMS"
import UserManagementView from "../features/ebUserManagement/views/UserManagement"
import RankingAuditView from "../features/rankingAudit/views/RankingAudit"
import ReportsView from "../features/reports/views/Reports"
import StatusView from "../features/status/views/Status"
import GenerateView from "../features/surveyDataExport/views/Generate"
import BaseLayout from "../layouts/BaseLayout"
import { FEATURE_GROUPS } from "../lib/Features"

const FeatureGroupToViewMap = {
  [FEATURE_GROUPS.EBUserManagement]: UserManagementView,
  [FEATURE_GROUPS.Reports]: ReportsView,
  [FEATURE_GROUPS.Status]: StatusView,
  [FEATURE_GROUPS.DataExport]: GenerateView,
  [FEATURE_GROUPS.CompanyAudit]: RankingAuditView,
  [FEATURE_GROUPS.CMS]: CMSView
}

const Home = () => {
  const groups = useSelector(authSelectors.getGroups)
  if (groups.includes(FEATURE_GROUPS.Admin)) {
    return (
      <BaseLayout>
        <ReportsView />
      </BaseLayout>
    )
  }
  const homeGroup = groups.find(g => g !== FEATURE_GROUPS.Admin)
  const HomeView = FeatureGroupToViewMap[homeGroup]
  return <BaseLayout>{HomeView ? <HomeView /> : null}</BaseLayout>
}

export default Home
