// Eng / Styled Components
import { H4, H3, Text } from "@engaging-tech/components"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
// React
import React from "react"
// Components
import CreditsEditorCtr from "../../../containers/management_actions/manage_credits/CreditsEditorCtr"
import CreditsLogCtr from "../../../containers/management_actions/manage_credits/CreditsLogCtr"
import EditorSlider from "./EditorSlider"

const PanelHeader = ({ currentOrg, isLoading }) => {
  const { name, credits } = currentOrg

  return (
    <>
      {isLoading ? (
        <Spinner color="primary.0" width={1 / 1} py={6} />
      ) : (
        <>
          <H4 textAlign="center">{name}</H4>
          <H3 textAlign="center">{credits}</H3>
          <Text textAlign="center">Available credits</Text>
        </>
      )}
    </>
  )
}

const CreditButtons = ({ setOpenSlider }) => {
  const buttonList = [
    {
      id: 1,
      buttonText: "Add Credits",
      sliderAction: { add: true },
      bg: "#ff9900",
      color: "black",
      my: 3
    },
    {
      id: 2,
      buttonText: "Edit Credits",
      sliderAction: { edit: true },
      bg: "#ff9900",
      color: "black",
      my: 0
    },
    {
      id: 3,
      buttonText: "Log",
      sliderAction: { log: true },
      bg: "primary.0",
      color: "black",
      my: 3
    }
  ]

  return (
    <>
      {buttonList.map(obj => {
        const { buttonText, sliderAction, bg, color, id, my } = obj

        return (
          <Button
            key={id}
            width="150px"
            bg={bg}
            color={color}
            my={my}
            onClick={() => setOpenSlider(sliderAction)}
          >
            {buttonText}
          </Button>
        )
      })}
    </>
  )
}

const CreditsPanel = ({ currentOrg, isLoading, openSlider, setOpenSlider }) => {
  return (
    <Flex width={1 / 1} flexDirection="column" position="relative">
      <Flex width={1 / 1} flexDirection="column">
        <PanelHeader currentOrg={currentOrg} isLoading={isLoading} />
      </Flex>
      <EditorSlider openSlider={openSlider}>
        {(openSlider.add || openSlider.edit) && <CreditsEditorCtr />}
        {openSlider.log && <CreditsLogCtr />}
      </EditorSlider>
      <Flex width={1 / 1} flexDirection="column" alignItems="center">
        <CreditButtons setOpenSlider={setOpenSlider} />
      </Flex>
    </Flex>
  )
}

export default CreditsPanel
