import { useEffect } from "react"
import { connect } from "react-redux"

import { loadAllIndustries } from "../store/misc.actions"
import {
  getAllIndustries,
  getHasLoaded,
  getIsLoading
} from "../store/misc.selectors"

const IndustriesLoaderComponent = ({
  loading,
  hasLoaded,
  allIndustries,
  loadIndustries
}) => {
  useEffect(() => {
    if (loading) return
    if (hasLoaded && !allIndustries.length) {
      setTimeout(loadIndustries, 1000)
    } else if (!allIndustries.length) {
      loadIndustries()
    }
  }, [allIndustries, hasLoaded, loadIndustries, loading])

  return null
}

const mapState = state => ({
  allIndustries: getAllIndustries(state),
  loading: getIsLoading(state, "Industries"),
  hasLoaded: getHasLoaded(state, "Industries")
})

const mapDispatch = dispatch => ({
  loadIndustries: () => dispatch(loadAllIndustries())
})

const IndustriesLoader = connect(
  mapState,
  mapDispatch
)(IndustriesLoaderComponent)

export default IndustriesLoader
