import { connect } from "react-redux"
import { rankingAuditActions } from ".."
import RankingAuditComponent from "../components/RankingAudit"
import {
  getEntries,
  getIsLoading,
  getIsLoadingMore
} from "../store/rankingAudit.selectors"

const mapState = state => ({
  entries: getEntries(state),
  isLoading: getIsLoading(state),
  isLoadingMore: getIsLoadingMore(state)
})

const mapDispatch = dispatch => ({
  onLoadMore: ({
    from,
    id,
    companyName,
    reviewed,
    invalid,
    hasEmails,
    score
  }) =>
    dispatch(
      rankingAuditActions.loadMore(
        from,
        id,
        companyName,
        reviewed,
        invalid,
        hasEmails,
        score
      )
    ),
  onLoad: ({ from, id, companyName, reviewed, invalid, hasEmails, score }) =>
    dispatch(
      rankingAuditActions.load(
        from,
        id,
        companyName,
        reviewed,
        invalid,
        hasEmails,
        score
      )
    )
})

const RankingAudit = connect(mapState, mapDispatch)(RankingAuditComponent)

export default RankingAudit
