import React, { useState } from "react"

import { createPortal } from "react-dom"
import { useFormik } from "formik"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import TextField from "@engaging-tech/components/dist/lib/TextField/TextField"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"

import styled from "styled-components"
import * as Yup from "yup"
import SearchByOrgInput from "../../portable/SearchOrgInput"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_?><\\/.,`~'";:+-=)(])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("An email address is required"),
  organisation: Yup.object()
    .shape({
      name: Yup.string().required("Company Name Required"),
      id: Yup.string().required("Company ID Required")
    })
    .required("Required")
})

const FormWrapper = styled.form`
  width: 100%;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const AddUserForm = ({ orgs = [], initialValues, onSubmit, toggle }) => {
  const [searchOrgs, setSearchOrgs] = useState(false)
  const {
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    values,
    setFieldValue,
    validateField,
    isValid
  } = useFormik({
    initialValues,
    validateOnChange: true,
    onSubmit,
    validationSchema
  })

  const handleFieldChange = field => e => {
    handleChange(e)
    validateField(field)
  }
  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Flex alignItems="center" justifyContent="flex-start" mb={4}>
        <Text width={1 / 2}>First Name:</Text>
        <TextField
          name="firstName"
          type="text"
          width={1 / 1}
          error={errors.firstName}
          value={values.firstName}
          onChange={handleFieldChange("firstName")}
          onBlur={handleBlur}
          id="firstName"
          label="First Name"
        />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" mb={4}>
        <Text width={1 / 2}>Last Name:</Text>
        <TextField
          name="lastName"
          type="text"
          width={1 / 1}
          error={errors.lastName}
          value={values.lastName}
          onChange={handleFieldChange("lastName")}
          onBlur={handleBlur}
          id="lastName"
          label="Last Name"
        />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" mb={4}>
        <Text width={1 / 2}>Email:</Text>
        <TextField
          name="email"
          type="text"
          width={1 / 1}
          error={errors.email}
          value={values.email}
          onChange={handleFieldChange("email")}
          onBlur={handleBlur}
          id="email"
          label="Email Address"
        />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" mb={4}>
        <Text width={1 / 2}>Password:</Text>
        <TextField
          name="password"
          type="text"
          width={1 / 1}
          error={touched.password && errors.password}
          value={values.password}
          onChange={handleFieldChange("password")}
          onBlur={handleBlur}
          id="password"
          label="Password"
        />
      </Flex>
      {searchOrgs ? (
        <Flex>
          <SearchByOrgInput
            width={1}
            mt={0}
            buttonText="Select"
            onSubmit={({ id }) => {
              const org = orgs.find(o => o.id === id)
              setFieldValue("organisation", org)
              setSearchOrgs(false)
              validateField("organisation")
            }}
          />
          <Button
            variant="error.0"
            onClick={() => setSearchOrgs(false)}
            height="54px"
            ml={2}
          >
            Cancel
          </Button>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="flex-start" mb={4}>
          <Text width={1 / 2}>Organisation:</Text>
          {values.organisation ? (
            <Text width={1 / 2}>{values.organisation.name}</Text>
          ) : (
            <Text color="error.0">Please Select A Valid Company</Text>
          )}
          <Button variant="secondary.0" onClick={() => setSearchOrgs(true)}>
            Change
          </Button>
        </Flex>
      )}
      <Flex flexDirection="row" width={1 / 1} justifyContent="center" mt={4}>
        <Button mr={3} bg="secondary.2" onClick={toggle}>
          Cancel
        </Button>
        <Button bg="secondary.2" type="submit" disabled={!isValid}>
          Submit
        </Button>
      </Flex>
    </FormWrapper>
  )
}

const AddUser = ({ toggle, onSubmit, orgs, currentOrg }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    organisation: currentOrg || null
  }

  return createPortal(
    <Modal bg="light.0" p={4}>
      <Flex flexDirection="column" width={1 / 1} alignItems="stretch">
        <Text textAlign="center" mb={4} fontWeight={700}>
          Add A New User
        </Text>
        <AddUserForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          orgs={orgs}
          toggle={toggle}
          mt={[0, 3]}
        />
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default AddUser
