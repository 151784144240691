// Redux
import { connect } from "react-redux"
// Store
import { getCurrentOrg } from "../../../store/orgManagement.selectors"

// Components
import ViewServices from "../../../components/management_actions/view_services/ViewServices"

const mapState = state => ({
  currentOrg: getCurrentOrg(state)
})

const ViewServicesCtr = connect(mapState)(ViewServices)

export default ViewServicesCtr
