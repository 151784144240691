import React from "react"
import { hydrate } from "react-dom"
import { Provider as StoreProvider } from "react-redux"
import { ThemeProvider, createGlobalStyle } from "styled-components"

import { Amplify } from "aws-amplify"
import ClientRouter from "@engaging-tech/routing/dist/components/ClientRouter"
import workLTheme from "@engaging-tech/components/dist/themes/workL"
import App from "./App"
import store from "./store"
import "@aws-amplify/ui-react/styles.css"

import awsmobile from "./aws-exports"
import woffIcons from "./fonts/EngagingTechIcons.woff"
import ttfIcons from "./fonts/EngagingTechIcons.ttf"
import centraWoff from "./fonts/CentraNo2-Book.woff"
import centraWoff2 from "./fonts/CentraNo2-Book.woff2"

// const woffIcons = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/EngagingTechIcons.woff`)
// const ttfIcons = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/EngagingTechIcons.ttf`)
// const centraWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Book.woff`)
// const centraWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Book.woff2`)

Amplify.configure(awsmobile)

const GlobalStyles = createGlobalStyle`
@font-face {
   font-family: 'Centra';
   src: url(${centraWoff}) format('woff'),
        url(${centraWoff2}) format('woff2');
        
   font-weight: 400;
   font-style: normal;
  }

  @font-face {
    font-family: 'EngagingTechIcons';
    font-style: normal;
    font-weight: 400;
    src: 
      url(${woffIcons}) format('woff'),
      url(${ttfIcons}) format('truetype');
  }

 * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --container-height: 450px;
  }
body {
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`

const BaseApp = () => (
  <StoreProvider store={store}>
    <ClientRouter>
      <ThemeProvider theme={workLTheme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </ClientRouter>
  </StoreProvider>
)

hydrate(<BaseApp />, document.getElementById("root"))

if (module.hot) {
  module.hot.accept()
}
