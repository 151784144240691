// React
import React, { useEffect } from "react"
// Eng / Styled Components
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import Table from "@engaging-tech/components/dist/lib/Table/Table"
import { H3, Box, Flex } from "@engaging-tech/components"
import styled from "styled-components"
// Table Data
import { Headings, TableConfig } from "../../../lib/LogTableData"

const TableBox = styled(Box)`
  overflow-y: scroll;
  height: 225px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const prepareData = (logs = []) => {
  if (!logs) return []
  return logs.map(entry => ({
    oldValue: entry.oldValue,
    newValue: entry.newValue,
    userId: entry.userId,
    createdAt: entry.createdAt
  }))
}

const CreditsLog = ({
  setCloseSlider,
  isLoading,
  logs,
  getLogsByOrganisation,
  currentOrg
}) => {
  useEffect(() => {
    getLogsByOrganisation({ organisationId: currentOrg.id })
  }, [])

  const logLength = logs.length

  return (
    <>
      {isLoading ? (
        <Spinner color="primary.0" width={1 / 1} py={6} />
      ) : (
        <Flex width={1 / 1} flexDirection="column" alignItems="center">
          <TableBox width={1 / 1}>
            {logLength > 0 ? (
              <Table
                headings={Headings}
                config={TableConfig}
                rows={prepareData(logs)}
              />
            ) : (
              <H3 textAlign="center">Log is empty</H3>
            )}
          </TableBox>
          <Button my={2} onClick={() => setCloseSlider()}>
            Back
          </Button>
        </Flex>
      )}
    </>
  )
}

export default CreditsLog
