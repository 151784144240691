import { connect } from "react-redux"
import Component from "../components/GenerateButton"
import { generateExport } from "../store/dataexport.actions"
import { getField } from "../store/dataexport.selectors"

const mapState = state => ({
  surveyType: getField(state, "surveyType"),
  surveyId: getField(state, "surveyId")
})

const mapDispatch = dispatch => ({
  handleClick: () => dispatch(generateExport())
})

export default connect(mapState, mapDispatch)(Component)
