import { connect } from "react-redux"
import { reportsActions } from ".."
import BespokeModalComponent from "../components/BespokeModal.js"
import { startOfDay, endOfDay } from "date-fns"

const mapDispatch = dispatch => ({
  onSubmit: ({ startDate, endDate }) =>
    dispatch(
      reportsActions.loadQuery({
        from: startOfDay(startDate),
        to: endOfDay(endDate),
        type: "bespoke"
      })
    )
})

const BespokeModal = connect(null, mapDispatch)(BespokeModalComponent)

export default BespokeModal
