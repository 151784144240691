import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query (
    $from: Int
    $searchParams: SurveyCompanySearchParamsInput
    $limit: Int
  ) {
    getSurveyCompanies(
      limit: $limit
      skip: $from
      searchParams: $searchParams
    ) {
      id
      companyName
      industry {
        id
        name
      }
      country {
        id
        name
      }
      reviewed
      invalid
      emails
      score
    }
  }
`

export const UPDATE = gql`
  mutation updateSurveyCompany(
    $id: ID!
    $companyName: String
    $industryId: ID
    $countryId: ID
    $reviewed: Boolean
    $emails: [String]
  ) {
    updateSurveyCompany(
      id: $id
      companyName: $companyName
      industryId: $industryId
      countryId: $countryId
      reviewed: $reviewed
      emails: $emails
    ) {
      id
      companyName
      industry {
        id
        name
      }
      country {
        id
        name
      }
      reviewed
      invalid
      emails
    }
  }
`

export const REMOVE = gql`
  mutation deleteSurveyCompany($id: ID!) {
    deleteSurveyCompany(id: $id)
  }
`

export const MERGE = gql`
  mutation mergeSurveyCompany($id: ID!, $companyName: String!) {
    mergeSurveyCompany(id: $id, companyName: $companyName)
  }
`
