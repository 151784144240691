export const types = {
  SET_QUERY: "[Reports] Set Query",
  LOAD_QUERY: "[Reports] Load Query",
  LOAD_QUERY_SUCCESS: "[Reports] Load Query Success",
  LOAD_QUERY_FAIL: "[Reports] Load Query Fail"
}

export const setQuery = payload => ({
  type: types.SET_QUERY,
  payload
})

export const loadQuery = payload => ({
  type: types.LOAD_QUERY,
  payload
})

export const loadQuerySuccess = (response, type) => ({
  type: types.LOAD_QUERY_SUCCESS,
  payload: { response, type }
})

export const loadQueryFail = type => ({
  type: types.LOAD_QUERY_FAIL,
  payload: { type }
})
