import React from "react"
import CreditsPanelCtr from "../containers/management_actions/manage_credits/CreditsPanelCtr"
import ViewProductsContainer from "../containers/management_actions/premium_products/ViewProducts"
import ViewServicesCtr from "../containers/management_actions/view_services/ViewServicesCtr"

/* Configuration Array which will create a new button in the organisation 
managment page and will pass a component as child to the Modal to be 
rendered on the click of the button  */
const managmentOptions = [
  {
    id: 1,
    buttonText: "Manage Credits",
    component: <CreditsPanelCtr />
  },
  {
    id: 2,
    buttonText: "View Services",
    component: <ViewServicesCtr />
  },
  {
    id: 3,
    buttonText: "Premium Products",
    component: <ViewProductsContainer />
  }
]

export default managmentOptions
