import { put, call, takeLatest, select } from "redux-saga/effects"
import * as Actions from "./orgManagement.actions"
import {
  updateCredits,
  getLogsByOrganisation,
  getActiveProducts,
  deleteActiveProduct,
  getAvailableProducts,
  postActiveProduct,
  updateActiveProduct
} from "../services/orgManagement.service"
import { getCurrentOrg, selectActiveProducts } from "./orgManagement.selectors"

const getGraphQLErrCode = err => {
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

export function* updateCreditsSaga(action) {
  try {
    const res = yield call(updateCredits, action.payload)

    if (res.data.updateCredits) {
      yield put(Actions.updateCreditsSuccess(res.data.updateCredits))
      yield put(Actions.setCloseSlider())
    } else {
      yield put(Actions.updateCreditsFail(getGraphQLErrCode("Data not found")))
    }
  } catch (err) {
    yield put(Actions.updateCreditsFail(getGraphQLErrCode(err)))
  }
}

export function* getLogsByOrganisationSaga(action) {
  try {
    const res = yield call(getLogsByOrganisation, action.payload)

    yield put(
      Actions.getLogsByOrganisationSuccess(res.data.getLogsByOrganisation)
    )
  } catch (err) {
    yield put(Actions.getLogsByOrganisationFail(getGraphQLErrCode(err)))
  }
}

export function* getActiveProductsSaga() {
  try {
    const { id } = yield select(getCurrentOrg)
    const res = yield call(getActiveProducts, { organisationId: id })

    yield put(Actions.getActiveProductsSuccess(res.data.getActiveProducts))
  } catch (err) {
    yield put(Actions.getActiveProductsFail(err))
  }
}

export function* deleteActiveProductSaga(action) {
  const { id } = yield select(getCurrentOrg)
  const reqObj = { ...action.payload, organisationId: id }

  try {
    const res = yield call(deleteActiveProduct, reqObj)

    yield put(Actions.deleteActiveProductSuccess(res.data.deleteActiveProduct))
  } catch (err) {
    yield put(Actions.deleteActiveProductFail(err))
  }
}

export function* getAvailableProductsSaga() {
  try {
    const { id } = yield select(getCurrentOrg)
    const res = yield call(getAvailableProducts, { organisationId: id })

    yield put(
      Actions.getAvailableProductsSuccess(res.data.getAvailableProducts)
    )
  } catch (err) {
    yield put(Actions.getAvailableProductsFail(err))
  }
}

export function* postActiveProductSaga(action) {
  const { id } = yield select(getCurrentOrg)
  const reqObj = { ...action.payload, organisationId: id }

  try {
    const res = yield call(postActiveProduct, reqObj)

    yield put(Actions.postActiveProductSuccess(res.data.postActiveProduct))
  } catch (err) {
    yield put(Actions.postActiveProductFail(err))
  }
}

export function* updateActiveProductSaga() {
  const { id } = yield select(getCurrentOrg)
  const { editData } = yield select(selectActiveProducts)
  const reqObj = { organisationId: id, ...editData }

  try {
    const res = yield call(updateActiveProduct, reqObj)

    yield put(Actions.updateActiveProductSuccess(res.data.updateActiveProduct))
  } catch (err) {
    yield put(Actions.updateActiveProductFail(err))
  }
}

export function* saga() {
  yield takeLatest(Actions.types.UPDATE_CREDITS_REQUEST, updateCreditsSaga)
  yield takeLatest(
    Actions.types.GET_LOGS_BY_ORGANISATION_REQUEST,
    getLogsByOrganisationSaga
  )
  yield takeLatest(
    Actions.types.GET_ACTIVE_PRODUCTS_REQUEST,
    getActiveProductsSaga
  )
  yield takeLatest(
    Actions.types.DELETE_ACTIVE_PRODUCT_REQUEST,
    deleteActiveProductSaga
  )
  yield takeLatest(
    Actions.types.GET_AVAILABLE_PRODUCTS_REQUEST,
    getAvailableProductsSaga
  )
  yield takeLatest(
    Actions.types.POST_ACTIVE_PRODUCT_REQUEST,
    postActiveProductSaga
  )
  yield takeLatest(
    Actions.types.UPDATE_ACTIVE_PRODUCT_REQUEST,
    updateActiveProductSaga
  )
}
