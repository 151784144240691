import React from "react"

import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import ToggleLogic from "@engaging-tech/components/dist/lib/ToggleLogic/ToggleLogic"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import BespokeModal from "../containers/BespokeModal"
import BespokeTable from "../containers/BespokeTable"

const Bespoke = ({ queryStats }) => {
  return (
    <Flex width={1 / 1} flexDirection="column" alignItems="center">
      <ToggleLogic>
        {({ on, toggle }) => (
          <>
            <Button
              type="button"
              variant="primary.0"
              width="auto"
              onClick={toggle}
              mb={4}
            >
              Choose Bespoke Dates
            </Button>
            {on && <BespokeModal toggle={toggle} />}
          </>
        )}
      </ToggleLogic>
      {queryStats && <BespokeTable />}
    </Flex>
  )
}

export default Bespoke
