import { useEffect } from "react"

const FetchSurveysForOrg = ({
  surveys,
  children,
  organisationId,
  fetchSurveys
}) => {
  useEffect(() => {
    if (organisationId) fetchSurveys(organisationId)
  }, [fetchSurveys, organisationId])

  return children({ surveys, organisationId })
}

export default FetchSurveysForOrg
