import { connect } from "react-redux"
import DateRange from "../../components/OptionsSelect/DateRange"
import { changeDates } from "../../store/dataexport.actions"
import { getField } from "../../store/dataexport.selectors"

const mapState = state => ({
  startDate: getField(state, "startDate"),
  endDate: getField(state, "endDate"),
  error: getField(state, "error")
})

const mapDispatch = dispatch => ({
  onDateChange: (dateName, date, isError) =>
    dispatch(changeDates(dateName, date, isError))
})

export default connect(mapState, mapDispatch)(DateRange)
