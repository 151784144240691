import React from "react"

import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import { useSelector } from "react-redux"
import { Redirect } from "@engaging-tech/routing"
import StatusLoader from "../containers/StatusLoader"
import EWStatusTable from "../containers/EWStatusTable"
import EBStatusTable from "../containers/EBStatusTable"
import { authSelectors } from "../../auth"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"

const Status = () => {
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.Status),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />
  return (
    <>
      <StatusLoader />
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <EWStatusTable title="Workl status report" />
        <EBStatusTable title="Engaging Business status report" />
      </Flex>
    </>
  )
}

export default Status
