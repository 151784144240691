import createTopFieldSelectorFactory from "../../../../helpers/createTopFieldSelectorFactory"

const orgSearchState = state => state.UMOrganisationSearch

const createTopFieldSelector = createTopFieldSelectorFactory(orgSearchState)

export const getCurrentOrg = createTopFieldSelector("currentOrg")

export const getUsers = createTopFieldSelector("users")

export const getIsLoadingUsers = createTopFieldSelector("isLoadingUsers")

export const getErrorCode = createTopFieldSelector("errorCode")

export const getIsLoadingMoreUsers =
  createTopFieldSelector("isLoadingMoreUsers")

export const getIsMoreUsersToLoad = createTopFieldSelector("isMoreUsersToLoad")
