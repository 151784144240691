/* eslint-disable no-nested-ternary */
import React from "react"
import { Flex, Text, Button, Spinner } from "@engaging-tech/components"
import styled from "styled-components"
import GetActiveProducts from "../../../loaders/getActiveProducts"
import TextData from "./TextData"
import ControlButtons from "./ControlButtons"
import ConfirmButtons from "./ConfirmButtons"
import AddProductContainer from "../../../containers/management_actions/premium_products/AddProduct"

const StyledBox = styled(Flex)`
  border-top: ${({ i, selected }) => i !== 0 && !selected && "1px solid grey"};
  overflow: hidden;
  height: ${({ selected, visible }) => (selected && !visible ? "0px" : "auto")};
  padding: ${({ selected, visible }) =>
    selected && !visible ? "0px" : "10px 0px"};
`

const ProductCard = ({
  product,
  selectedProduct,
  action,
  editData,
  setEditData,
  setSelectedProduct,
  setAction,
  confirmAction,
  disableButton,
  i
}) => (
  <StyledBox
    width={1 / 1}
    mx={2}
    justifyContent="space-between"
    i={i}
    selected={!!selectedProduct.id}
    visible={selectedProduct.id === product.id}
    flexWrap="wrap"
  >
    {action === "delete" && (
      <Text width={1 / 1} textAlign="center" fontSize={5} color="red">
        Are you sure you want to delete this product from the account?
      </Text>
    )}

    {action === "edit" && (
      <Text width={1 / 1} textAlign="center" fontSize={4} color="primary.0">
        Edit mode
      </Text>
    )}

    <TextData
      product={product}
      action={action}
      editData={editData}
      setEditData={setEditData}
    />

    {selectedProduct.id ? (
      <ConfirmButtons
        setSelectedProduct={setSelectedProduct}
        setAction={setAction}
        clickAction={confirmAction}
        disableButton={disableButton}
      />
    ) : (
      <ControlButtons
        setSelectedProduct={setSelectedProduct}
        product={product}
        setAction={setAction}
        setEditData={setEditData}
      />
    )}
  </StyledBox>
)

const ViewFeatures = ({
  activeProducts,
  deleteActiveProduct,
  updateActiveProduct,
  availableProducts,
  postActiveProduct,
  selectedProduct,
  setSelectedProduct,
  action,
  setAction,
  editData,
  setEditData,
  newProduct,
  setNewProduct,
  isLoading,
  error
}) => {
  const confirmAction = () => {
    if (action === "delete") deleteActiveProduct({ id: selectedProduct.id })
    if (action === "update")
      updateActiveProduct({ ...editData, id: setSelectedProduct.id })
    if (action === "add") postActiveProduct(newProduct)
  }

  const disableButton = action === "update" && !editData.invoiceId

  return (
    <>
      <GetActiveProducts />

      {isLoading ? (
        <>
          <Text my={2} textAlign="center">
            Fetching Data
          </Text>
          <Spinner color="primary.0" mb={3} />
        </>
      ) : error ? (
        <Flex textAlign="center" my={2}>
          Something went wrong
        </Flex>
      ) : (
        <>
          {!action && (
            <Button bg="#ff9900" onClick={() => setAction("add")}>
              Add new product
            </Button>
          )}

          {action === "add" ? (
            <AddProductContainer
              availableProducts={availableProducts}
              setSelectedProduct={setSelectedProduct}
              confirmAction={confirmAction}
              setAction={setAction}
              newProduct={newProduct}
              setNewProduct={setNewProduct}
              action={action}
            />
          ) : (
            <Flex width={1 / 1} flexWrap="wrap">
              {activeProducts?.map((product, i) => (
                <ProductCard
                  i={i}
                  key={product.id}
                  product={product}
                  selectedProduct={selectedProduct}
                  action={action}
                  editData={editData}
                  setEditData={setEditData}
                  setSelectedProduct={setSelectedProduct}
                  setAction={setAction}
                  confirmAction={confirmAction}
                  disableButton={disableButton}
                />
              ))}
            </Flex>
          )}
        </>
      )}
    </>
  )
}

export default ViewFeatures
