/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const GET_ALL = gql`
  query ($from: Date, $to: Date) {
    IndividualUsers(from: $from, to: $to) {
      count
      users {
        firstName
        lastName
        email
        createdAt
        confirmed
        location
        allowMarketing
      }
    }

    IndividualUsersPremiumStartedAt(
      from: $from
      to: $to
      isPremiumQuery: true
    ) {
      count
      users {
        firstName
        lastName
        email
        createdAt
        premiumStartedAt
        confirmed
        location
        allowMarketing
      }
    }

    IndividualSurvey(from: $from, to: $to) {
      completed
    }

    IndividualWorkplaceHappinessSurvey(from: $from, to: $to) {
      completed
    }

    IndividualWorkFromHomeSurvey(from: $from, to: $to) {
      completed
    }

    OrganisationsUsers(from: $from, to: $to) {
      count
      organisations {
        name
        email
        confirmed
        allowMarketing
        websiteURL
        createdAt
      }
    }

    OrganisationWithEBNSubscription(from: $from, to: $to) {
      count
      organisations {
        name
        email
        confirmed
        allowMarketing
        websiteURL
        createdAt
      }
    }

    OrganisationEmployeeSurvey(from: $from, to: $to) {
      completed
    }

    OrganisationWithJobBoardSubscription(from: $from, to: $to) {
      count
    }

    OrganisationWithLeadsSubscription(from: $from, to: $to) {
      count
    }

    OrganisationWithEventsSubscription(from: $from, to: $to) {
      count
    }

    OrganisationWithProfile(from: $from, to: $to) {
      count
    }

    WellbeingSurvey(from: $from, to: $to) {
      completed
    }
  }
`
