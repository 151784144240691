import { takeLatest, call, put } from "redux-saga/effects"
import {
  loadAllCountriesService,
  loadAllIndustriesService,
  loadSurveyJobRolesService,
  loadAllJobRolesService,
  loadAllSkills,
  loadAllLanguages,
  loadAllHobbies,
  loadAllRegions
} from "../services/misc.service"

import * as Actions from "./misc.actions"

export function* loadAllCountriesSaga() {
  try {
    const res = yield call(loadAllCountriesService)
    if (res.data.allCountries) {
      yield put(Actions.loadAllCountriesSuccess(res.data.allCountries))
    }
  } catch {
    yield put(Actions.loadFailCountries())
  }
}

export function* loadAllIndustriesSaga() {
  try {
    const res = yield call(loadAllIndustriesService)
    if (res.data.allIndustries) {
      yield put(Actions.loadAllIndustriesSuccess(res.data.allIndustries))
    }
  } catch {
    yield put(Actions.loadFailIndustries())
  }
}
export function* loadAllJobRolesSaga() {
  try {
    const res = yield call(loadAllJobRolesService)
    if (res.data.allJobRoles) {
      yield put(Actions.loadAllJobRolesSuccess(res.data.allJobRoles))
    }
  } catch {
    yield put(Actions.loadFailJobRoles())
  }
}
export function* loadSurveyJobRolesSaga() {
  try {
    const res = yield call(loadSurveyJobRolesService)
    if (res.data.allSurveyJobRoles) {
      yield put(Actions.loadSurveyJobRolesSuccess(res.data.allSurveyJobRoles))
    }
  } catch {
    yield put(Actions.loadFailSurveyJobRoles())
  }
}

export function* loadSkillsSaga() {
  try {
    const res = yield call(loadAllSkills)
    if (res.data.allSkills) {
      yield put(Actions.loadSkillsSuccess(res.data.allSkills))
    }
  } catch {
    yield put(Actions.loadSkillsFail())
  }
}

export function* loadHobbiesSaga() {
  try {
    const res = yield call(loadAllHobbies)
    if (res.data.allHobbies) {
      yield put(Actions.loadHobbiesSuccess(res.data.allHobbies))
    }
  } catch {
    yield put(Actions.loadHobbiesFail())
  }
}

export function* loadLanguagesSaga() {
  try {
    const res = yield call(loadAllLanguages)
    if (res.data.allLanguages) {
      yield put(Actions.loadLanguagesSuccess(res.data.allLanguages))
    }
  } catch {
    yield put(Actions.loadLanguagesFail())
  }
}
export function* loadRegionsSaga() {
  try {
    const res = yield call(loadAllRegions)
    if (res.data.allRegions) {
      yield put(Actions.loadRegionsSuccess(res.data.allRegions))
    }
  } catch {
    yield put(Actions.loadRegionsFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_ALL_COUNTRIES, loadAllCountriesSaga)
  yield takeLatest(Actions.types.LOAD_ALL_INDUSTRIES, loadAllIndustriesSaga)
  yield takeLatest(Actions.types.LOAD_ALL_JOB_ROLES, loadAllJobRolesSaga)
  yield takeLatest(Actions.types.LOAD_SURVEY_JOB_ROLES, loadSurveyJobRolesSaga)
  yield takeLatest(Actions.types.LOAD_SKILLS, loadSkillsSaga)
  yield takeLatest(Actions.types.LOAD_LANGUAGES, loadLanguagesSaga)
  yield takeLatest(Actions.types.LOAD_HOBBIES, loadHobbiesSaga)
  yield takeLatest(Actions.types.LOAD_REGIONS, loadRegionsSaga)
}
