import React from "react"
import { Redirect } from "@engaging-tech/routing"
import { useSelector } from "react-redux"
import { authSelectors } from "../../auth"

import UserManagementComponent from "../components/UserManagement"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"

const UserManagement = () => {
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.EBUserManagement),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />
  return <UserManagementComponent />
}

export default UserManagement
