import React from "react"

import { SurveyTypes } from "../../lib"
import GetIsWorkFromHome from "../../containers/SurveySelect/GetIsWorkFromHome"
import ChooseOrg from "../../containers/SurveySelect/ChooseOrg"

const Secondary = ({ surveyType }) => {
  switch (surveyType) {
    case SurveyTypes.organisation:
      return <ChooseOrg />
    case SurveyTypes.happiness:
    case SurveyTypes.savanta:
      return <GetIsWorkFromHome />

    default:
      return null
  }
}

export default Secondary
