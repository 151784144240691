import React from "react"

import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Checkbox from "@engaging-tech/components/dist/lib/Checkbox/Checkbox"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import { AdditionalColumnNames } from "../../lib"

const AdditionalColumns = ({ additionalColumns, setColumnValue, ...props }) => {
  return (
    <Box width={1} {...props}>
      <Text width={1} display="block">
        Additional Columns
      </Text>
      <Text fontSize={2} color="dark.2">
        Check these to include these columns in the export.
      </Text>
      <Flex flexWrap="wrap" mt={2}>
        {Object.keys(AdditionalColumnNames).map(name => (
          <Checkbox
            id={name}
            key={name}
            mb={[2, 2, 0]}
            width={1 / 2}
            checked={additionalColumns[name]}
            onChange={() => setColumnValue(name, !additionalColumns[name])}
            label={() => (
              <Text fontSize={2}>{AdditionalColumnNames[name]}</Text>
            )}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default AdditionalColumns
