import React from "react"

import { useSelector } from "react-redux"
import { Redirect } from "@engaging-tech/routing"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import { getSelectedQueryType } from "../store/reports.selectors"

import NavBar from "../../navigation/containers/ReportsNav"
import Bespoke from "../containers/Bespoke"
import Lifetime from "../containers/Lifetime"
import LastMonth from "../containers/LastMonth"
import LastWeek from "../containers/LastWeek"
import Last24 from "../containers/Last24"
import { authSelectors } from "../../auth"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"

const Reports = () => {
  const currentQuery = useSelector(getSelectedQueryType)
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.Reports),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />
  return (
    <Flex justifyContent="center" flexDirection="column" alignItems="center">
      <NavBar />
      {currentQuery === "lifetime" && <Lifetime />}
      {currentQuery === "lastMonth" && <LastMonth />}
      {currentQuery === "lastWeek" && <LastWeek />}
      {currentQuery === "last24" && <Last24 />}
      {currentQuery === "bespoke" && <Bespoke />}
    </Flex>
  )
}

export default Reports
