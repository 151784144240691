import { connect } from "react-redux"
import LoadMoreUsersButtonComponent from "../components/LoadMoreUserButton"
import {
  getUsers,
  getIsLoadingMoreUsers,
  getIsMoreUsersToLoad
} from "../store/organisationSearch/organisationSearch.selectors"
import { loadMoreOrgUsers } from "../store/organisationSearch/organisationSearch.actions"

const mapState = state => ({
  users: getUsers(state),
  isLoading: getIsLoadingMoreUsers(state),
  isMoreToLoad: getIsMoreUsersToLoad(state)
})

const mapDispatch = dispatch => ({
  loadMoreOrgUsers: skip => dispatch(loadMoreOrgUsers(skip))
})

const LoadMoreUsersButton = connect(
  mapState,
  mapDispatch
)(LoadMoreUsersButtonComponent)

export default LoadMoreUsersButton
