import React from "react"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import DatePicker from "react-multi-date-picker"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Box from "@engaging-tech/components/dist/lib/Box/Box"

// Needs to have a look into DatePicker date converstion /01/0/2020 will convert into 01/12/2020, thus making the validation pass.
const validateDate = date => {
  const dateRegex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/
  return dateRegex.test(date)
}

const DatePickerContainer = ({
  dateText,
  date,
  dateName,
  error,
  onDateChange
}) => {
  return (
    <Box>
      <Text display="block" fontSize={2}>
        {dateText}
      </Text>
      <DatePicker
        title="Start Date"
        format="DD/MM/YYYY"
        value={date}
        onChange={value =>
          value &&
          onDateChange(dateName, value.toDate(), !validateDate(value.format()))}
      />
      {/* {error[dateName] && (
        <Text color="red" fontSize={2} display="block">
          Invalid date format
        </Text>
      )} */}
    </Box>
  )
}

const DateRange = ({ startDate, endDate, onDateChange, error }) => {
  return (
    <Flex width={1 / 1} flexDirection="column">
      <Text width="auto" fontSize={4} mb={2}>
        Date Range
      </Text>
      <DatePickerContainer
        dateText="Start Date"
        date={startDate}
        dateName="startDate"
        error={error}
        onDateChange={onDateChange}
      />
      <DatePickerContainer
        dateText="End Date"
        date={endDate}
        dateName="endDate"
        error={error}
        onDateChange={onDateChange}
      />
    </Flex>
  )
}

export default DateRange
