/* eslint-disable import/prefer-default-export */
export const SurveyTypes = {
  organisation: "Organisation",
  happiness: "Happiness",
  savanta: "Savanta"
}

export const AdditionalColumnNames = {
  companyNameDisplay: "Company Name",
  happinessScore: "Happiness Score",
  completedAt: "Completed At",
  email: "Email",
  name: "Name",
  code: "Code",
  savantaId: "Savanta ID",
  savantaTerritory: "Savanta Territory"
}

export const NonOrgSurveyIds = {
  [SurveyTypes.happiness]: {
    workFromHome: "5ef4ac43d95fbbe07467f1ff",
    standard: "5e1d996bd95fbbe074bd5b9f"
  },
  [SurveyTypes.savanta]: {
    workFromHome: "5fa3e988d95fbbe074310a99",
    standard: "5f59f336e4c9fa699c8da269"
  }
}
