import React from "react"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import { SurveyTypes } from "../lib"

const getIsValid = ({ surveyType, surveyId }) => {
  if (!surveyType) return false
  if (surveyType !== SurveyTypes.organisation) return true
  return !!surveyId
}

const GenerateButton = ({ surveyType, surveyId, handleClick }) => {
  const isValid = getIsValid({ surveyType, surveyId })
  return (
    <Flex width={1} justifyContent="center" py={3}>
      <Button
        disabled={!isValid}
        variant="primary.0"
        onClick={() => {
          handleClick()
        }}
      >
        Generate Export
      </Button>
    </Flex>
  )
}

export default GenerateButton
