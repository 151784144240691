import { types } from "./reports.actions"

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  selectedQuery: "last24"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_QUERY:
      return {
        ...state,
        selectedQuery: action.payload.type
      }

    case types.LOAD_QUERY:
      return {
        ...state,
        selectedQuery: action.payload.type,
        [action.payload.type]: {
          isLoading: true,
          hasLoaded: false,
          hasError: false
        }
      }
    case types.LOAD_QUERY_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...action.payload.response,
          isLoading: false,
          hasLoaded: true,
          hasError: false
        }
      }
    case types.LOAD_QUERY_FAIL:
      return {
        ...state,
        [action.payload.type]: {
          isLoading: false,
          hasLoaded: false,
          hasError: true
        }
      }

    default:
      return state
  }
}
