import React, { useState, useEffect } from "react"

import { createPortal } from "react-dom"
import { Formik } from "formik"
import styled from "styled-components"
import * as Yup from "yup"
import { updateSuccessFlag } from "../store/rankingAudit.actions"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import TextField from "@engaging-tech/components/dist/lib/TextField/TextField"
import Select from "@engaging-tech/components/dist/lib/Select/Select"
import Checkbox from "@engaging-tech/components/dist/lib/Checkbox/Checkbox"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"

const FormWrapper = styled.form`
  width: 100%;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const EditForm = ({
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  toggle,
  data,
  setFieldValue,
  industries,
  countries
}) => {
  const [reviewedFlag, setReviewedFlag] = useState(data.reviewed)

  const handleReviewedChange = () => {
    setFieldValue("reviewed", !reviewedFlag)
    setReviewedFlag(!reviewedFlag)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    handleSubmit(e)
  }

  if (!industries.length || !countries.length)
    return <Spinner color="primary.0" />

  return (
    <FormWrapper onSubmit={handleFormSubmit}>
      <Flex alignItems="center" justifyContent="flex-start" mb={4}>
        <Text width={1 / 2}>Company Name:</Text>
        <TextField
          name="companyName"
          type="text"
          width={1 / 1}
          error={errors.companyName}
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          id="companyName"
          label="Company Name"
        />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" mb={4}>
        <Text width={1 / 2}>Company Emails:</Text>
        <TextField
          name="emails"
          type="text"
          width={1 / 1}
          error={errors.emails}
          value={values.emails}
          onChange={handleChange}
          onBlur={handleBlur}
          id="emails"
          label="Company Emails"
        />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" mb={5}>
        <Text width={1 / 2}>Industry:</Text>
        <Select
          name="industryId"
          type="text"
          width={1 / 1}
          error={errors.industryId}
          value={values.industryId}
          onChange={handleChange}
          id="industryId"
          label="Industry"
        >
          <option disabled value="">
            {" "}
          </option>
          {industries.map(ind => (
            <option key={ind.id} value={ind.id}>
              {ind.name}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" mb={5}>
        <Text width={1 / 2}>Country:</Text>
        <Select
          name="countryId"
          type="text"
          width={1 / 1}
          error={errors.countryId}
          onBlur={handleBlur}
          value={values.countryId}
          onChange={handleChange}
          id="countryId"
          label="Country"
        >
          <option disabled value="">
            {" "}
          </option>
          {countries.map(country => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </Select>
      </Flex>
      <Checkbox
        mb={2}
        onChange={handleReviewedChange}
        error={errors.reviewed}
        value={values.reviewed}
        checked={reviewedFlag}
        label={() => <Text>Set this entry as reviewed</Text>}
      />
      <Text width={1 / 1} textAlign="right" fontSize={2} color="#ff0000">
        You'll need to have a country selected before you can submit
      </Text>
      <Flex flexDirection="row" width={1 / 1} justifyContent="center" mt={4}>
        <Button mr={3} bg="secondary.2" onClick={toggle}>
          Cancel
        </Button>
        <Button bg="secondary.2" type="submit">
          Submit
        </Button>
      </Flex>
    </FormWrapper>
  )
}

const validationSchema = Yup.object().shape({
  countryId: Yup.string().required("Required")
})

const EditModal = ({
  data,
  toggle,
  onSubmit,
  isSubmitting,
  hasError,
  updateSuccess,
  resetUpdateSuccess,
  resetHasError,
  mergeRequest,
  callToMerge,
  onLoad,
  from,
  entryIdSearch,
  nameSearchTerm,
  reviewedSearch,
  emailSearch,
  scoreSearch,
  invalidSearch,
  newCompanyName,
  updateNoChange,
  noChange,
  setMergeRequest,
  isMerging,
  industries,
  countries
}) => {
  const initialValues = {
    id: data.id,
    companyName: data.companyName,
    industryId: data.industry ? data.industry.id : "",
    countryId: data.country ? data.country.id : "",
    reviewed: data.reviewed || false,
    emails: data.emails && data.emails !== "" ? data.emails.join(", ") : ""
  }

  return createPortal(
    <Modal bg="light.0" p={3}>
      <Flex flexDirection="column" width={1 / 1} alignItems="stretch">
        <Text textAlign="center" mb={4} fontWeight={700}>
          Edit This Entry
        </Text>
        {isSubmitting || isMerging ? (
          <Spinner py={3} color="primary.0" width={1 / 1} />
        ) : (
          <>
            {hasError && (
              <Flex flexDirection="column" alignItems="center">
                <Text textAlign="center">
                  There was a problem updating this entry, please inform the
                  Engaging Tech team
                </Text>
                <Button
                  mt={3}
                  bg="secondary.2"
                  onClick={() => {
                    toggle()
                    resetHasError()
                  }}
                >
                  Close
                </Button>
              </Flex>
            )}
            {!hasError && !updateSuccess && !mergeRequest && !noChange && (
              <>
                <Text mb={5} fontSize={3} color="primary.1">
                  For the emails list, enter emails seperated by a comma and
                  space all on one line (eg- "test@test.com, test2@test2.com,
                  test3@test3.com")
                </Text>
                <Text mb={4}>Entry ID: {data.id}</Text>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {props => {
                    return (
                      <EditForm
                        {...props}
                        toggle={toggle}
                        mt={[0, 3]}
                        data={data}
                        industries={industries}
                        countries={countries}
                      />
                    )
                  }}
                </Formik>
              </>
            )}
            {mergeRequest && (
              <Flex flexDirection="column" alignItems="center">
                <Text textAlign="center">
                  There is already an entry with that name and industry, would
                  you like to merge the two?
                </Text>
                <Flex justifyContent="center" mt={2}>
                  <Button
                    mr={3}
                    bg="secondary.2"
                    onClick={() => {
                      callToMerge({ id: data.id, companyName: newCompanyName })
                      setMergeRequest()
                      updateSuccessFlag()
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    bg="secondary.2"
                    onClick={() => {
                      setMergeRequest()
                      updateNoChange(true)
                    }}
                  >
                    No
                  </Button>
                </Flex>
              </Flex>
            )}
            {noChange && (
              <Flex flexDirection="column" alignItems="center">
                <Text textAlign="center">
                  This entry has not been changed and merged. You may wish to
                  remove it, if it is a duplicate that you don't want to merge.
                </Text>
                <Button
                  mt={3}
                  bg="secondary.2"
                  onClick={() => {
                    updateNoChange(false)
                    onLoad({
                      from: from,
                      id: entryIdSearch,
                      companyName: nameSearchTerm,
                      reviewed: reviewedSearch,
                      invalid: invalidSearch,
                      hasEmails: emailSearch,
                      score: scoreSearch
                    })
                    toggle()
                  }}
                >
                  Close
                </Button>
              </Flex>
            )}
            {updateSuccess && (
              <Flex flexDirection="column" alignItems="center">
                <Text textAlign="center">
                  This entry has been updated! You will see the changes when you
                  search for it tomorrow on the rankings tables.
                </Text>
                <Button
                  mt={3}
                  bg="secondary.2"
                  onClick={() => {
                    resetUpdateSuccess()
                    onLoad({
                      from: from,
                      id: entryIdSearch,
                      companyName: nameSearchTerm,
                      reviewed: reviewedSearch,
                      invalid: invalidSearch,
                      hasEmails: emailSearch,
                      score: scoreSearch
                    })
                    toggle()
                  }}
                >
                  Close
                </Button>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default EditModal
