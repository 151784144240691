import React, { useEffect } from "react"
import { queryTypes } from "../../reports/lib/reportsLibrary"

import ChipBar from "./ChipBar"

const ReportsNav = ({ onSelect, selected }) => {
  useEffect(() => {
    onSelect(queryTypes[selected])
  }, [onSelect, selected])

  const chips = Object.values(queryTypes).map(query => ({
    name: query.type,
    onClick: () => onSelect(query),
    isActive: selected === query.type
  }))

  return <ChipBar chips={chips} />
}

export default ReportsNav
