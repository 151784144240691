/* eslint-disable import/prefer-default-export */
import { gql } from "../../../../lib/Apollo"

export const SEARCH_ORGS_BY_NAME = gql`
  query ($name: String!) {
    searchOrgsByName(name: $name) {
      id
      name
      credits
      services {
        name
        active
        expireDate
      }
    }
  }
`
