import { types } from "./dataexport.actions"
import { AdditionalColumnNames } from "../lib"

const additionalColumns = Object.keys(AdditionalColumnNames).reduce(
  (acc, name) => {
    acc[name] = false
    return acc
  },
  {}
)

export const initialState = {
  isGenerating: false,
  exportUrl: null,
  surveyType: null,
  isWorkFromHome: false,
  organisationId: null,
  organisationName: null,
  surveyId: null,
  surveyName: null,
  companyNameRequired: false,
  // 1st Jan 2018
  startDate: new Date(1514764800000),
  endDate: new Date(),
  additionalColumns,
  error: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        error: { ...action.payload.error }
      }
    case types.SET_FIELD:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      }
    case types.SET_COLUMN_VALUE:
      return {
        ...state,
        additionalColumns: {
          ...state.additionalColumns,
          [action.payload.name]: action.payload.value
        }
      }

    case types.GENERATE_EXPORT:
      return {
        ...state,
        isGenerating: true
      }
    case types.GENERATE_EXPORT_SUCCESS:
      return {
        ...state,
        isGenerating: false,
        exportUrl: action.payload
      }

    case types.RESET_ORG:
      return {
        ...state,
        organisationId: null,
        organisationName: null
      }
    case types.CHANGE_DATES: {
      const { dateName, date, isError } = action.payload
      return {
        ...state,
        [dateName]: date,
        error: { ...state.error, [dateName]: isError }
      }
    }
    default:
      return state
  }
}
