import { connect } from "react-redux"
import { reportsSelectors, reportsActions } from ".."
import ReportTable from "../components/ReportTable.js"

const mapState = state => ({
  selected: reportsSelectors.getSelectedQueryType(state),
  queryStats: reportsSelectors.getBespokeStats(state)
})

const mapDispatch = dispatch => ({
  loadQuery: queryType => dispatch(reportsActions.loadQuery(queryType))
})

const BespokeTable = connect(mapState, mapDispatch)(ReportTable)

export default BespokeTable
