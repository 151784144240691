import createTopFieldSelectorFactory from "../../../helpers/createTopFieldSelectorFactory"

const orgManagementState = state => state.orgManagement

const createTopFieldSelector = createTopFieldSelectorFactory(orgManagementState)

export const getCurrentOrg = createTopFieldSelector("currentOrg")

export const getOpenSlider = createTopFieldSelector("openSlider")

export const getComponent = createTopFieldSelector("component")

export const getIsLoading = createTopFieldSelector("isLoading")

export const getErrorCode = createTopFieldSelector("errorCode")

export const getLogs = createTopFieldSelector("logs")

export const selectActiveProducts = createTopFieldSelector("activeProducts")

export const getAvailableProducts = createTopFieldSelector("availableProducts")
