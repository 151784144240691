/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect"

const dataExportState = state => state?.dataExport

export const getField = createSelector(
  [
    dataExportState,
    (state, fieldName) => {
      return fieldName
    }
  ],
  (state, fieldName) => state && state[fieldName]
)
