// React
import React from "react"
// Eng / Styled Components
import { Flex, H4, Box, Text } from "@engaging-tech/components"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import styled from "styled-components"
// Config Obj
import orgActionsConfig from "../lib/OrgActionsConfig"
// Components
import OrganisationModalCtr from "../containers/reusables/OrganisationModalCtr"

// Would like to get these into correct colors
const IndexBox = styled(Box)`
  border-left: 10px solid #ff9900;

  &:hover {
    cursor: pointer;
    background-color: #ff9900;
    transition: 0.4s ease-out;
  }

  &:active {
    transition: 0s;
    outline: 2px inset lightgrey;
  }
`

const OrganisationButton = ({ entry, setComponent }) => {
  const { buttonText, component } = entry

  return (
    <>
      <IndexBox
        width={1 / 1}
        bg="primary.5"
        py={3}
        mb={1}
        onClick={() => setComponent(component)}
      >
        <Text fontSize={5} ml={4}>
          {buttonText}
        </Text>
      </IndexBox>
    </>
  )
}

const ManagmentActions = ({ setComponent }) => {
  return (
    <>
      {orgActionsConfig.map(entry => {
        const { id } = entry
        return (
          <OrganisationButton
            entry={entry}
            setComponent={setComponent}
            key={id}
          />
        )
      })}
    </>
  )
}

const PageHeader = ({ name, setCurrentOrg }) => {
  return (
    <>
      <H4 color="primary.0" width={1 / 1} textAlign="center">
        {`${name} managment`}
      </H4>
      <Button width="150px" mb={4} mt={2} onClick={() => setCurrentOrg(null)}>
        Back
      </Button>
    </>
  )
}

const OrganisationPage = ({
  currentOrg,
  setCurrentOrg,
  setComponent,
  component
}) => {
  const { name } = currentOrg

  return (
    <>
      <Flex width={1 / 1} flexDirection="column" alignItems="center">
        <PageHeader name={name} setCurrentOrg={setCurrentOrg} />
        <ManagmentActions setComponent={setComponent} />
      </Flex>
      {!!component && (
        <OrganisationModalCtr setComponent={setComponent}>
          {component}
        </OrganisationModalCtr>
      )}
    </>
  )
}

export default OrganisationPage
