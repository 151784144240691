import { types } from "./status.actions"

export const initialState = {
  statusReport: null,
  isLoading: false,
  hasError: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_STATUS:
      return { ...state, isLoading: true, hasError: false }

    case types.LOAD_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        ewStatusReport: action.payload.ewStatus,
        ebStatusReport: action.payload.ebStatus
      }

    case types.LOAD_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        statusReport: null
      }
    default:
      return state
  }
}
