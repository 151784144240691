import { connect } from "react-redux"
import { getCurrentOrg } from "../../../organisationManagement/store/orgManagement.selectors"
import SearchByOrgInputComponent from "../components/SearchByOrgInput"
import { searchOrgsByName } from "../store/searchorg.actions"
import { getOrgs, getIsLoadingOrgs } from "../store/searchorg.selectors"

const mapState = state => ({
  isLoading: getIsLoadingOrgs(state),
  orgs: getOrgs(state) || [],
  currentOrg: getCurrentOrg(state)
})

const mapDispatch = dispatch => ({
  onChangeCallback: name => dispatch(searchOrgsByName({ name }))
})

const SearchByOrgInput = connect(
  mapState,
  mapDispatch
)(SearchByOrgInputComponent)

export default SearchByOrgInput
