const TableConfig = {
  stylesPerColumn: [
    {
      fontSize: 16,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 14
    },
    {
      fontSize: 16,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 16,
      fontWeight: 600
    }
  ],
  columnWidths: [15 / 100, 12 / 100, 30 / 100, 8 / 100, 25 / 100],
  main: {
    borderRadius: "10px"
  },
  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: "dark.3",
    py: 2
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 2,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const Headings = [
  {
    id: "service",
    title: "Service Name"
  },
  {
    id: "type",
    title: "Rest or GraphQL"
  },
  {
    id: "description",
    title: "Description"
  },
  {
    id: "status",
    title: "Status"
  },
  {
    id: "text",
    title: "Text"
  }
]

const determineDescription = {
  "business-graphql":
    "Organisation profile, create expert advice, retrieving events",
  "file-manager": "For uploading files eg images, podcasts",
  "gardners-payment":
    "Making a book order, retrieving info about previous book orders",
  "hub-graphql":
    "Creating, retrieving, updating, deleting, endorsing, commenting on hub posts",
  jobs: "Creating, retrieving, updating, deleting, matching, subscribing jobs",
  materials: "Creating, retrieving business Library materials",
  "misc-graphql":
    "Misc service used for loading all countries, industries, job roles, regions, skills, hobbies, languages",
  misc: "Misc service used for loading all countries, industries, job roles, regions, skills, hobbies, languages",
  payment: "All stripe payments",
  profile:
    "User profile, job matcher, user alerts, mentor matcher, cv library, user connections",
  rankings: "Companies ranking, industries ranking",
  "social-library": "Bookmarking",
  "survey-processes": "Processes survey submissions",
  surveys:
    "Retrieving survey questions, languages, templates, results; submitting surveys",
  "user-manager": "Workl user authentication",
  services: "Purchase EBN, events, leads, purchase survey credits",
  business: "Organisation profile, publishing Business Information Profile"
}

const servicesToExclude = { service: "misc", type: "Rest" }

export { TableConfig, Headings, determineDescription, servicesToExclude }
