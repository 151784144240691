import createTopFieldSelectorFactory from "../../../../helpers/createTopFieldSelectorFactory"

const currentUserState = state => state.UMCurrentUser

const createTopFieldSelector = createTopFieldSelectorFactory(currentUserState)

export const getCurrentUser = createTopFieldSelector("user")

export const getIsLoading = createTopFieldSelector("isLoading")

export const getErrorCode = createTopFieldSelector("errorCode")
