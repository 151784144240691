const TableConfig = {
  stylesPerColumn: [
    {
      fontSize: 14,
      fontWeight: 500,
      color: "dark.1"
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    },
    {
      fontSize: 16,
      color: "primary.0",
      fontWeight: 600
    }
  ],
  columnWidths: [1, 1, 1],
  main: {
    borderRadius: "10px"
  },
  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: "dark.3",
    py: 2
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 2,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const Headings = [
  { id: "id", title: "Organisation ID" },
  { id: "company", title: "Company" },
  { id: "manage", title: "Manage" }
]

export { TableConfig, Headings }
