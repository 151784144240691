import React, { useEffect, useState } from "react"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Snackbar from "@engaging-tech/components/dist/lib/Snackbar/Snackbar"

const { Parser } = require("json2csv")
const copy = require("copy-text-to-clipboard")

const fields = [
  "firstName",
  "lastName",
  "email",
  "createdAt",
  "confirmed",
  "allowMarketing",
  "location"
]
const opts = { fields }
let timeout

const DownloadEmails = ({ usersData, selected, type }) => {
  useEffect(() => {
    return () => timeout && clearTimeout(timeout)
  }, [])

  const [isSnackbarVisible, setIsSnackbarVisible] = useState({
    success: false,
    failure: false
  })

  const parseCSV = data => {
    try {
      const parser = new Parser(opts)
      const csv = parser.parse(data)
      const isCopySuccessful = copy(csv)
      if (!isCopySuccessful) {
        setIsSnackbarVisible({ ...isSnackbarVisible, failure: true })
      } else {
        setIsSnackbarVisible({ ...isSnackbarVisible, success: true })
        timeout = setTimeout(() => {
          setIsSnackbarVisible({ ...isSnackbarVisible, success: false })
        }, 4000)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Flex width={1 / 1} justifyContent="center">
      <Button
        variant="secondary.0"
        onClick={() => parseCSV(usersData)}
        disabled={!usersData}
        minWidth={1 / 2}
      >
        Copy data
      </Button>
      <Snackbar
        width="auto"
        p={2}
        isVisible={isSnackbarVisible.success}
        content={`${type} ${selected} is copied to your clipboard! You can paste the data into CSV or Text file`}
      />
      <Snackbar
        width="auto"
        p={2}
        isVisible={isSnackbarVisible.failure}
        content="Either Your browser does not support copy to clipboard functionality or something else went wrong."
      />
    </Flex>
  )
}

export default DownloadEmails
