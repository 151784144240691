export const types = {
  UPDATE_CREDITS_REQUEST: "[Org Management] UPDATE_CREDITS_REQUEST",
  UPDATE_CREDITS_SUCCESS: "[Org Management] UPDATE_CREDITS_SUCCESS",
  UPDATE_CREDITS_FAIL: "[Org Management] UPDATE_CREDITS_FAIL",
  SET_CURRENT_ORG: "[Org Management] SET_CURRENT_ORG",
  SET_OPEN_SLIDER: "[Org Management] SET_OPEN_SLIDER",
  SET_CLOSE_SLIDER: "[Org Management] SET_CLOSE_SLIDER",
  SET_COMPONENT: "[Org Management] SET_COMPONENT",
  GET_LOGS_BY_ORGANISATION_FAIL:
    "[Org Management] GET_LOGS_BY_ORGANISATION_FAIL",
  GET_LOGS_BY_ORGANISATION_REQUEST:
    "[Org Management] GET_LOGS_BY_ORGANISATION_REQUEST",
  GET_LOGS_BY_ORGANISATION_SUCCESS:
    "[Org Management] GET_LOGS_BY_ORGANISATION_SUCCESS",
  GET_ACTIVE_PRODUCTS_REQUEST: "[Org Management] GET_ACTIVE_PRODUCTS_REQUEST",
  GET_ACTIVE_PRODUCTS_FAIL: "[Org Management] GET_ACTIVE_PRODUCTS_FAIL",
  GET_ACTIVE_PRODUCTS_SUCCESS: "[Org Management] GET_ACTIVE_PRODUCTS_SUCCESS",
  DELETE_ACTIVE_PRODUCT_REQUEST:
    "[Org Management] DELETE_ACTIVE_PRODUCT_REQUEST",
  DELETE_ACTIVE_PRODUCT_FAIL: "[Org Management] DELETE_ACTIVE_PRODUCT_FAIL",
  DELETE_ACTIVE_PRODUCT_SUCCESS:
    "[Org Management] DELETE_ACTIVE_PRODUCT_SUCCESS",
  GET_AVAILABLE_PRODUCTS_REQUEST:
    "[Org Management] GET_AVAILABLE_PRODUCTS_REQUEST",
  GET_AVAILABLE_PRODUCTS_FAIL: "[Org Management] GET_AVAILABLE_PRODUCTS_FAIL",
  GET_AVAILABLE_PRODUCTS_SUCCESS:
    "[Org Management] GET_AVAILABLE_PRODUCTS_SUCCESS",
  POST_ACTIVE_PRODUCT_REQUEST: "[Org Management] POST_ACTIVE_PRODUCT_REQUEST",
  POST_ACTIVE_PRODUCT_FAIL: "[Org Management] POST_ACTIVE_PRODUCT_FAIL",
  POST_ACTIVE_PRODUCT_SUCCESS: "[Org Management] POST_ACTIVE_PRODUCT_SUCCESS",
  SET_SELECTED_PRODUCT: "[Org Management] SET_SELECTED_PRODUCT",
  SET_ACTION: "[Org Management] SET_ACTION",
  SET_EDIT_DATA: "[Org Management] SET_EDIT_DATA",
  SET_NEW_PRODUCT: "[Org Management] SET_NEW_PRODUCT",
  UPDATE_ACTIVE_PRODUCT_REQUEST:
    "[Org Management] UPDATE_ACTIVE_PRODUCT_REQUEST",
  UPDATE_ACTIVE_PRODUCT_FAIL: "[Org Management] UPDATE_ACTIVE_PRODUCT_FAIL",
  UPDATE_ACTIVE_PRODUCT_SUCCESS:
    "[Org Management] UPDATE_ACTIVE_PRODUCT_SUCCESS"
}

// 1. UPDATE CREDITS
export const updateCreditsRequest = payload => ({
  type: types.UPDATE_CREDITS_REQUEST,
  payload
})

export const updateCreditsSuccess = payload => ({
  type: types.UPDATE_CREDITS_SUCCESS,
  payload
})

export const updateCreditsFail = payload => ({
  type: types.UPDATE_CREDITS_FAIL,
  payload
})

// 2. GET LOGS
export const getLogsByOrganisationRequest = payload => ({
  type: types.GET_LOGS_BY_ORGANISATION_REQUEST,
  payload
})

export const getLogsByOrganisationSuccess = payload => ({
  type: types.GET_LOGS_BY_ORGANISATION_SUCCESS,
  payload
})

export const getLogsByOrganisationFail = payload => ({
  type: types.GET_LOGS_BY_ORGANISATION_FAIL,
  payload
})

// 3. SET CURRENT ORG
export const setCurrentOrg = payload => ({
  type: types.SET_CURRENT_ORG,
  payload
})

// 4. SLIDER OPEN/CLOSE
export const setOpenSlider = payload => ({
  type: types.SET_OPEN_SLIDER,
  payload
})

export const setCloseSlider = () => ({
  type: types.SET_CLOSE_SLIDER,
  payload: {
    add: false,
    edit: false,
    log: false
  }
})

// 5. SET COMPONENT
export const setComponent = payload => ({
  type: types.SET_COMPONENT,
  payload
})

// 6. GET ACTIVE PRODUCTS
export const getActiveProductsRequest = payload => ({
  type: types.GET_ACTIVE_PRODUCTS_REQUEST,
  payload
})

export const getActiveProductsFail = payload => ({
  type: types.GET_ACTIVE_PRODUCTS_FAIL,
  payload
})

export const getActiveProductsSuccess = payload => ({
  type: types.GET_ACTIVE_PRODUCTS_SUCCESS,
  payload
})

// 7. DELETE ACTIVE PRODUCT
export const deleteActiveProductRequest = payload => ({
  type: types.DELETE_ACTIVE_PRODUCT_REQUEST,
  payload
})

export const deleteActiveProductFail = payload => ({
  type: types.DELETE_ACTIVE_PRODUCT_FAIL,
  payload
})

export const deleteActiveProductSuccess = payload => ({
  type: types.DELETE_ACTIVE_PRODUCT_SUCCESS,
  payload
})

// 8. GET AVAILABLE PRODUCTS
export const getAvailableProductsRequest = payload => ({
  type: types.GET_AVAILABLE_PRODUCTS_REQUEST,
  payload
})

export const getAvailableProductsFail = payload => ({
  type: types.GET_AVAILABLE_PRODUCTS_FAIL,
  payload
})

export const getAvailableProductsSuccess = payload => ({
  type: types.GET_AVAILABLE_PRODUCTS_SUCCESS,
  payload
})

// 9. POST NEW PRODUCT
export const postActiveProductRequest = payload => ({
  type: types.POST_ACTIVE_PRODUCT_REQUEST,
  payload
})

export const postActiveProductFail = payload => ({
  type: types.POST_ACTIVE_PRODUCT_FAIL,
  payload
})

export const postActiveProductSuccess = payload => ({
  type: types.POST_ACTIVE_PRODUCT_SUCCESS,
  payload
})

// 10. SET SELECTED PRODUCT
export const setSelectedProduct = payload => ({
  type: types.SET_SELECTED_PRODUCT,
  payload
})

// 11. SET ACTION
export const setAction = payload => ({
  type: types.SET_ACTION,
  payload
})

// 12. SET EDIT DATA
export const setEditData = payload => ({
  type: types.SET_EDIT_DATA,
  payload
})

// 13. SET NEW PRODUCT
export const setNewProduct = payload => ({
  type: types.SET_NEW_PRODUCT,
  payload
})

// 14. UPDATE ACTIVE PRODUCT
export const updateActiveProductRequest = payload => ({
  type: types.UPDATE_ACTIVE_PRODUCT_REQUEST,
  payload
})

export const updateActiveProductFail = payload => ({
  type: types.UPDATE_ACTIVE_PRODUCT_FAIL,
  payload
})

export const updateActiveProductSuccess = payload => ({
  type: types.UPDATE_ACTIVE_PRODUCT_SUCCESS,
  payload
})
