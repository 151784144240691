import React from "react"

import { createPortal } from "react-dom"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import Paragraph from "@engaging-tech/components/dist/lib/Paragraph/Paragraph"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const RemoveUser = ({ toggle, handleRemoveUser, userId }) => {
  return createPortal(
    <Modal bg="light.0" p={4}>
      <Flex flexDirection="column" width={1 / 1} alignItems="stretch">
        <Text textAlign="center" mb={4} fontWeight={700}>
          Remove User
        </Text>
        <Paragraph textAlign="center" color="error.0" fontSize={5}>
          Warning! You are about to delete this user from the database. Are you
          sure you wish to proceed?
        </Paragraph>
        <Flex flexDirection="row" width={1 / 1} justifyContent="center" mt={4}>
          <Button mr={3} bg="secondary.2" onClick={toggle}>
            Cancel
          </Button>
          <Button bg="error.0" onClick={() => handleRemoveUser(userId)}>
            Remove
          </Button>
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default RemoveUser
