import React from "react"

import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Radio from "../../../components/Radio"
import { SurveyTypes } from "../../lib"

const SurveyTypeRadios = ({ surveyType, handleClick }) => (
  <Box mb={[3, 3, 0]}>
    <Text>Survey Type</Text>
    {Object.values(SurveyTypes).map(type => (
      <Radio
        id={type}
        key={type}
        checked={type === surveyType}
        label={() => type}
        onClick={() => handleClick(type)}
      />
    ))}
  </Box>
)

export default SurveyTypeRadios
