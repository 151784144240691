import React from "react"
import { Authenticator } from "@aws-amplify/ui-react"
import { Auth } from "aws-amplify"

import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"

const AuthGuard = ({ updateUser, user, children }) => {
  if (user) return <>{children}</>
  return (
    <Flex
      width={1 / 1}
      height="100vh"
      justifyContent="center"
      alignContent="center"
      bg="primary.0"
      pt="25vh"
    >
      <Authenticator hideSignUp>
        {({ user }) => {
          updateUser(user)
          return <>{children}</>
        }}
      </Authenticator>
    </Flex>
  )
}

export default AuthGuard
