import React from "react"
import UserManagementView from "../features/ebUserManagement/views/UserManagement"
import BaseLayout from "../layouts/BaseLayout"

const UserManagement = () => (
  <BaseLayout>
    <UserManagementView />
  </BaseLayout>
)

export default UserManagement
