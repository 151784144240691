import Pages from "./pages"

const routes = [
  {
    path: "/",
    description: "Home",
    component: Pages.Home
  },
  {
    path: "/reports",
    description: "Reports",
    component: Pages.Reports
  },
  {
    path: "/status",
    description: "Status",
    component: Pages.Status
  },
  {
    path: "/ranking-audit",
    description: "Audit",
    component: Pages.RankingAudit
  },
  {
    path: "/survey-data-export",
    description: "Data Export",
    component: Pages.DataExport
  },
  {
    path: "/user-management",
    description: "EB User Management",
    component: Pages.UserManagement
  },
  {
    path: "/organisation-management",
    description: "Organisation Management",
    component: Pages.OrgManagement
  },
  {
    path: "/cms",
    description: "CMS",
    component: Pages.CMS
  }
]

export default routes
