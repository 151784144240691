import React from "react"
import styled from "styled-components"
import Chip from "@engaging-tech/components/dist/lib/Chip/Chip"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import { queryTypes } from "../../reports/lib/reportsLibrary"

const Wrapper = styled(Flex)`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const ChipBar = ({ chips }) => (
  <Wrapper
    width={1 / 1}
    pl={[3, 3, 4]}
    py={3}
    mb={4}
    bg="primary.4"
    justifyContent="space-around"
    borderRadius={2}
  >
    {chips.map(chip => (
      <Chip
        key={chip.name}
        onClick={chip.onClick}
        isActive={chip.isActive}
        px={8}
        width="auto"
      >
        <Text color="primary.0" fontSize={3}>
          {queryTypes[chip.name].text || chip.name}
        </Text>
      </Chip>
    ))}
  </Wrapper>
)

export default ChipBar
