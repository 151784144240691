import { takeLatest, put, call, delay, select } from "redux-saga/effects"
import { endOfDay, startOfDay } from "date-fns"
import { generateExport } from "../services/dataexport.service"

import * as Actions from "./dataexport.actions"
import { getField } from "./dataexport.selectors"
import { NonOrgSurveyIds, SurveyTypes } from "../lib"

const getGraphQLErrCode = err => {
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

const getSurveyId = ({ orgSurveyId, surveyType, isWorkFromHome }) => {
  if (surveyType === SurveyTypes.organisation) return orgSurveyId
  const ids = NonOrgSurveyIds[surveyType]
  return isWorkFromHome ? ids.workFromHome : ids.standard
}

function* getParams() {
  const orgSurveyId = yield select(getField, "surveyId")
  const surveyType = yield select(getField, "surveyType")
  const isWorkFromHome = yield select(getField, "isWorkFromHome")
  const companyNameRequired = yield select(getField, "companyNameRequired")
  const additionalColumns = yield select(getField, "additionalColumns")
  const startDate = yield select(getField, "startDate")
  const endDate = yield select(getField, "endDate")
  const options = {
    companyNameRequired,
    additionalColumns,
    startDate: startOfDay(startDate),
    endDate: endOfDay(endDate)
  }

  const surveyId = getSurveyId({ orgSurveyId, surveyType, isWorkFromHome })
  return { surveyId, options }
}

const SMALL_DELAY = 1000 // ms
const LARGER_DELAY = 5000 // ms

export function* generateExportSaga(action) {
  try {
    const params = yield getParams()
    const res = yield call(generateExport, params)
    if (res?.data?.getDataExport?.status !== "processed") {
      const count = action.payload || 0
      yield delay(count > 20 ? LARGER_DELAY : SMALL_DELAY)
      yield put(Actions.retryGenerateExport(count + 1))
    } else {
      yield put(Actions.generateExportSuccess(res?.data?.getDataExport?.url))
    }
  } catch (error) {
    yield put(Actions.generateExportFail(getGraphQLErrCode(error)))
  }
}

export function* saga() {
  yield takeLatest(
    [Actions.types.GENERATE_EXPORT, Actions.types.RETRY_GENERATE_EXPORT],
    generateExportSaga
  )
}
