import React from "react"
import { useSelector } from "react-redux"
import { Redirect } from "@engaging-tech/routing"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"
import { authSelectors } from "../../auth"
import { IndustriesLoader, CountriesLoader } from "../../misc/index"
import EntriesLoader from "../containers/EntriesLoader"
import RankingAuditContainer from "../containers/RankingAudit"

const RankingAudit = () => {
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.CompanyAudit),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />

  return (
    <>
      <IndustriesLoader />
      <CountriesLoader />
      <EntriesLoader />
      <RankingAuditContainer />
    </>
  )
}

export default RankingAudit
