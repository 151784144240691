import { connect } from "react-redux"
import { reportsSelectors, reportsActions } from ".."
import {
  oldIndividualSurveysArchived,
  oldOrganisationSurveysArchived
} from "../lib/reportsLibrary"
import ReportTable from "../components/ReportTable.js"

const mapState = state => {
  let lifetimeStats = reportsSelectors.getLifetimeStats(state)
  return {
    selected: reportsSelectors.getSelectedQueryType(state),
    queryStats: lifetimeStats && {
      ...lifetimeStats,
      IndividualSurvey: {
        completed:
          lifetimeStats?.IndividualSurvey?.completed +
          oldIndividualSurveysArchived
      },
      OrganisationEmployeeSurvey: {
        completed:
          lifetimeStats?.OrganisationEmployeeSurvey?.completed +
          oldOrganisationSurveysArchived
      }
    }
  }
}

const mapDispatch = dispatch => ({
  loadQuery: queryType => dispatch(reportsActions.loadQuery(queryType))
})

const Lifetime = connect(mapState, mapDispatch)(ReportTable)

export default Lifetime
