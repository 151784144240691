import { useEffect } from "react"
import { connect } from "react-redux"

import { load } from "../store/rankingAudit.actions"

const EntriesLoaderComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(load())
})

const EntriesLoader = connect(null, mapDispatch)(EntriesLoaderComponent)

export default EntriesLoader
