/* eslint-disable import/prefer-default-export */
import { gql } from "../../../../lib/Apollo"

export const FETCH_SURVEYS = gql`
  query ($organisationId: String!) {
    fetchSurveysByOrgId(organisationId: $organisationId) {
      name
      id
      startDate
      endDate
    }
  }
`
