import React from "react"

import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Radio from "../../../components/Radio"

const OrganisationNameFilter = ({
  companyNameRequired,
  handleClick,
  ...props
}) => {
  return (
    <Box width={1} {...props}>
      <Text width={1} display="block">
        Organisation Name Filter
      </Text>
      <Text fontSize={2} color="dark.2">
        Use this to either fetch results of all submissions or filter by only
        those who entered a company name. Most useful in combination with
        Happiness Surveys.
      </Text>
      <Radio
        id="company-name-required-all"
        label={() => "All"}
        checked={!companyNameRequired}
        onClick={() => handleClick(false)}
      />
      <Radio
        id="company-name-required-org-only"
        label={() => "Organisation Name Only"}
        checked={companyNameRequired}
        onClick={() => handleClick(true)}
      />
    </Box>
  )
}

export default OrganisationNameFilter
