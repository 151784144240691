// Date Formating
import { format } from "date-fns"
// Eng / Styled Components
import { Box, Flex, H2, H3, Text } from "@engaging-tech/components"
// React
import React, { Fragment } from "react"

const ViewServices = ({ currentOrg }) => {
  const { name, services } = currentOrg

  const formatRow = (key, value) => {
    const newKey = key
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, str => {
        return str.toUpperCase()
      })
    switch (newKey) {
      case "Expire Date":
        return (
          <Text>
            {newKey}: {format(new Date(value), "dd/MM/yyyy")}
          </Text>
        )
      default:
        return (
          <Text color={value ? "secondary.0" : "primary.0"}>
            {newKey}: {value?.toString() || value}
          </Text>
        )
    }
  }

  return (
    <>
      <H2
        color="primary.0"
        width={1 / 1}
        fontSize={6}
        textAlign="center"
        mb={5}
      >
        Viewing services for {name}
      </H2>
      <Flex width={1 / 1} flexDirection="column">
        {services?.map((service, idx) => {
          const { __typename, name: serviceName, ...attributes } = service
          return (
            <Box mb={3} key={idx}>
              <H3 width={1 / 1} fontSize={5}>
                {serviceName}
              </H3>
              <Flex flexDirection="column">
                {Object.entries(attributes).map(([key, value], idx) => (
                  <Fragment key={idx}>{formatRow(key, value)}</Fragment>
                ))}
              </Flex>
            </Box>
          )
        })}
      </Flex>
    </>
  )
}

export default ViewServices
