import { takeLatest, put, call } from "redux-saga/effects"

import { fetchSurveysByOrganisationId } from "../services/fetchsurveys.service"

import * as Actions from "./fetchsurveys.actions"

const getGraphQLErrCode = err => {
  console.log(err)
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions?.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

export function* fetchSurveysSaga(action) {
  try {
    const res = yield call(fetchSurveysByOrganisationId, action.payload)

    yield put(Actions.fetchSurvesSuccess(res?.data?.fetchSurveysByOrgId))
  } catch (error) {
    yield put(Actions.fetchSurveysFail(getGraphQLErrCode(error)))
  }
}

export function* saga() {
  yield takeLatest([Actions.types.FETCH_SURVEYS], fetchSurveysSaga)
}
