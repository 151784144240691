import React from "react"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import { useSelector } from "react-redux"
import { Redirect } from "@engaging-tech/routing"
import GenerateForm from "../containers/GenerateForm"
import { authSelectors } from "../../auth"
import { FEATURE_GROUPS, getShouldRedirectToHome } from "../../../lib/Features"

const Generate = () => {
  const groups = useSelector(authSelectors.getGroups)
  const shouldRedirect = React.useMemo(
    () => getShouldRedirectToHome(groups, FEATURE_GROUPS.DataExport),
    [groups]
  )
  if (shouldRedirect) return <Redirect to="/" />
  return (
    <Flex justifyContent="center" flexDirection="column" alignItems="center">
      <GenerateForm />
    </Flex>
  )
}

export default Generate
