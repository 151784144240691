import { takeLatest, put, call } from "redux-saga/effects"

import { getAll } from "../service/reports.service"

import * as Actions from "./reports.actions"

export function* loadQuerySaga(action) {
  const {
    payload: { from, to, type }
  } = action
  try {
    const res = yield call(getAll, {
      from,
      to
    })
    yield put(Actions.loadQuerySuccess(res.data, type))
  } catch {
    yield put(Actions.loadQueryFail(type))
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_QUERY, loadQuerySaga)
}
