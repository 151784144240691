import React from "react"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Card from "@engaging-tech/components/dist/lib/Card/Card"
import SurveyTypeRadios from "../../containers/SurveySelect/SurveyTypeRadios"
import Secondary from "../../containers/SurveySelect/Secondary"
import Ternary from "../../containers/SurveySelect/Ternary"

const SurveySelect = () => {
  return (
    <Card width={1} border="1px solid black" p={3} alignItems="center">
      <Text width="auto" fontSize={5}>
        Select Survey
      </Text>
      <Flex width={1} flexDirection={["column", "column", "row"]}>
        <SurveyTypeRadios />
        <Secondary />
        <Ternary />
      </Flex>
    </Card>
  )
}

export default SurveySelect
