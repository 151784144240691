import { gql } from "../../../lib/Apollo"

export const LOAD_USER = gql`
  query ($email: String, $userId: String) {
    getUser(email: $email, userId: $userId) {
      userId
      email
      organisation {
        id
        services {
          active
          name
          expireDate
        }
        name
        confirmed
      }
      firstName
      lastName
      createdAt
      updatedAt
      enabled
      status
      attributes {
        name
        value
      }
    }
  }
`

export const LOAD_USERS_BY_ORG = gql`
  query ($orgId: String!, $limit: Int, $skip: Int) {
    getUsersByOrganisation(organisationId: $orgId, limit: $limit, skip: $skip) {
      userId
      firstName
      lastName
    }
  }
`

export const UPDATE_USER = gql`
  mutation ($userId: String!, $params: UpdateUserParams!) {
    updateUser(userId: $userId, params: $params) {
      userId
      email
      organisation {
        id
        services {
          active
          name
          expireDate
        }
        name
        confirmed
      }
      firstName
      lastName
      createdAt
      updatedAt
      enabled
      status
      attributes {
        name
        value
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation ($params: CreateUserParams!) {
    createUser(params: $params) {
      userId
      firstName
      lastName
    }
  }
`

export const DELETE_USER = gql`
  mutation ($userId: String!) {
    deleteUser(userId: $userId)
  }
`
