import React from "react"

import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Spinner from "@engaging-tech/components/dist/lib/Spinner/Spinner"
import Button from "@engaging-tech/components/dist/lib/Button/Button"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import SurveySelect from "./SurveySelect/index"
import OptionsSelect from "./OptionsSelect"
import GenerateButton from "../containers/GenerateButton"

const GenerateForm = ({ exportUrl, isGenerating }) => {
  return (
    <Box width={1}>
      <SurveySelect />
      <OptionsSelect />
      <GenerateButton />
      <Flex width={1} justifyContent="center">
        {isGenerating && (
          <Flex alignItems="center" flexDirection="column">
            <Spinner color="primary.0" mb={2} />
            <Text display="block" fontSize={2} width="40%" textAlign="center">
              Some data exports might take a few minutes. Please leave this page
              and open if you are requesting a larger export.
            </Text>
          </Flex>
        )}
        {!isGenerating && !!exportUrl && (
          <a href={exportUrl} target="_blank" rel="noopener noreferrer">
            <Button variant="secondary.0">Download</Button>
          </a>
        )}
      </Flex>
    </Box>
  )
}

export default GenerateForm
